import React from "react";
import { useMutation } from "react-query";
import { secureAxios } from "../../_helpers/auth";
import { queryClient } from "../../_helpers/query";

export const useChangeMembership = (profile_id) => {
    return useMutation(
        (data) => {
            return secureAxios
                .patch(`/items/profile/${profile_id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "profile"]);
            },
        }
    );
};
