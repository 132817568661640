import React, { Fragment, useState } from "react";
import Modal from "react-modal";
function AddField() {
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <button onClick={() => setOpen(true)}>Add</button>
            <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
                Add New Field Form
            </Modal>
        </Fragment>
    );
}

export default AddField;
