import React, { useEffect, useState } from "react";
import { useSingleItem } from "../actions/app/items";
import log from "cslog";
import QForm from "./QForm";
import QFormStandard from "./QFormStandard";
import Loading from "../common/Loading";

function decodeRecord(template, record) {
    log.d(template, "Template");
    log.d(record, "Record");
    let out = {};
    if (typeof template === "object") {
        Object.entries(template).forEach((row) => {
            out = {
                ...out,
                ...decodeRecord(row[1], record[row[0]]),
            };
        });
    } else {
        out[template] = record;
    }
    return out;
}

const ROLES_MAPPING = {
    "b35e905a-97f5-4fd4-87cc-631ecd33354b": 11, //admin
    "a4654aae-b05f-4a21-be49-af1a6fd0d4e9": 10, //manager
    "a0def5fa-b897-4fa9-b847-fd60e34acf44": 22, //qadmin
    "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f": 21, //users
    "52bb1969-8b18-4e10-8798-c614f476c306": 20, //bot
};

const ROLES_REVERSE_MAPPING = {
    11: "b35e905a-97f5-4fd4-87cc-631ecd33354b", //admin
    10: "a4654aae-b05f-4a21-be49-af1a6fd0d4e9", //manager
    22: "a0def5fa-b897-4fa9-b847-fd60e34acf44", //qadmin
    21: "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f", //users
    20: "52bb1969-8b18-4e10-8798-c614f476c306", //bot
};

// const ROLES_REVERSE_MAPPING = {};
// Object.entries(ROLES_MAPPING).forEach((row) => {
//     ROLES_REVERSE_MAPPING[row[1]] = row[0];
// });
// log.d(ROLES_REVERSE_MAPPING, "Roles reverse mapping");

function makeSingleRecordQuery(form, record_id) {
    let query = "fields=";
    form?.fields.forEach((f) => {
        if (f.type === "relationM2M") {
            query = query + `${f.name}.*,`;
        } else {
            query = query + `${f.name},`;
        }
    });
    query = query.substring(0, query.length - 1);
    const out = `/${record_id}?${query}`;
    log.d(out, "Query");
    return out;
}

function refineData(f, data) {
    if (f.type.startsWith("relation")) {
        // if (f.foreign_key_table === "roles") {
        if (f.foreign_key_table === "directus_roles") {
            const val = data[f.name];
            if (Array.isArray(val)) {
                // return val.map((r) => ROLES_REVERSE_MAPPING[r]);
                return val.map((r) => r?.directus_roles_id);
            } else {
                return ROLES_REVERSE_MAPPING[val];
            }
        } else {
            return data[f.name];
        }
    } else if (f.type === "json") {
        const jout = {};
        f.fields.forEach((ff) => {
            jout[ff.name] = refineData(ff, data[f.name]);
        });
        return jout;
    } else {
        return data[f.name];
    }
}

const getDefaultValues = (f) => {
    if (f.type === "json") {
        const out = {};
        f.fields.forEach((ff) => {
            out[ff.name] = getDefaultValues(ff);
        });
        return out;
    } else {
        if ("default_value" in f) {
            return f["default_value"];
        }
        return null;
    }
};

function QurealForm({ id, record_id, wrapperStyle }) {
    const {
        isLoading: loadingForm,
        isSuccess: successForm,
        data: dataForm,
    } = useSingleItem("qdb_form", `/${id}`, id !== undefined);
    const {
        isLoading: loadingRecord,
        isSuccess: successRecord,
        data: dataRecord,
    } = useSingleItem(
        dataForm?.table,
        // `/${record_id}`,
        makeSingleRecordQuery(dataForm, record_id),
        successForm && record_id !== undefined
    );
    const [fdata, setFdata] = useState({});
    const edit = record_id !== undefined;

    log.d(record_id, "Record ID");
    log.d(dataRecord, "Record Data");

    useEffect(() => {
        if (successForm) {
            const initialData = {};
            dataForm.fields.map((ff) => {
                const val = getDefaultValues(ff);
                if (val) {
                    initialData[ff.name] = val;
                }
            });
            log.d(initialData, "Initial Data");
            setFdata(initialData);
        }
    }, [successForm, dataForm]);

    useEffect(() => {
        if (edit && successForm && successRecord) {
            // const template = dataForm.action?.out;
            // log.p("Decoding record");
            // const out = decodeRecord(template, dataRecord);
            // log.d(out, "Decoded OUT");

            log.d(dataRecord, "Data Record");
            let refined_data = {};
            dataForm.fields.forEach((f) => {
                refined_data[f.name] = refineData(f, dataRecord);
            });
            log.d(refined_data, "Refined Data");
            setFdata(refined_data);
        }
    }, [successRecord, dataRecord, successForm, dataForm, edit]);

    log.d(dataForm, "Data Form");

    if (loadingForm) {
        return <Loading title="Loading form, please wait..." />;
    }
    if (successForm) {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    padding: "15px",
                }}
            >
                {/* <QForm */}
                <QFormStandard
                    form={dataForm}
                    fields={dataForm.fields}
                    record_id={record_id}
                    data={fdata}
                    setData={setFdata}
                />
            </div>
        );
    }
    return <></>;
}

// function QurealFormTest() {
//     const [fdata, setFData] = useState(() => {
//         const initialData = {};
//         FIELDS.map((ff) => {
//             if ("default_value" in ff) {
//                 initialData[ff.id] = ff.default_value;
//             }
//         });
//         return initialData;
//     });

//     log.d(FIELDS, "FIELDS");

//     return <QForm fields={FIELDS} data={fdata} setData={setFData} />;
// }

export default QurealForm;
