import React from "react";
import { Layout, Spin } from "antd";
import QurealSection from "./QurealSection";

const { Content } = Layout;

function LoadingBox({ text }) {
    return (
        <div
            style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "75px",
                paddingBottom: "75px",
                // border: "1px dashed blue",
            }}
        >
            <Spin />
        </div>
    );
}

export default LoadingBox;
