import React, { useMemo } from "react";
import styles from "./styles/SectionHeader.module.css";
import { CodeSandboxOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import useMedia from "react-use/lib/useMedia";
import { TOPHEADER_HEIGHT } from "../../constants";
import { Layout, theme } from "antd";
import { useLocation } from "react-router-dom";
import log from "cslog";
import { history } from "../../_helpers/history";

const { Header } = Layout;

function SectionHeader({ heading, lchildren, children }) {
    const isMobile = useMedia("(max-width: 700px)");
    const location = useLocation();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const back_route = useMemo(() => {
        if (location.state && location.state.from) {
            return location.state.from;
        }
        return null;
    }, [location]);

    log.d(back_route, "Back Route");

    return (
        <Header
            className={styles.wrapper}
            style={{
                // height: TOPHEADER_HEIGHT,
                background: colorBgContainer,
            }}
        >
            <div className={styles.left}>
                {back_route ? (
                    <ArrowLeftOutlined
                        style={{ fontSize: "1.5rem", color: "gray" }}
                        onClick={() => history.goBack()}
                    />
                ) : (
                    <CodeSandboxOutlined
                        style={{ fontSize: "1.5rem", color: "gray" }}
                    />
                )}
                <h3 className={styles.title}>{heading}</h3>
            </div>
            <div className={styles.right}>{children}</div>
        </Header>
    );
}

export default SectionHeader;
