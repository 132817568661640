import { Button, Col, Form, Input, Modal, Row, Upload } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import PhotoButton from "../../../qforms/standard_elements/PhotoButton";
import TextArea from "antd/es/input/TextArea";
import log from "cslog";
import {
    useCreateItem,
    useUpdateItem,
} from "../../../_actions/mutations/items";
import { nanoid } from "nanoid"
import snakeCase from "lodash/snakeCase";

function AddBrandOrganization({ show, setShow, current_user, initialValues = {} }) {
    const [form] = Form.useForm();
    const mutationCreate = useCreateItem("qdb_organization", () =>
        setShow(false)
    );
    const mutationUpdate = useUpdateItem(
        "qdb_organization",
        initialValues?.id,
        () => setShow(false)
    );

    const closeModal = () => {
        // form.resetFields();
        setShow(false);
    };

    const is_update = !!initialValues?.id;

    const addOrganization = (values) => {
        log.d(values, "Person values");
        const new_data = {
            ...values,
            id: `${snakeCase(values.name)}_${nanoid(5)}`,
            owner: current_user?.id,
        }
        log.d(new_data, "New data");
        // return;
        if (!!initialValues?.id) {
            log.p("Updating organization");
            mutationUpdate.mutate(new_data);
        } else {
            log.p("Creating organization");
            mutationCreate.mutate(new_data);
        }
    };

    log.d(initialValues, "Initial Values");

    return (
        <Fragment>
            <Modal
                title={`${is_update ? "Update" : "Add new"} organization`}
                open={show}
                onOk={() => { }}
                confirmLoading={false}
                onCancel={closeModal}
                footer={[
                    <Button key="cancel" onClick={closeModal}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={
                            mutationCreate.isLoading || mutationUpdate.isLoading
                        }
                    >
                        {is_update ? "Update" : "Create"}
                    </Button>,
                ]}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={addOrganization}
                    initialValues={initialValues}
                >
                    <Form.Item label="Organization Name" name="name">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Organization Description"
                        name="description"
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item label="Logo" name="logo">
                        <PhotoButton
                            field={{
                                collection: "qdb_person",
                                field: "photo",
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default AddBrandOrganization;
