import { Button } from "antd";
import React from "react";
import logo from "../../media/imgs/Q_logo_b.png";
import log from "cslog";

function BillingButton({ total, onSuccess }) {
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order

        // Getting the order details back
        const amount = total * 100;
        const order_id = 12;
        const currency = "INR";

        const options = {
            key: "rzp_test_zgAd7EWVIRRspp", // Enter the Key ID generated from the Dashboard
            // secret: LHL8rESChksaYK6hRuIWYZXD,
            amount: amount.toString(),
            currency: currency,
            name: "LCCIA",
            description: "Test Transaction",
            image: { logo },
            // order_id: order_id, // remove it in test mode
            handler: async function (response) {
                log.d(response, "Rozerpay Response");
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                onSuccess(response.razorpay_payment_id);

                alert("Payment successfully");
            },
            prefill: {
                name: "Sumant Patel",
                email: "sumant.patel@gmail.com",
                contact: "9999999999",
            },
            notes: {
                address: "Sumant Patel Corporate Office",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <Button type="primary" size="large" onClick={displayRazorpay}>
            Pay ₹{total}
        </Button>
    );
}

export default BillingButton;
