import { Button } from "antd";
import log from "cslog";
import { useAtom } from "jotai";
import React from "react";
import { useUpdateItemsMutation } from "../../../../actions/app/items";
import { currentComponentAtom } from "../../../../states/app/component";
import UIButton from "../../UIButton";

export default function UpdateAllbutton({ action, comp_id, fields, items }) {
    const [currentComp, setCurrentComp] = useAtom(
        currentComponentAtom(comp_id)
    );
    const updateAllMutation = useUpdateItemsMutation();

    const meta = action.meta;

    const updateAll = () => {
        const keys = items.map((item) => item.id);
        updateAllMutation.mutate({
            collection: currentComp.data.collection,
            data: {
                keys: keys,
                data: meta.new_values,
            },
        });
    };

    log.d(updateAllMutation, "UpdateAllMutation");

    return (
        <UIButton
            layout={action.layout}
            onClick={updateAll}
            loading={updateAllMutation.isLoading}
        />
    );
}
