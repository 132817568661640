import React, { Fragment, useState } from "react";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import log from "cslog";
import { useUploadFile } from "../../actions/files";
import { BASE_URL } from "../../constants";
import { restoreAccessToken } from "../../_helpers/auth";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}

function PhotoButton({ field, value, onChange, remove_bg = false }) {
    const [photo, setPhoto] = useState({ id: value });
    const fileMutation = useUploadFile((data, variables, context) => {
        log.d(data, "Success Data");
        setPhoto(data);
        onChange(data.id);
    });

    log.d(value, "Value in PhotoButton");

    const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        if (file) {
            if (typeof file === "object" && file !== null) {
                const formData = new FormData();
                // formData.append("title", "Some title")
                formData.append("file", file);
                fileMutation.mutate(formData);
            }
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
            }}
        >
            <Button
                loading={fileMutation.isLoading}
                type="primary"
                ghost
                icon={<UploadOutlined />}
                onClick={(eve) => {
                    eve.preventDefault();
                    document
                        .getElementById(
                            `photo-${field.collection}-${field.field}`
                        )
                        .click();
                }}
            >
                {fileMutation.isLoading
                    ? "Uploading..."
                    : fileMutation.isSuccess
                    ? "Change"
                    : "Upload"}
            </Button>
            {photo?.id && (
                <img
                    src={getImageURL(photo.id)}
                    alt="preview"
                    style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "4px",
                        border: "1px solid grey",
                    }}
                />
            )}
            <input
                type="file"
                name="photo"
                onChange={onFile}
                id={`photo-${field.collection}-${field.field}`}
                hidden
                style={{
                    display: "none",
                }}
            />
        </div>
    );
}

export default PhotoButton;

// Ant design form custom field
// https://medium.com/swlh/use-custom-and-third-party-react-form-components-with-ant-design-and-typescript-2732e7849aee
