import { Form, Input, Radio } from "antd";
import React, { useEffect, useState } from "react";
import QurealRTEField from "../qrte/QurealRTEField";
import { TableFilters } from "../components/commons/Filters";
import log from "cslog";

function CollectionForm() {
    const [data, setData] = useState({});

    log.d(data, "CollectionForm Data");

    return (
        <div style={{ padding: "15px" }}>
            <Form>
                <Form.Item>
                    <label>Title</label>
                    <Input
                        value={data.title}
                        onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <label>Description</label>
                    <QurealRTEField
                        value={data.description}
                        onChange={(new_val) =>
                            setData({
                                ...data,
                                description: new_val,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <label>Collection Type</label>
                    <br />
                    <Radio.Group
                        options={[
                            { label: "Manual", value: "manual" },
                            { label: "Automated", value: "automated" },
                        ]}
                        value={data.type}
                        onChange={({ target: { value } }) =>
                            setData({
                                ...data,
                                type: value,
                            })
                        }
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>
                {data.type === "automated" && (
                    <AutomatedSetup
                        data={data.data || {}}
                        setData={(newData) =>
                            setData({ ...data, data: newData })
                        }
                    />
                )}
            </Form>
        </div>
    );
}

export default CollectionForm;

function AutomatedSetup({ data, setData }) {
    // const [filters, setFilters] = useState([]);
    // const [filterMatch, setFilterMatch] = useState("_and");

    useEffect(() => {
        if (!data.filterMatch && !data?.filters) {
            setData({
                filters: [{ field: "_" }],
                filterMatch: "_and",
            });
        }
    }, [data]);
    return (
        <div>
            <h4>Automated Setup</h4>
            <TableFilters
                collection="qd_product"
                filters={data?.filters || []}
                setFilters={(new_filters) =>
                    setData({
                        ...data,
                        filters: new_filters,
                    })
                }
                filterMatch={data?.filterMatch || "_and"}
                setFilterMatch={(new_filter_match) =>
                    setData({ ...data, filterMatch: new_filter_match })
                }
            />
        </div>
    );
}
