import React from "react";
import { useParams } from "react-router-dom";
import { useSingleItem } from "../actions/app/items";
import Component from "./Component";

function QComponent() {
    let { component, record } = useParams();
    const { isLoading, isSuccess, data } = useSingleItem(
        "qureal_component",
        `/${component}`,
        component !== undefined
    );

    if (isLoading) {
        return <h3>Loading...</h3>;
    }

    return isSuccess ? (
        <Component
            key={component}
            comp={data}
            slug={component}
            params={{
                table_id: record,
            }}
        />
    ) : (
        <h3>Loading failed: {component}</h3>
    );
}

export default QComponent;
