import { Layout } from "antd";
import log from "cslog";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useFields } from "../../actions/app/fields";
import { useItemsFine } from "../../actions/app/items";
import { currentComponentAtom } from "../../states/app/component";
import { generateUrl } from "../../_helpers/app/fields";
import TableHeader from "../commons/TableHeader";
import TableView from "./tableview/TableView";
// import CardsView from "./cardsview/CardsView";
import Icon from "@ant-design/icons";
import useMedia from "react-use/lib/useMedia";
import Loading from "../../common/Loading";
import { getPermittedActions } from "../../_helpers/app/actions";
import GridView from "./gridview/GridView";
import QurealSection from "../../common/QurealSection";
import MultiColumnView from "./multicolumnview/MultiColumnView";

const { Content } = Layout;

function DataView({ comp, slug, permissions }) {
    const isMobile = useMedia("(max-width: 700px)");
    const [url, setUrl] = useState(null);
    const {
        data: dataFields,
        isLoading: loadingFields,
        isSuccess: successFields,
    } = useFields(comp.data.collection);
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(
        comp.data.collection,
        url,
        url !== null,
        comp.data.refresh
    );
    // const {
    //     data: dataPermi,
    //     isLoading: loadingPermi,
    //     isSuccess: successPermi,
    // } = useCollectionPermission(comp.data.collection);
    const [currentComp, setCurrentComp] = useAtom(
        currentComponentAtom(comp.id)
    );
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedItems, setSearchedItems] = useState(null);

    useEffect(() => {
        const actions = getPermittedActions(comp, permissions);

        setCurrentComp({
            ...comp,
            actions: actions,
        });
    }, []);

    log.d(currentComp, "Current Component");
    // log.d(dataPermi, `Permission ${comp.data.collection}`);

    useEffect(() => {
        if (successFields && currentComp) {
            log.p("generating field staring");
            const fieldString = generateUrl(currentComp, dataFields, slug);
            log.d(fieldString, "Generated field string");
            setUrl(fieldString);
        }
    }, [currentComp, dataFields, successFields]);

    if (successFields && successItems) {
        log.d(dataFields, "Fields");
        log.d(dataItems, "Items");
    }

    useEffect(() => {
        if (successItems && searchTerm !== "") {
            const temp = dataItems.filter((obj) =>
                Object.values(obj).some((val) => {
                    if (typeof val === "string" || val instanceof String) {
                        return val.includes(searchTerm);
                    }
                    return false;
                })
            );
            setSearchedItems(temp);
        } else {
            setSearchedItems(null);
        }
    }, [searchTerm, dataItems, successItems]);

    // return <Loading text={comp.title} />;

    // log.d(searchedItems, "Searched Items");

    const itemRows = searchedItems
        ? searchedItems
        : Array.isArray(dataItems)
        ? dataItems
        : [dataItems];

    if (successFields && successItems && currentComp) {
        const subtype = comp.subtype;
        let element = null;
        switch (subtype) {
            case "tableview":
                element = (
                    <TableView
                        comp={currentComp}
                        fields={dataFields}
                        items={itemRows}
                        permissions={permissions}
                    />
                );
                break;
            case "gridview":
            case "cardsview":
                element = (
                    <GridView
                        comp={currentComp}
                        fields={dataFields}
                        items={itemRows}
                        permissions={permissions}
                    />
                );
                break;
            case "multicolumnview":
                element = (
                    <MultiColumnView
                        comp={comp}
                        fields={dataFields}
                        items={itemRows}
                        permissions={permissions}
                    />
                );
                break;
            default:
                element = <p>Component subtype {comp.subtype} supported yet</p>;
                break;
        }
        return (
            <QurealSection
                header_sticky={true}
                header={
                    <TableHeader
                        currentComp={currentComp}
                        fields={dataFields}
                        items={
                            searchedItems
                                ? searchedItems
                                : Array.isArray(dataItems)
                                ? dataItems
                                : [dataItems]
                        }
                        searchTerm={searchTerm}
                        onSearch={(term) => setSearchTerm(term)}
                        permissions={permissions}
                    />
                }
            >
                {itemRows.length > 0 && (
                    <div
                        style={{
                            // padding: isMobile ? 12 : 12,
                            maxWidth: "100%",
                            overflowX: "auto",
                            minHeight: 60,
                            // overflow: "auto",
                            background:
                                comp.subtype === "gridview" ||
                                comp.subtype === "cardsview"
                                    ? "transparent"
                                    : // : "white",
                                      "#F2F3F5",
                        }}
                    >
                        {element}
                    </div>
                )}
                {itemRows.length <= 0 && (
                    <div
                        style={{
                            padding: isMobile ? 12 : 12,
                            maxWidth: "100%",
                            overflowX: "auto",
                            minHeight: 150,
                            // overflow: "auto",
                            background: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <Icon
                            component={() => (
                                <i
                                    className="fas fa-box-open"
                                    style={{
                                        fontSize: "2.5rem",
                                        color: "#d1d1d1",
                                    }}
                                ></i>
                            )}
                        />
                        <p
                            style={{
                                fontSize: "1.1rem",
                                color: "#a5a5a5",
                            }}
                        >
                            No data available
                        </p>
                    </div>
                )}
            </QurealSection>
        );
    } else if (loadingFields || loadingItems) {
        return <Loading text={comp.title} />;
    } else {
        return <></>;
    }
}

export default DataView;
