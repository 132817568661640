import log from "cslog";
import objectPath from "object-path";

const getRecord = (record, key) => {
    log.d(`Getting record for ${key}`);
    if (key.includes(".")) {
        return objectPath.get(record, key);
    }
    return record[key];
};
const validateFilter = (filter) => {
    // make sure this doesn't cause vernability
    if (!filter) {
        return (record) => true;
    }
    //=======================================
    for (const [key, values] of Object.entries(filter)) {
        if (key === "_and") {
            return (record) => {
                return values.every((value) => validateFilter(value)(record));
            };
        } else if (key === "_or") {
            return (record) => {
                return values.some((value) => validateFilter(value)(record));
            };
        } else {
            const [operator, val] = Object.entries(values)[0];
            switch (operator) {
                case "_eq":
                    // return (record) => record[key] === val;
                    return (record) => getRecord(record, key) === val;
                case "_neq":
                    return (record) => record[key] !== val;
                case "_gt":
                    return (record) => record[key] > val;
                default:
                    return (record) => false;
            }
        }
    }
    return (record) => true;
};

export const getValidationFunc = (conditions) => {
    log.info("Calculating Validation Func");
    return validateFilter(conditions);
};

export const getConditions = (conditions) => {
    // log.d(conditions, "Conditionsss");
    // for (const [key, value] of Object.entries(conditions)) {
    // }
    const func = validateFilter(conditions);
    log.d(func, "FUNC");
    log.success(func({ status: "pending", name: "rama" }), "FUNC_OUT");
};
