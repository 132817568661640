import styles from "./styles/Default.module.css";
import log from "cslog";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import { getItemRenderer, renderItem } from "../../../_parsers/app/item";
import SectionView from "../views/SectionView";

function Default({ selected_fields = null, fields, item }) {
    const [data, setData] = useState(null);

    log.d(item, "Profile; item");

    if (!selected_fields) {
        selected_fields = fields.map((fi) => fi.field);
    }
    log.d(selected_fields, "Default; selected_fields");
    const item_renderer = getItemRenderer(selected_fields, fields);
    log.d(item_renderer, "Default; item_renderer");
    const rendered_item = renderItem(item_renderer, item);
    log.d(rendered_item, "Default; rendered items");

    useEffect(() => {
        const out = {};
        Object.keys(rendered_item).forEach((key) => {
            if (Array.isArray(item[key])) {
                out[key] = rendered_item[key].map((it) =>
                    Handlebars.compile(it)
                );
            } else {
                out[key] = Handlebars.compile(rendered_item[key]);
            }
        });
        setData(out);
    }, [item]);

    if (!data) {
        return <h2>Loading...</h2>;
    }

    log.d(data, "Default; data after HB");

    return (
        <div className={styles.wrapper}>
            <SectionView item={data} rendered_item={rendered_item} />
        </div>
    );
}

export default Default;
