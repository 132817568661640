import { Button, Form, Space } from "antd";
import log from "cslog";
import React, { useState } from "react";
import { getFormField } from "../../_parsers/form_fields";
import { useCreateItem, useUpdateItem } from "../../actions/mutations/items";
import { cleanFormData } from "../../_helpers/form";
import Alert from "../../common/Alert";
import startCase from "lodash/startCase";
import { history } from "../../_helpers/history";
import { useParams, useRouteMatch } from "react-router-dom";
import { useSingleItem } from "../../actions/app/items";
import Loading from "../../common/Loading";
import useMedia from "react-use/lib/useMedia";

const ItemEditForm = ({
    currentComp,
    id,
    fields,
    fullWidth = false,
    onDone = "back",
}) => {
    const isMobile = useMedia("(max-width: 700px)");
    const [form] = Form.useForm();
    const {
        data: dataItem,
        isSuccess: successItem,
        isLoading: loadingItem,
    } = useSingleItem(currentComp.data.collection, `/${id}`, id !== null);
    const updateMutation = useUpdateItem(currentComp.data.collection, id);
    const [doneAction, setDoneAction] = useState(onDone);

    // const layout = "horizontal";
    // const layout = "vertical";
    const layout = currentComp.layout.style
        ? currentComp.layout.style
        : "vertical";
    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: fullWidth ? 16 : 14 },
    };
    const tailLayout = {
        wrapperCol: {
            offset: layout === "vertical" || isMobile ? 0 : 8,
            span: 12,
        },
    };

    const submitForm = (values) => {
        log.d(values, "Form Values");
        const cleanedValues = cleanFormData(values);
        log.d(cleanedValues, "Cleandeed Valued");
        updateMutation.mutate(cleanedValues);
        // createItem(values);
    };

    const handleSubmit = (reset = true) => {
        form.validateFields()
            .then((values) => {
                log.d(values, "FINLA VALUES");
                submitForm(values);
            })
            .catch((errorInfo) => {})
            .then(() => {
                if (reset) {
                    form.resetFields();
                }
            });
    };

    log.d(updateMutation.error?.response?.data, "Mutation Errors");
    log.d(updateMutation.data, "Mutation Data");

    if (doneAction === "back" && updateMutation.isSuccess) {
        log.p("Going back now");
        history.goBack();
    }

    // log.d(fields, `Fields ${currentComp.data.collection}`);
    log.d(dataItem, "EditForm Data Item");

    if (!successItem) {
        return <Loading />;
    }

    return (
        <Form
            form={form}
            initialValues={dataItem}
            layout={layout}
            {...formItemLayout}
            // onFinish={submitForm}
            style={{
                paddingTop: "30px",
            }}
            size={currentComp.layout.size ? currentComp.layout.size : "default"}
            requiredMark="optional"
        >
            {fields.map((item) => {
                return getFormField(item);
            })}
            <Form.Item {...tailLayout}>
                <Space>
                    <Button
                        type="primary"
                        // htmlType="submit"
                        onClick={() => {
                            if (onDone === "back") {
                                setDoneAction("back");
                            }
                            handleSubmit();
                        }}
                        loading={updateMutation.isLoading}
                    >
                        {currentComp.layout?.save?.text || "Update"}
                    </Button>
                    {currentComp.layout?.save_and_copy && (
                        <Button
                            htmlType="submit"
                            onClick={(e) => {
                                setDoneAction("stay");
                                handleSubmit(false);
                            }}
                            loading={updateMutation.isLoading}
                        >
                            {currentComp.layout?.save?.text || "Save and Copy"}
                        </Button>
                    )}
                    {currentComp.layout?.reset && (
                        <Button
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            {currentComp.layout?.reset?.text || "Reset"}
                        </Button>
                    )}
                </Space>
                {updateMutation.isSuccess && (
                    <Alert
                        type="success"
                        message={`Record created successfully`}
                    />
                )}
                {updateMutation.isError && (
                    <Alert
                        type="error"
                        message={`Record couldn't be created`}
                    />
                )}
            </Form.Item>
        </Form>
    );
};

export default ItemEditForm;
