import React, { useEffect, useState } from "react";
import DataView from "./dataview/DataView";
import FormView from "./formview/FormView";
// import FormView from "../qforms/FormView";
import DisplayView from "./displayview/DisplayView";
import log from "cslog";
import { useCollectionPermission } from "../actions/auth";
import BlankBox from "../common/BlankBox";
import QMadeComponent from "../qmade/QMadeComponent";
import QurealFormComponent from "../qforms/QurealFormComponent";
import QBuiltComponent from "../qbuilt/QBuiltComponent";
import QurealForm from "../forms/QurealForm";
import FormViewStandard from "./formview/FormViewStandard";

function Component({ comp, slug, params }) {
    const {
        data: dataPermi,
        isLoading: loadingPermi,
        isSuccess: successPermi,
    } = useCollectionPermission(comp.data.collection);
    const [permissions, setPermissions] = useState(null);
    const type = comp.type;

    useEffect(() => {
        if (!permissions && dataPermi) {
            setPermissions(dataPermi);
        }
    }, [successPermi, dataPermi]);

    log.d(comp, "Component Data");
    // log.d(permissions, "Component Permissions");

    if (permissions) {
        switch (type) {
            case "dataview":
                if ("read" in permissions) {
                    return (
                        <DataView
                            comp={comp}
                            slug={slug}
                            permissions={permissions}
                        />
                    );
                }
                return <BlankBox text="Read permission denied" />;
            // case "newformview":
            //     if ("create" in permissions) {
            //         return (
            //             <QurealFormComponent
            //                 comp={comp}
            //                 slug={slug}
            //                 permissions={permissions}
            //             />
            //         );
            //     }
            //     return <BlankBox text="Create permission denied" />;
            case "newformview":
                return (
                    <QurealFormComponent
                        comp={comp}
                        slug={slug}
                        permissions={permissions}
                    />
                );

            case "formview":
                // if ("create" in permissions) {
                return (
                    // <FormView
                    <FormViewStandard
                        comp={comp}
                        // slug={slug}
                        // permissions={permissions}
                    />
                );

            // }
            // return <BlankBox text="Create permission denied" />;
            case "displayview":
                return (
                    <DisplayView
                        comp={comp}
                        slug={slug}
                        permissions={permissions}
                    />
                );
            case "qmade":
                return <QMadeComponent comp={comp} params={params} />;
            case "qbuilt":
                return <QBuiltComponent comp={comp} params={params} />;
            default:
                return <h1>Component type not supported yet</h1>;
        }
    }
    return (
        <h2
            style={{
                color: "grey",
            }}
        >
            Loading....
        </h2>
    );
}

export default Component;
