import React, { useEffect, useState } from "react";
import log from "cslog";
import { useItemsFine } from "../../actions/app/items";

function DynamicScreenLoader({ item, onLoad }) {
    const [url, setUrl] = useState(null);
    const { data, isLoading, isSuccess } = useItemsFine(
        item.layout.collection,
        item.layout.query ? item.layout.query : "",
        true
    );

    // log.d(data, "Dynamic Loaded Data");

    useEffect(() => {
        if (isSuccess && data) {
            onLoad({ [`id_${item.id}`]: data });
        }
    }, [data, isSuccess]);

    return <></>;
}

export default DynamicScreenLoader;
