import React from "react";
import BrowseTemplates from "./BrowseTemplates";

function TemplatesBar({ type }) {
    return (
        <div>
            <BrowseTemplates
                creation_type={type}
                show_as_row={true}
                height={250}
            />
        </div>
    );
}

export default TemplatesBar;
