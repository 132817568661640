import React from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import CollectionForm from "./CollectionForm";

function CollectionCreateComponent() {
    return (
        <QurealSection
            header={<SectionHeader heading="Create New Collection" />}
        >
            <CollectionForm />
        </QurealSection>
    );
}

export default CollectionCreateComponent;
