import classNames from "classnames";
import React, { useMemo } from "react";
import { getValidationFunc } from "../utils/conditions";
import ArrayField from "./elements/ArrayField";
import Dropdown from "./elements/Dropdown";
import JSONField from "./elements/JSONField";
import LookupField from "./elements/LookupField";
import MultiDropown from "./elements/MultiDropdown";
import RelationField from "./elements/RelationField";
// import "../qui/forms.css";
import produce from "immer";
import log from "cslog";
import ImageField from "./elements/ImageField";
import { getImageURL } from "../_helpers/image";
import DateTime from "./elements/DateTime";

function QField({ field, value, setValue, formData, setFormData }) {
    let f = { ...field };

    log.d(value, field.name);

    const condFuncs = useMemo(() => {
        if ("conditions" in field) {
            const condFuncs = [];
            field.conditions.forEach((cond) => {
                const condFunc = getValidationFunc(cond.rules);
                condFuncs.push({
                    func: condFunc,
                    action: cond.action,
                });
            });
            return condFuncs;
        }
        return [];
    }, [field]);

    condFuncs.forEach((cf) => {
        if (cf.func(formData)) {
            f = {
                ...f,
                ...cf.action,
            };
        }
    });

    if (f.hidden) {
        return null;
    }

    const style = {
        // width: `${((f.width || 12) / 12) * 100}%`,
        width: `calc(${((f.width || 12) / 12) * 100}% - 10px)`,
    };

    switch (f.type) {
        case "text":
            return (
                <div
                    className={classNames("qfield", {
                        required: f.required,
                    })}
                    key={f.id}
                    style={style}
                >
                    <label>{f.label}</label>
                    <input
                        required={f.required}
                        value={value}
                        disabled={f.readonly}
                        onChange={(eve) => setValue(eve.target.value)}
                    />
                </div>
            );
        case "number":
            return (
                <div
                    className={classNames("qfield", {
                        required: f.required,
                    })}
                    key={f.id}
                    style={style}
                >
                    <label>{f.label}</label>
                    <input
                        type="number"
                        required={f.required}
                        disabled={f.readonly}
                        value={value}
                        onChange={(eve) => setValue(parseInt(eve.target.value))}
                    />
                </div>
            );
        case "textarea":
        case "richtext":
        case "markdown":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <textarea
                        value={value}
                        disabled={f.readonly}
                        onChange={(eve) => setValue(eve.target.value)}
                        rows={4}
                    />
                </div>
            );
        // case "richtext":
        //     return <div>RichText</div>;
        // case "markdown":
        //     return <div>Markdown</div>;
        case "tags":
            return <div>Tags</div>;
        case "dropdown":
            log.d(value, `Dropdown ${f.name} value`);
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <Dropdown
                        options={f.options.map((op) => ({
                            value: op.value,
                            label: op.text,
                        }))}
                        value={value}
                        onChange={setValue}
                        // optionRender={(op) => (
                        //     <div style={{ backgroundColor: "hotpink" }}>
                        //         {op.label}
                        //     </div>
                        // )}
                    />
                </div>
            );
        case "dropdown-multiple":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <MultiDropown
                        options={f.options.map((op) => ({
                            value: op.value,
                            label: op.text,
                        }))}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "relationM2O":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <RelationField
                        field={f}
                        many={false}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "relation-o2m":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <RelationField
                        field={f}
                        many={true}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "relationM2M":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <RelationField
                        field={f}
                        many={true}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "image":
            const imgsrc = getImageURL(formData.icon);
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <ImageField field={f} value={value} onChange={setValue} />
                </div>
            );
        case "boolean":
        case "checkbox":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={f.id}
                            checked={value}
                            onClick={() => setValue(!value)}
                            onChange={() => {}}
                        />
                        {f.label}
                    </label>
                </div>
            );
        case "checkbox-multiple":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    {f.options.map((op) => {
                        return (
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name={op.value}
                                    checked={value.includes(op.value)}
                                    onClick={() => {
                                        if (value.includes(op.value)) {
                                            setValue(
                                                produce(value, (df) => {
                                                    const ci = df.indexOf(
                                                        op.value
                                                    );
                                                    df.splice(ci, 1);
                                                })
                                            );
                                        } else {
                                            setValue(
                                                produce(value, (df) => {
                                                    df.push(op.value);
                                                })
                                            );
                                        }
                                    }}
                                    onChange={() => {}}
                                />
                                {op.text}
                            </label>
                        );
                    })}
                </div>
            );
        case "checkbox-tree":
            return <div>Checkbox Tree</div>;
        case "datetime":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <DateTime field={f} value={value} onChange={setValue} />
                </div>
            );
        case "radio":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <div className="qh">
                        {f.options.map((option) => {
                            return (
                                <div className="qradio" key={option.value}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name={f.id}
                                        value={option.value}
                                        checked={value === option.value}
                                        onClick={() => setValue(option.value)}
                                        onChange={() => {}}
                                    />
                                    <label htmlFor={option.value}>
                                        {option.text}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        case "code":
            return <div>Code</div>;
        case "icon-picker":
            return <div>Icon Picker</div>;
        case "slider":
            return <div>Slider</div>;
        case "repeater":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <ArrayField field={f} value={value} onChange={setValue} />
                </div>
            );
        case "lookup":
            return (
                <div className="qfield" key={f.id}>
                    <label>{f.label}</label>
                    <LookupField
                        field={f}
                        many={false}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "lookup-many":
            return (
                <div className="qfield" key={f.id}>
                    <label>{f.label}</label>
                    <LookupField
                        field={f}
                        many={true}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "json":
            return (
                <JSONField
                    field={f}
                    formData={formData}
                    // setFormData={setFormData}
                    value={value}
                    setValue={setValue}
                />
            );
        case "submit":
            return (
                <div className="qfield" key={f.id}>
                    <></>
                    <button type="submit">{f.label || "Submit"}</button>
                </div>
            );
        default:
            return null;
    }
}

export default QField;
