import React from "react";
import { useMutation } from "react-query";
import { secureAxios } from "../../_helpers/auth";
import { queryClient } from "../../_helpers/query";

export const useCreateItem = (collection, onDone = () => {}) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    } else if (collection === "roles") {
        query = "roles";
    }
    return useMutation(
        (newItem) => {
            return secureAxios
                .post(`/${query}`, newItem)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", collection]);
                onDone();
            },
        }
    );
};

export const useUpdateItem = (collection, id, onDone = () => {}) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    } else if (collection === "roles") {
        query = "roles";
    }
    return useMutation(
        (newItem) => {
            return secureAxios
                .patch(`/${query}/${id}`, newItem)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", collection]);
                onDone();
            },
        }
    );
};
