import React from "react";

function EmptyFull({ text = "No data available" }) {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <h3>{text}</h3>
        </div>
    );
}

export default EmptyFull;
