import { Table } from "antd";
import React from "react";
import { getFields } from "../../../_parsers/app/fields";
import log from "cslog";
import ActionCell from "../../commons/ActionCell";
import { getValidationFunc } from "../../../_helpers/app/conditions";

function TableView({ comp, items, fields, permissions }) {
    let selectedFields = comp.data.fields;
    let pfields = [];
    if (!selectedFields) {
        pfields = getFields(fields);
    } else {
        pfields = getFields(
            selectedFields.map((item) => fields.find((ii) => ii.field === item))
        );
    }

    let actions = comp.actions.filter((item) => item.status === "published");
    log.d(actions, "Actions in tableview");
    if (actions.length > 0) {
        const processedActions = [];
        for (const action of actions) {
            processedActions.push({
                ...action,
                validationFunc: getValidationFunc(action.conditions),
            });
        }
        log.d(processedActions, "Processed Actionss");
        //check permissions
        //
        //--------------------

        pfields.push({
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (item, record) => (
                <ActionCell
                    actions={processedActions}
                    item={item}
                    record={record}
                    collection={comp.data.collection}
                    permissions={permissions}
                />
            ),
            // render
        });
    }

    let tableSpacing = comp.layout.spacing;

    return (
        <div style={{ padding: "15px", backgroundColor: "white" }}>
            <Table
                // loading={true}
                columns={pfields}
                dataSource={items}
                rowKey="id"
                // size="middle"
                size={tableSpacing}
                bordered={true}
                showHeader={comp.layout.header}
                pagination={{
                    defaultPageSize: comp.layout.pagination?.row_per_page
                        ? comp.layout.pagination?.row_per_page
                        : 50,
                    position: [
                        comp.layout.pagination
                            ? comp.layout.pagination.position
                            : "none",
                    ],
                }}
                // scroll={{ y: "50vh" }}
                // rowClassName="qureal-ant-table-row"
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: () => alert("Row clicked"),
                //     };
                // }}
                // loading={status === "loading"}
            />
        </div>
    );
}

export default TableView;
