import React, { useState } from "react";
import CreateNewCard from "./CreateNewCard";
import CreationDisplayArea from "./CreationDisplayArea";
import { Layout } from "antd";
import QurealSection from "../common/QurealSection";

const { Content } = Layout;

function CreationsHome({ me }) {
    const [type, setType] = useState("all_creation");
    return (
        <QurealSection>
            <div
                style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <CreateNewCard
                    selected_type={type}
                    setSelectedType={setType}
                    me={me}
                />
                <CreationDisplayArea type={type} />
            </div>
        </QurealSection>
    );
}

export default CreationsHome;
