import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { useEventCalender } from "../actions/events";
import log from "cslog";
import styles from "./styles/ContentCalender.module.css";
import { PreviewTemplateList } from "../qmade/creator/PreviewTemplateList";

function ContentCalender() {
    const { isSuccess, isLoading, data } = useEventCalender(true);
    const [open, setOpen] = useState(false)
    const [selected_card, setSelectedCard] = useState(null);

    log.d(data, "Event calender data");
    log.d(open, "Now Open is")

    return (
        <QurealSection header={<SectionHeader heading="Content Calender" />}>
            {/* <PreviewTemplateList
                tags={["gandhi"]}
                enable={true}
                  open={true}
                setOpen={setOpen}
            /> */}
            <div className={styles.wrapper}>
                {isSuccess &&
                    [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                    ].map((month, index) => (
                        <MonthSection month={month} data={data[index]}
                            key={index}
                            onCardClick={(item) => {
                                log.d(item, "Item clicked card")
                                setOpen(true)
                                setSelectedCard(item)
                            }} />
                    ))}
            </div>
            {selected_card && <PreviewTemplateList
                // enable={open && selected_card?.filter !== null}
                enable={open}
                // filter={selected_card?.filter ? encodeURIComponent(JSON.stringify(selected_card.filter)) : null}
                filter={selected_card?.filter ? JSON.stringify(selected_card.filter) : null}
                open={open}
                setOpen={setOpen}
            />}
        </QurealSection>
    );
}

function MonthSection({ month, data = [], onCardClick }) {

    if (data.length === 0) {
        return null;
    }
    return (
        <div className={styles.month_wrapper}>
            <h5 className={styles.month_name}>{month}</h5>
            {data.length > 0 && (
                <div className={styles.items_wrapper}>
                    {data.map((item) => (
                        <OneCard item={item} month={month} onCardClick={onCardClick} />
                    ))}
                </div>
            )}
        </div>
    );
}

function OneCard({ item, month, onCardClick }) {
    return (
        <div className={styles.item_wrapper} onClick={() => onCardClick(item)}>
            <p className={styles.item_title}>{item.name}</p>
            {/* <p>{item.date}</p> */}
            <div className={styles.item_dt_wrapper}>
                <p className={styles.item_dt}>{item.dt?.getDate()}</p>
                <p className={styles.item_month_name}>{month}</p>
            </div>
        </div>
    );
}

export default ContentCalender;
