import React, { useEffect } from "react";
import log from "cslog";
import { Button } from "antd";
import { GoogleOutlined } from "@ant-design/icons";

// import { GoogleLogin } from "react-google-login";
// import { gapi } from "gapi-script";

// const clientId =
//     "404662816537-peoefo2mt6tb0q07lm2gd7hit5otjja3.apps.googleusercontent.com";

function GoogleLoginButton({
    btn_style = {
        width: "100%",
    },
}) {
    return (
        <Button
            onClick={() =>
                window.open(
                    "https://api.qureal.com/auth/login/google?redirect=https://app.qureal.com/verify_auth",
                    "_self"
                )
            }
            style={{
                ...btn_style,
                backgroundColor: "#4285F4",
            }}
            icon={<GoogleOutlined />}
            type="primary"
            color="blue"
            // ghost
        >
            {/* <i className="fab fa-google" /> */}
            Google
        </Button>
    );
}

// function GoogleLoginButton2() {
//     useEffect(() => {
//         const initClient = () => {
//             gapi.client.init({
//                 clientId: clientId,
//                 scope: "",
//             });
//         };
//         gapi.load("client:auth2", initClient);
//     });

//     const onSuccess = (res) => {
//         log.d(res, "Google login success");
//     };
//     const onFailure = (err) => {
//         log.d(err, "Google logni failed");
//     };

//     return (
//         <GoogleLogin
//             clientId={clientId}
//             buttonText="Sign in with Google"
//             onSuccess={onSuccess}
//             onFailure={onFailure}
//             cookiePolicy={"single_host_origin"}
//             isSignedIn={true}
//         />
//     );
// }

export default GoogleLoginButton;
