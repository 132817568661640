import React from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";

function EComProducts() {
    return (
        <QurealSection header={<SectionHeader heading="Products" />}>
            <p>Hello World</p>
            <p>Hello World</p>
            <p>Hello World</p>
            <p>Hello World</p>
            <p>Hello World</p>
            <p>Hello World</p>
        </QurealSection>
    );
}

export default EComProducts;
