import styles from "./styles/CreateNewCard.module.css";
import React, { useState } from "react";
import classNames from "classnames";

function CreateNewCard({ selected_type, setSelectedType, me }) {
    return (
        <div className={styles.wrapper}>
            {/* <h4 className={styles.welcome_text}>Hello Shivam</h4> */}
            <h3 className={styles.ask_text}>
                {me?.first_name}, Let's create something awesome today!
            </h3>

            <div className={styles.items_bar}>
                <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "all_creation",
                    })}
                    onClick={() => setSelectedType("all_creation")}
                >
                    All
                </div>
                <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "website",
                    })}
                    onClick={() => setSelectedType("website")}
                >
                    Website
                </div>
                <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "slider",
                    })}
                    onClick={() => setSelectedType("slider")}
                >
                    Slider
                </div>
                <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "magazine",
                    })}
                    onClick={() => setSelectedType("magazine")}
                >
                    Magazine
                </div>
                <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "qdf",
                    })}
                    onClick={() => setSelectedType("qdf")}
                >
                    QDF
                </div>
                <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "singlepage",
                    })}
                    onClick={() => setSelectedType("singlepage")}
                >
                    Social Media
                </div>
                {/* <div
                    className={classNames(styles.item_wrapper, {
                        [styles.active]: selected_type === "packaging_box",
                    })}
                    onClick={() => setSelectedType("packaging_box")}
                >
                    Box Packaging
                </div> */}
            </div>
            {/* <AllCreations /> */}
        </div>
    );
}

export default CreateNewCard;
//inspiration
//https://new.express.adobe.com/?referrer=https%3A%2F%2Fwww.google.com%2F&url=%2Fexpress%2F&ctaid=marquee&_branch_match_id=1250850696368271814&_branch_referrer=H4sIAAAAAAAAAxWLwQrCMBAFv6YeE9CbEMQcKnjyIp637bMtTd11NyV%2BvikMDAzMlLPY2XsauIMJ6SJs2ZGIS%2FNn8be7xkf7fMXYXRRvqELDtE%2FN6doc20opxY3MY4Lrea3hsGkK1fiJwmwvfaZ5CCvpdwP%2BGhWAO3QAAAA%3D
