import React, { useState } from "react";
import log from "cslog";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import useMedia from "react-use/lib/useMedia";

const { Search: AntSearch } = Input;

const Search = ({ currentComp, searchTerm, onSearch }) => {
    const isMobile = useMedia("(max-width: 700px)");
    const [show, setShow] = useState(false);

    return (
        <Fragment>
            {!show && (
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={() => setShow(true)}
                />
            )}
            {show && (
                <Input
                    value={searchTerm}
                    onChange={(e) => onSearch(e.target.value)}
                    // onPressEnter={}
                    prefix={<SearchOutlined />}
                    style={{
                        width: "200px",
                        // backgroundColor: "transparent",
                    }}
                    allowClear
                />
            )}
        </Fragment>
    );
};

export default Search;
