import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Divider, Button, theme, Menu } from "antd";
import {
    LogoutOutlined,
    SettingOutlined,
    MenuOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { useLogout } from "../actions/auth";
import { history } from "../_helpers/history";

const { useToken } = theme;

const TopBarOptions = ({ name, orgs, isMobile }) => {
    const { token } = useToken();
    const {
        logout,
        loading: loadingLogout,
        success: successLogout,
    } = useLogout();

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        minWidth: "200px",
    };

    const menuStyle = {
        boxShadow: "none",
    };

    const items = [
        {
            key: "account_settings",
            label: "Account Settings",
            icon: <SettingOutlined />,
            disabled: false,
        },
        {
            key: "logout",
            label: "Logout",
            icon: <LogoutOutlined />,
            loading: loadingLogout,
        },
    ];

    return (
        <Dropdown
            menu={{
                items,
                onClick: ({ key }) => {
                    if (key === "logout") {
                        logout();
                    } else if (key === "account_settings") {
                        history.push("/settings/update-profile");
                    }
                },
            }}
            dropdownRender={(menu) => (
                <div style={contentStyle}>
                    <OrgPicker orgs={orgs} />

                    <Divider style={{ margin: 0 }} />
                    <div
                        style={{
                            padding: "15px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            backgroundColor: "white",
                        }}
                    >
                        <a
                            target="_blank"
                            href="https://app.qureal.com/privacy_policy"
                        >
                            Privacy Policy
                        </a>
                        <a
                            target="_blank"
                            href="https://app.qureal.com/terms_and_conditions"
                        >
                            Terms & Conditions
                        </a>
                    </div>
                    {React.cloneElement(menu, { style: menuStyle })}
                </div>
            )}
        >
            <div
                onClick={(e) => e.preventDefault()}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {" "}
                <Space align="center">
                    {isMobile ? (
                        <UserOutlined />
                    ) : (
                        <Button icon={<UserOutlined />}>{name}</Button>
                    )}
                </Space>
            </div>
        </Dropdown>
    );
};

export default TopBarOptions;

function OrgPicker({ orgs = [] }) {
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    return (
        <Menu
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            // mode="inline"
            // theme="dark"
            items={orgs.map((org) => getItem(org.name, org.id))}
        />
    );
}
