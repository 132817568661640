import React, { Fragment } from "react";
import styles from "./styles/Editor.module.css";
import { Button } from "antd";
import { useItemsFine } from "../actions/app/items";
import classNames from "classnames";

function ListScreens({ app, editScreen, createNewScreen }) {
    const {
        isLoading: loadingApps,
        isSuccess: successApps,
        data: dataApps,
    } = useItemsFine("qureal_screen", `filter[app]=${app.id}`, true);

    if (loadingApps) {
        return <h4>Loading...</h4>;
    }

    return successApps ? (
        <Fragment>
            <div className={styles.itemWrapper}>
                {dataApps.map((app) => {
                    return (
                        <div className={styles.item}>
                            <span className={styles.name}>{app.name}</span>
                            <i
                                className={classNames(
                                    "fas fa-pencil-alt",
                                    styles.iconBtn
                                )}
                                onClick={(e) => {
                                    editScreen(app);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={styles.itemWrapper}>
                <Button
                    type="primary"
                    ghost
                    icon={
                        <i
                            className="fas fa-plus"
                            style={{
                                marginRight: "10px",
                            }}
                        />
                    }
                    onClick={createNewScreen}
                >
                    Add New Screen
                </Button>
            </div>
        </Fragment>
    ) : (
        <h3>Loading failed</h3>
    );
}

export default ListScreens;
