import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
//========================
import { $generateNodesFromDOM } from "@lexical/html";

// import ImagesPlugin from "./plugins/ImagesPlugin";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import "./styles/qrte_main.css";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useState } from "react";
import { $createParagraphNode, $getRoot } from "lexical";

function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
}

function OnChangePlugin({ onChange }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            onChange(editorState);
        });
    }, [editor, onChange]);
    return <></>;
}

const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
    ],
};

// ======================================
function isLexicalValue(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}
export const LoadInitialContent = ({ initialContent }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!initialContent) {
            return;
        }
        if (isLexicalValue(initialContent)) {
            return;
        }
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialContent, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            console.log(dom, nodes);
            const paragraphNode = $createParagraphNode();
            nodes.forEach((n) => paragraphNode.append(n));
            $getRoot().append(paragraphNode);
        });
    }, []);
    return null;
};
//======================================

export default function QurealRichTextEditor({ value, onChange }) {
    function onEditorValueChange(editorState) {
        const editorStateJSON = editorState.toJSON();
        // setEditorState(JSON.stringify(editorStateJSON));
        onChange(editorStateJSON);
    }

    return (
        <LexicalComposer
            initialConfig={{
                ...editorConfig,
                ...(isLexicalValue(value) ? { editorState: value } : {}),
                // editorState: value,
            }}
        >
            <div className="editor-container">
                <ToolbarPlugin />
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable className="editor-input" />
                        }
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin />
                    {/* <TreeViewPlugin /> */}
                    <AutoFocusPlugin />
                    <CodeHighlightPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoLinkPlugin />
                    {/* <ImagesPlugin /> */}
                    <ListMaxIndentLevelPlugin maxDepth={7} />
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                </div>
            </div>
            <OnChangePlugin onChange={onEditorValueChange} />
            <LoadInitialContent initialContent={value} />
        </LexicalComposer>
    );
}
