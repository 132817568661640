import { Button, Typography } from "antd";
import styles from "./styles/EnquiryCard.module.css";
import React, { useEffect, useState } from "react";
import { PhoneFilled, PhoneOutlined, MailFilled } from "@ant-design/icons";
import { getTime } from "../_helpers/app/dt";
import { useUpdateItemMutation } from "../actions/app/items";

const { Paragraph, Text } = Typography;

function EnquiryCard({ data }) {
    const updateMutation = useUpdateItemMutation();
    return (
        <div className={styles.wrapper}>
            <div className={styles.top_wrapper}>
                <div className={styles.left_box}>
                    <h3 className={styles.name}>{data.full_name}</h3>
                    {/* <p className={styles.company}>{data.company_name}</p> */}
                    <p className={styles.icon_item}>
                        <i
                            className="fas fa-building"
                            style={{ color: "#999" }}
                        />
                        {data.company_name}
                    </p>
                    <p className={styles.icon_item}>
                        <i
                            className="fas fa-phone"
                            style={{ color: "#1AC37E" }}
                        />
                        {data.contact_no}
                    </p>

                    <p className={styles.icon_item}>
                        <i
                            className="fas fa-at"
                            style={{ color: "royalblue" }}
                        />
                        {data.email}
                    </p>
                    <p className={styles.icon_item}>
                        <i
                            className="fas fa-clock"
                            style={{ color: "royalblue" }}
                        />
                        {getTime(data.date_created)}
                    </p>
                    {/* <p className={styles.dt}>{getTime(data.date_created)}</p> */}
                </div>
                <div className={styles.right_box}>
                    <StatusLabel status={data.status} />
                    <p
                        style={{
                            color: "#999",
                            paddingBottom: "5px",
                            fontSize: "0.9em",
                        }}
                    >
                        REQUIREMENTS
                    </p>
                    <MaxLineText text={data.requirement} />
                    {/* <Paragraph
                        ellipsis={{
                            rows: 6,
                            expandable: true,
                            symbol: "more",
                        }}
                    >
                        {data.requirement}
                    </Paragraph> */}
                </div>
            </div>
            <div className={styles.bottom_wrapper}>
                <Button
                    type="primary"
                    style={{ backgroundColor: "#1AC37E" }}
                    icon={<PhoneFilled />}
                    onClick={(e) => {
                        window.location.href = `tel:${data.contact_no}`;
                    }}
                >
                    Call
                </Button>
                <Button
                    type="primary"
                    style={{ backgroundColor: "royalblue" }}
                    icon={<MailFilled />}
                    onClick={(e) => {
                        window.location.href = `mailto:${data.email}`;
                    }}
                >
                    Mail
                </Button>
                {data.status === "active" && (
                    <Button
                        danger
                        type="primary"
                        onClick={() => {
                            updateMutation.mutate({
                                collection: "qform_contact",
                                id: data.id,
                                data: {
                                    status: "on_hold",
                                },
                            });
                        }}
                        loading={updateMutation.isLoading}
                    >
                        Hold
                    </Button>
                )}
                {data.status !== "closed" && (
                    <Button
                        type="primary"
                        onClick={() => {
                            updateMutation.mutate({
                                collection: "qform_contact",
                                id: data.id,
                                data: {
                                    status: "closed",
                                },
                            });
                        }}
                        loading={updateMutation.isLoading}
                    >
                        Close
                    </Button>
                )}
                {data.status === "closed" && (
                    <Button
                        type="primary"
                        onClick={() => {
                            updateMutation.mutate({
                                collection: "qform_contact",
                                id: data.id,
                                data: {
                                    status: "active",
                                },
                            });
                        }}
                        loading={updateMutation.isLoading}
                    >
                        Reopen
                    </Button>
                )}
            </div>
        </div>
    );
}

export default EnquiryCard;

function MaxLineText({ text, limit = 600 }) {
    const [expended, setExpended] = useState(false);

    useEffect(() => {
        if (text.length < limit) {
            setExpended(true);
        }
    }, [text]);
    return (
        <div>
            {expended && <p>{text}</p>}
            {!expended && <p>{text.substring(0, limit)}...</p>}
            {!expended && (
                <span
                    style={{ color: "royalblue", cursor: "pointer" }}
                    onClick={() => setExpended(true)}
                >
                    {" "}
                    Read More
                </span>
            )}
        </div>
    );
}

const STATUS_MAPPING = {
    active: { text: "Active", bg: "#00C897" },
    on_hold: { text: "On Hold", bg: "red" },
    closed: { text: "Closed", bg: "#607D8B" },
};
function StatusLabel({ status }) {
    const val = STATUS_MAPPING[status] || {};

    return (
        <span
            className={styles.label}
            style={{
                backgroundColor: val.bg,
            }}
        >
            {val.text}
        </span>
    );
}
