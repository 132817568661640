import log from "cslog";

export const getTemplateVariables = (display_template) => {
    const re = /\{\{[^}]*\}\}/g;
    // const arr = [...display_template.matchAll(re)];
    const arr = display_template.match(re);
    const out = arr.map((item) => item.substring(2, item.length - 2));
    // log.d(out, "Regex Matches");
    return out;
};

export const generateUrl = (module, fields, slug) => {
    // log.p("Generating URL");
    // log.d(module, "Module");

    let out = "limit=25&fields[]=id";
    let pfields = module.data?.fields;
    if (!pfields) {
        pfields = fields.map((item) => item.field);
    }
    // log.d(pfields, "pfields");
    pfields.forEach((pf) => {
        const field = fields.find((item) => item.field === pf);
        if (!field) {
            return;
        }
        const dtype = field.meta.display;
        switch (dtype) {
            case "related-values":
                const display_template = field?.meta?.display_options?.template;
                if (display_template) {
                    //match RegEx and make one to any fields query
                    // log.d(display_template, "Display Template");

                    //add id always (can be GLICH)
                    out = out + `&fields[]=${field.field}.id`;
                    const vars = getTemplateVariables(display_template);
                    vars.forEach((item) => {
                        out = out + `&fields[]=${field.field}.${item}`;
                    });
                }
                break;
            case "user":
                let display_template2 = field?.meta?.options?.template;
                if (display_template2) {
                    display_template2 = display_template2.replace("$", "");
                    //match RegEx and make one to any fields query
                    // log.d(display_template2, "Display Template");
                    const vars = getTemplateVariables(display_template2);
                    vars.forEach((item) => {
                        out = out + `&fields[]=${field.field}.${item}`;
                    });
                }
                break;
            default:
                out = out + `&fields[]=${field?.field}`;
        }
    });
    const filters = module.data.filters;
    const filter_match = module.data.filter_match
        ? module.data.filter_match
        : "_and";

    const hidden_filters = module.data.hidden_filters;
    const hidden_filter_match = module.data.hidden_filter_match
        ? module.data.hidden_filter_match
        : "_and";

    const external_filters = module.data.external_filters
        ? module.data.external_filters
        : [];
    const external_filter_match = module.data.external_filter_match
        ? module.data.external_filter_match
        : "_and";

    let hfilterString = null;
    if (hidden_filters.length > 0) {
        // log.d(hidden_filters, "Hidden Filters");
        let hfilterArr = [];
        hidden_filters.forEach((filter) => {
            let value = filter.value;
            if (value === "$SLUG") {
                value = slug;
            }
            // const obj = { [filter.field]: { [filter.operator]: value } };
            let obj = { [filter.field]: { [filter.operator]: value } };
            if ("subfield" in filter) {
                obj = {
                    [filter.field]: {
                        [filter.subfield]: { [filter.operator]: value },
                    },
                };
            }
            hfilterArr.push(obj);
        });

        if (hfilterArr.length === 1) {
            hfilterString = hfilterArr[0];
        } else if (hfilterArr.length > 1) {
            hfilterString = {
                [hidden_filter_match]: hfilterArr,
            };
        }
        // log.d(hfilterString, "Hidden Filter String");
    }

    let efilterString = null;
    if (external_filters.length > 0) {
        log.d(external_filters, "External Filters");
        let efilterArr = [];
        external_filters.forEach((filter) => {
            let value = filter.value;
            if (value === "$SLUG") {
                value = slug;
            }
            const obj = { [filter.field]: { [filter.operator]: value } };
            efilterArr.push(obj);
        });

        if (efilterArr.length === 1) {
            efilterString = efilterArr[0];
        } else if (efilterArr.length > 1) {
            efilterString = {
                [external_filter_match]: efilterArr,
            };
        }
        log.d(efilterString, "External Filter String");
    }

    if (filters.length <= 0) {
        if (hfilterString && efilterString) {
            const heFilterString = {
                _and: [hfilterString, efilterString],
            };
            out += `&filter=${JSON.stringify(heFilterString)}`;
        } else if (hfilterString) {
            out += `&filter=${JSON.stringify(hfilterString)}`;
        } else if (efilterString) {
            out += `&filter=${JSON.stringify(efilterString)}`;
        }
    } else {
        log.d(filters, "Filters");
        let filterArr = [];
        filters.forEach((filter) => {
            const obj = { [filter.field]: { [filter.operator]: filter.value } };
            filterArr.push(obj);
        });
        let filterString = "";
        if (filters.length === 1) {
            filterString = filterArr[0];
        } else {
            filterString = {
                [filter_match]: filterArr,
            };
        }
        // log.d(filterString, "Filter String");

        // Now combile filters
        if (hfilterString && efilterString) {
            const fheFilterString = {
                _and: [hfilterString, efilterString, filterString],
            };
            out += `&filter=${JSON.stringify(fheFilterString)}`;
        } else if (hfilterString) {
            const fhFilterString = {
                _and: [hfilterString, filterString],
            };
            out += `&filter=${JSON.stringify(fhFilterString)}`;
        } else if (efilterString) {
            const feFilterString = {
                _and: [efilterString, filterString],
            };
            out += `&filter=${JSON.stringify(feFilterString)}`;
        } else {
            out += `&filter=${JSON.stringify(filterString)}`;
        }
    }

    const sort = module.data.sort;
    if (sort) {
        out += `&sort=${sort}`;
    }
    log.d(out, "OUT");
    return out;
};

export function fillValuesInTemplate(template, values) {
    let out = template;
    // log.d(values, "Values");
    if (!values) {
        return "None";
    }
    Object.keys(values).forEach((item) => {
        const value = values[item];
        // log.p(`Replacing {{${item}}} with ${value}`);
        if (value) {
            out = out.replace(`{{${item}}}`, value);
        }
        //  else {
        //     out = out.replace(`{{${item}}}`, "");
        // }
    });
    // log.d(out, "Filled Template");
    return out;
}

//=========================
// Regex:   \{\{[^}]*\}\}
// Try here: https://www.regextester.com/99428
