import React from "react";
import { Spin } from "antd";

function LoadingFull({ text }) {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "50px",
            }}
        >
            <Spin size="large" />
        </div>
    );
}

export default LoadingFull;
