import { Button, Select, Spin, Tag } from "antd";
import log from "cslog";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import { useItemsFine } from "../../actions/app/items";
import { useRelation } from "../../actions/app/relations";
import { useAtom } from "jotai";
import { dialogState } from "../../states/dialog";

const { Option } = Select;

function TagRender(props) {
    const { label, value, closable, onClose, foreignTable } = props;
    const [, setDialog] = useAtom(dialogState);
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            // closable={closable}
            // onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
            <span
                style={{
                    cursor: "pointer",
                    color: "gray",
                    marginLeft: "10px",
                }}
                onClick={() => {
                    setDialog({
                        show: true,
                        intent: "edit",
                        data: {
                            collection: foreignTable,
                            id: value,
                        },
                    });
                }}
            >
                <i className="fas fa-pen"></i>
            </span>
            <span
                style={{
                    cursor: "pointer",
                    color: "gray",
                    marginLeft: "10px",
                }}
                onClick={onClose}
            >
                <i className="fas fa-times"></i>
            </span>
        </Tag>
    );
}

function ForeignField({ value, field, defaultSelect = false, ...props }) {
    const [options, setOptions] = useState([]);
    const [url, setUrl] = useState(null);
    const { data: relationData, isSuccess: successRelation } = useRelation(
        field.collection,
        field.field,
        field.schema === null
    );
    const [foreignTable, setForeignTable] = useState(null);
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(foreignTable, url, url !== null && foreignTable !== null);
    const [, setDialog] = useAtom(dialogState);

    useEffect(() => {
        if (relationData) {
            const foreign_table = relationData.meta?.many_collection;
            if (foreign_table) {
                setForeignTable(foreign_table);
            }
        }
    }, [relationData]);

    useEffect(() => {
        const foreign_table = field.schema?.foreign_key_table;
        if (foreign_table) {
            setForeignTable(foreign_table);
        }
        // const option_template = field.meta.options?.template;
        // const pfields = getTemplateVariables(option_template);

        // setUrl("limit=1000");
        setUrl("limit=1000&sort=-id"); //check if id is availabel in colleciton
        log.warn("ForeignField sort by -id");
        // setUrl("limit=1000&sort=-date_updated"); //check if date_updated is availabel in colleciton
    }, [field]);

    useEffect(() => {
        if (dataItems) {
            const option_template = Handlebars.compile(
                field.meta.options?.template
            );

            const newOptions = [];
            dataItems.forEach((item) => {
                newOptions.push({
                    label: option_template(item),
                    value: item.id,
                });
            });
            setOptions(newOptions);
            if (!props.value && defaultSelect) {
                // props.onChange(1);
                props.onChange(newOptions[0]["value"]);
            }
        }
    }, [dataItems]);

    // log.d(dataItems, "Foreign Data");
    // log.d(relationData, "Relation Data");
    log.d(props, "ForiegnField Props");

    const onSearch = () => {};
    return (
        <div>
            <Select
                // labelInValue
                value={value}
                filterOption={false}
                onSearch={onSearch}
                // notFoundContent={fetching ? <Spin size="small" /> : null}
                loading={loadingItems}
                {...props}
                options={options}
                notFoundContent={loadingItems ? <Spin size="small" /> : null}
                // mode={props.many ? "multiple" : "tags"}
                {...(props.many ? { mode: "multiple" } : {})}
                // tagRender={TagRender}
                tagRender={({ label, value, closable, onClose }) => (
                    <TagRender
                        label={label}
                        value={value}
                        closable={closable}
                        onClose={onClose}
                        foreignTable={foreignTable}
                    />
                )}
            ></Select>
            {foreignTable && (
                <Button
                    ghost
                    type="primary"
                    style={{
                        marginTop: "10px",
                    }}
                    onClick={() => {
                        setDialog({
                            show: true,
                            intent: "create",
                            data: {
                                collection: foreignTable,
                            },
                        });
                    }}
                >
                    Add New
                </Button>
            )}
            {!props.many && (
                <Button
                    ghost
                    type="primary"
                    style={{
                        marginLeft: "5px",
                    }}
                    onClick={() => {
                        setDialog({
                            show: true,
                            intent: "edit",
                            data: {
                                collection: foreignTable,
                                id: value,
                            },
                        });
                    }}
                >
                    Edit
                </Button>
            )}
        </div>
    );
}

export default ForeignField;
