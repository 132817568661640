import React from "react";
import { useFieldsDirect } from "../../actions/app/fields";
import { useSingleItem } from "../../actions/app/items";
import log from "cslog";
import Default from "../displayview/default/Default";
import Loading from "../../common/Loading";

function PortableShowView({ collection, id }) {
    const {
        data: dataFields,
        isLoading: loadingFields,
        isSuccess: successFields,
    } = useFieldsDirect(collection);
    const {
        data: dataItem,
        isLoading: loadingItem,
        isSuccess: successItem,
    } = useSingleItem(collection, `/${id}?fields=*.*`, id !== null);

    log.d(dataItem, "Show; dataItem");
    log.d(dataFields, "Show; dataFields");

    if (successFields && successItem) {
        return <Default fields={dataFields} item={dataItem} />;
    }

    return <Loading text="Loading..." />;
}

export default PortableShowView;
