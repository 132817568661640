import Icon from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";
import React, { useState } from "react";
import {
    useDeleteItemMutation,
    useUpdateItemMutation,
} from "../../actions/app/items";
import { history } from "../../_helpers/history";
import { useLocation, useRouteMatch } from "react-router-dom";
import log from "cslog";
import { getFileURL } from "../../_helpers/image";

export function ActionButton({
    action,
    record,
    collection,
    conditions,
    validationFunc,
    permiFunc,
    permissions,
}) {
    const [loading, setLoading] = useState(false);
    const deleteMutation = useDeleteItemMutation();
    const updateMutation = useUpdateItemMutation();
    const layout = action.layout;

    // Check condition------------------------------------
    // let show = validationFunc(record) & permiFunc(record);
    let show = validationFunc(record);

    if (!show) {
        // return <>NA</>;
        return <>NA</>;
    }
    //----------------------------------------------------

    let btnProps = {};
    switch (layout.theme) {
        case "primary":
            btnProps = { ...btnProps, type: "primary" };
            break;
        case "danger":
            btnProps = { ...btnProps, danger: true };
            break;
        default:
            break;
    }
    switch (layout.style) {
        case "solid":
            btnProps = { ...btnProps, type: "primary" };
            break;
        case "ghost":
            btnProps = { ...btnProps, ghost: true };
            break;
        case "dashed":
            btnProps = { ...btnProps, type: "dashed" };
            break;
        default:
            break;
    }

    const onClick = (e) => {
        const actionList = action.actions;
        // log.d(actionList, "Performing Actions");
        actionList.forEach((ac) => {
            switch (ac.type) {
                case "read":
                    if (ac.subtype === "download") {
                        log.d(record, "Downloading record");
                        const url = getFileURL(record[ac.field]);
                        console.log("Download url", url);
                        // saveAs(url, file?.filename_download);
                        // window.open(`${url}?download`, "_self");
                        window.open(`${url}&download`, "_blank");
                    } else {
                        history.push(`items/${collection}/view/${record.id}`);
                    }
                    break;
                case "delete":
                    deleteMutation.mutate({
                        collection: collection,
                        id: record.id,
                    });
                    break;
                case "create":
                    const path = history.location?.pathname;
                    const app_name = path.split("/")[1];
                    history.push(`/${app_name}/data/${ac.form_id}/create`, {
                        from: history.location.pathname,
                    });
                    break;
                case "modify":
                case "update":
                    if (ac.full) {
                        history.push(
                            `data/${
                                ac.form_id || "form_id_not_mentioned"
                            }/edit/${record.id}`,
                            {
                                from: history.location.pathname,
                            }
                        );
                    } else {
                        updateMutation.mutate({
                            collection: ac.collection
                                ? ac.collection
                                : collection,
                            id: ac.record_id ? ac.record_id : record.id,
                            data: {
                                [ac.field]: ac.new_value,
                            },
                        });
                    }
                    break;
                case "open_comp":
                    history.push(`open/${ac.component}/${record.id}`, {
                        from: history.location.pathname,
                    });
                    break;
                default:
                    break;
            }
        });
    };

    switch (layout.type) {
        case "button":
            btnProps = {
                ...btnProps,
                children: layout.text,
            };
            break;
        case "onlyicon":
            btnProps = {
                ...btnProps,
                icon: (
                    <Icon component={() => <i className={layout.icon}></i>} />
                ),
            };
            break;
        case "iconbutton":
            btnProps = {
                ...btnProps,
                icon: (
                    <Icon component={() => <i className={layout.icon}></i>} />
                ),
                children: layout.text,
            };
            break;
        default:
            break;
    }

    if (layout.confirm) {
        return (
            <Popconfirm title={layout.confirm.text} onConfirm={onClick}>
                <Button
                    {...btnProps}
                    loading={
                        deleteMutation.isLoading || updateMutation.isLoading
                    }
                />
            </Popconfirm>
        );
    } else {
        return (
            <Button
                {...btnProps}
                loading={deleteMutation.isLoading || updateMutation.isLoading}
                onClick={onClick}
            />
        );
    }
}

function ActionCell({ actions, item, record, collection, permissions }) {
    // log.d(actions, "Actions in ActionCell");
    // log.d(item, "Item");
    // log.d(record, "Record");

    // const location = useLocation();
    // const appname = location.pathname.split("/")[1];
    // log.d(appname, "AppName");

    return (
        <Space>
            {actions.map((action, index) => {
                if (action.target === "row") {
                    return (
                        <ActionButton
                            key={index}
                            action={action}
                            record={record}
                            collection={collection}
                            validationFunc={action.validationFunc}
                            permiFunc={action.permiFunc}
                            permissions={permissions}
                        />
                    );
                }
            })}
        </Space>
    );
}

export default ActionCell;
