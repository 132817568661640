import { Button, Tooltip } from "antd";
import startCase from "lodash/startCase";
import React from "react";
import { useItemsFine } from "../../actions/app/items";
import { CREATOR_BASE_URL } from "../../constants";
import sample_img from "../../media/imgs/website_skelton.jpg";
import ItemOptionMenu from "./ItemOptionMenu";
import styles from "./styles/CreativeNew.module.css";
import log from "cslog";
import { getEditURL } from "../../_helpers/editor";
import { restoreUserId } from "../../_helpers/auth";
import { Link } from "react-router-dom";
import ActionBox from "../../common/ActionBox";
import QurealSection from "../../common/QurealSection";
import { ArrowRightOutlined } from "@ant-design/icons";
import { getImageURL, getPublicImageURL } from "../../_helpers/image";
import PreviewTemplate from "./PreviewTemplate";

const CREATOR_URL = CREATOR_BASE_URL;

export const SingleItem = ({ item }) => {
    return (
        <div className={styles.gridItem} key={item.id}>
            <div className={styles.gridItemImg}>
                <img
                    className={styles.giImg}
                    src={
                        item.preview
                            ? getPublicImageURL(item.preview)
                            : sample_img
                    }
                    alt="img"
                />
                <a href={getEditURL(item.id)} target="_blank" rel="noreferrer">
                    <div className={styles.giOverley}>
                        <Tooltip title="Edit">
                            <button className={styles.giBtn}>
                                <i className="fas fa-pen"></i>
                            </button>
                        </Tooltip>
                        <PreviewTemplate
                            item={item}
                            trigger={
                                <Tooltip title="View">
                                    <button className={styles.giBtn}>
                                        <i className="fas fa-eye"></i>
                                    </button>
                                </Tooltip>
                            }
                            is_template={false}
                            slug_field="slug"
                        />

                        <Tooltip title="Edit">
                            <Link to={`/creatives/edit/${item.id}`}>
                                <button className={styles.giBtn}>
                                    <i className="fas fa-cog"></i>
                                </button>
                            </Link>
                        </Tooltip>
                    </div>
                </a>
            </div>
            <div className={styles.itemBottomPanel}>
                <ItemOptionMenu item={item}>
                    <button className={styles.itemBottomBtn}>
                        <i className="fas fa-ellipsis-h"></i>
                    </button>
                </ItemOptionMenu>
                <div className={styles.itemBottomTextPanel}>
                    <Tooltip title={item.title}>
                        <h2 className={styles.gridItemTitle}>{item.title}</h2>
                    </Tooltip>
                    <p className={styles.gridItemSubtitle}>
                        {startCase(item.type)}
                    </p>
                </div>
            </div>
        </div>
    );
};

