import log from "cslog";
import { useAtom } from "jotai";
import React, { Fragment, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";
import ComponentPopup from "../components/popup/ComponentPopup";
import QComponent from "../components/QComponent";
import { MAINBAR_WIDTH, SIDEBAR_WIDTH } from "../constants";
import QMade from "../qmade";
import { navAtom } from "../states/qcon";
import CRUDScreens from "./CRUDScreens";
import ItemScreen from "./ItemScreen";
import QurealScreen from "./QurealScreen";
import QurealSidebar from "./QurealSidebar";

function QurealAppSingleScreen({ app }) {
    const isMobile = useMedia("(max-width: 700px)");
    const [collapsed, setCollapsed] = useState(true);

    const [dscreens, setDScreens] = useState({});
    const [nav, setNav] = useAtom(navAtom);
    log.d(app, "App Data");

    useEffect(() => {
        if (app.id !== nav.app) {
            setNav({
                app: app.id,
            });
        }
    }, [app]);

    const sidegap = isMobile ? 0 : MAINBAR_WIDTH;

    const marginLeft = sidegap;
    const marginTop = app.layout.navigation.type === "topbar" ? "50px" : 0;
    const width = app.layout.navigation.type === "topbar" ? "auto" : "100%";

    const default_screen_index = app.default_screen_index;
    log.d(default_screen_index, "Default screen index");
    const defaultScreen = app.screens[default_screen_index];

    log.d(defaultScreen, "Default Screen");
    return (
        <Fragment>
            {/* <UsersSB /> */}
            {app.screens?.length <= 0 && (
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#aaa",
                    }}
                >
                    <i
                        className="fas fa-paint-roller"
                        style={{
                            fontSize: "2.5rem",
                        }}
                    ></i>
                    <h2>Coming soon...</h2>
                </div>
            )}

            <div
                style={{
                    // width: "100%",
                    // minHeight: "100vh",
                    width: width,
                    marginTop: marginTop,
                    paddingLeft: isMobile ? 0 : marginLeft,
                    overflow: "auto",
                }}
            >
                <Switch>
                    {defaultScreen && (
                        <Redirect
                            exact
                            from={`/${app.slug}`}
                            to={`/${app.slug}/${defaultScreen.slug}`}
                        />
                    )}

                    {app.screens.map((item) => {
                        const stype = item.layout.type;
                        if (stype === "qmade" && item.status === "published") {
                            return (
                                <Route
                                    key={item.slug}
                                    // exact
                                    path={`/${app.slug}/${item.slug}`}
                                    component={() => (
                                        <QMade screen={item.layout.screen} />
                                    )}
                                />
                            );
                        }
                    })}

                    <Route
                        key="creative_edit"
                        path={`/creatives/edit/:id`}
                        component={() => <QMade screen="creative_edit" />}
                    />

                    {app.screens.map((item) => {
                        const stype = item.layout.type;
                        if (stype === "static" && item.status === "published") {
                            return (
                                <Route
                                    key={item.slug}
                                    // exact
                                    path={`/${app.slug}/${item.slug}`}
                                    component={() => (
                                        <QurealScreen screen_id={item.id} />
                                    )}
                                />
                            );
                        }
                    })}
                </Switch>
            </div>
        </Fragment>
    );
}

export default QurealAppSingleScreen;
