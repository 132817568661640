import { Button } from "antd";
import React from "react";
import { useInitPaymentPhonePe } from "../../_actions/mutations/payment_phonepe";
import log from "cslog";

function PayButtonPhonePe({ text = "Pay with PhonePe PG", amount = 13 }) {
    const payMutation = useInitPaymentPhonePe((response) => {
        log.d(response, "PhonePe res");
        window.open(response?.url, "_blank");
    });

    return (
        <div>
            <Button
                type="primary"
                onClick={() =>
                    payMutation.mutate({
                        name: "Shivam Agrawal",
                        amount: amount,
                        MUID: "shivampip123",
                        mobile: "8871830420",
                        returnURL: "http://localhost:3000/creatives/trash",
                    })
                }
            >
                {text}
            </Button>
        </div>
    );
}

export default PayButtonPhonePe;
