import { Layout } from "antd";
import React, { useMemo } from "react";
import { useMe } from "../../actions/auth";
import QurealSection from "../../common/QurealSection";
import QurealForm from "../../forms/QurealForm";
// import "../../qui/forms.css";
// import "../../qui/form_thems.css";

const { Content } = Layout;

function FormView({ comp }) {
    const { data: dataMe, isSuccess: successMe } = useMe();

    const record_id = useMemo(() => {
        const data = comp.data;
        if (successMe && data.type === "edit") {
            const record = data.record;
            if (typeof record === "object" && record !== null) {
                const rid_type = record.type;
                switch (rid_type) {
                    case "fixed":
                        return record.value;
                    case "me":
                        const fvalue = dataMe.data[record.field];
                        if (Array.isArray(fvalue)) {
                            return fvalue[0];
                        } else {
                            return dataMe.data[record.field];
                        }
                    default:
                        return record.value;
                }
            }
            return null;
        }
        return null;
    }, [comp, dataMe, successMe]);

    return (
        <QurealSection>
            <QurealForm
                id={comp?.data?.form_id}
                org={"cooler_inc"}
                // id={12}
                // record_id={2}
                record_id={record_id}
                wrapper_style={{
                    background: "white",
                    padding: "15px",
                    // fontSize: input.fontSize,
                    // fontWeight: input.bold ? "bold" : "normal",
                    // textTransform: input.uppercase
                    //     ? "uppercase"
                    //     : "none",
                    // ...decodeStyle(ps.fixStyle, gs),
                    // ...decodeStyle(ps.style, gs),
                    // ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    // ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
                configs={{
                    submitButtonText: "Create Now",
                    submittingButtonText: "Creating now",
                    successMessage: "Your record is successfully createddddd",
                }}
                onSuccess={
                    () => alert("Done dana done")
                    // performAction({
                    //     ...input.actionOnSuccess,
                    //     dydb: dydb,
                    // })
                }
            />
        </QurealSection>
    );
}

export default FormView;
