import React from "react";
import styles from "./styles/ListCard.module.css";
import { BASE_URL } from "../../../constants";
import { restoreAccessToken } from "../../../_helpers/auth";
import log from "cslog";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}

// function Card({ title, subtitle, imgId }) {
function ListCard({ item }) {
    const { title, subtitle, imageSource, number } = item;
    log.d(item, "Itemm");
    return (
        <div className={styles.card}>
            {imageSource && (
                <div
                    className={styles.square_img}
                    style={{
                        // backgroundImage: `url(${img})`,
                        backgroundImage: `url(${getImageURL(imageSource)})`,
                    }}
                />
            )}
            <div className={styles.content_wrapper}>
                <h3
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                ></h3>
                <div
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                ></div>
            </div>
            <div>
                <div
                    className={styles.price}
                    dangerouslySetInnerHTML={{ __html: number }}
                ></div>
            </div>
        </div>
    );
}

export default ListCard;
