import React, { useState } from "react";
import { useChangePassword } from "../actions/auth";
import "./style/input.css";
import log from "cslog";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { Alert, Button, Form, Input } from "antd";

function ChangePassword(onChange) {
    const [data, setData] = useState({});
    const [newpassword, setnewpassword] = useState("");
    const [cpassword, setcpassword] = useState("");

    const passwordMutation = useChangePassword((data, variables, context) => {
        log.d(data, "Success Data");

        onChange(newpassword);
    });

    return (
        <QurealSection header={<SectionHeader heading="Change Password" />}>
            <Form
                layout="vertical"
                requiredMark="optional"
                name="normal_login"
                style={{
                    maxWidth: "300px",
                    padding: "15px",
                }}
                initialValues={{ remember: true }}
                onFinish={(values) => {
                    if (values.password === values.cpassword) {
                        passwordMutation.mutate({
                            password: values.password,
                        });
                    }
                }}
            >
                <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: "Please enter new password",
                        },
                    ]}
                    hasFeedback
                >
                    <Input placeholder="New Password" type="password" />
                </Form.Item>
                <Form.Item
                    name="cpassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("passwords do not match!")
                                );
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input placeholder="Confirm Password" type="password" />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        // style={{ width: "100%" }}
                        loading={passwordMutation.isLoading}
                    >
                        Reset Password
                    </Button>
                </Form.Item>
                {passwordMutation.isSuccess && (
                    <Alert
                        message="Password changed successfully"
                        type="success"
                    />
                )}
            </Form>
        </QurealSection>
    );
}

export default ChangePassword;
