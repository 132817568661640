import React, { useEffect, useState } from "react";
import {
    useCreateItemMutation,
    useSingleItem,
    useUpdateItemMutation,
} from "../../actions/app/items";
import QField from "../../qforms/QField";
import log from "cslog";
import { startCase } from "lodash";
import { Button, Checkbox, Form, Input } from "antd";

function generateField(f) {
    const field = {
        id: f.id,
        name: f.name,
        label: f.label,
        field_type: f.field_type,
        value_type: f.value_type,
        required: f.required,
        readonly: f.readonly,
        nullable: f.nullable,
        unique: f.unique,
        ...f.interface,
    };
    return field;
}

function CreateForm({ table_id, form_id }) {
    const {
        isLoading: loadingTable,
        isSuccess: successTable,
        data: dataTable,
    } = useSingleItem(
        "qdb_table",
        `/${table_id}?fields=*,fields.*`,
        table_id !== undefined
    );
    const {
        isLoading: loadingForm,
        isSuccess: successForm,
        data: dataForm,
    } = useSingleItem("qdb_form", `/${form_id}`, form_id !== undefined);
    const [selectedFields, setSelectedFields] = useState([]);
    const [formData, setFormData] = useState({
        type: "standard",
        table: table_id,
    });
    const mutationCreate = useCreateItemMutation();
    const mutationUpdate = useUpdateItemMutation();

    useEffect(() => {
        if (successForm) {
            log.d(dataForm, "dataForm");
            setFormData({
                name: dataForm.name,
                title: dataForm.title,
            });
            setSelectedFields(dataForm.fields.map((item) => item.id));
        }
    }, [successForm, dataForm]);

    const generateFormFromFields = (eve) => {
        eve.preventDefault();
        log.d(formData, "Generating form");
        log.d(selectedFields, "Selected fields");

        const out_fields = [];
        selectedFields.forEach((fid) => {
            const f = dataTable.fields.find((fi) => fi.id === fid);
            log.d(f, "Next Field");
            const field = generateField(f);
            if ("fields" in field) {
                const children = [];
                field.fields.forEach((cf) => {
                    children.push(
                        generateField({
                            ...cf,
                            id: cf.name,
                        })
                    );
                });
                field["fields"] = children;
            }
            out_fields.push(field);
        });
        log.d(out_fields, "Out Fields");

        const out = {
            ...formData,
            fields: out_fields,
        };

        log.d(out, "Final Out");
        if (form_id) {
            mutationUpdate.mutate({
                collection: "qdb_form",
                id: form_id,
                data: out,
            });
        } else {
            mutationCreate.mutate({
                collection: "qdb_form",
                data: out,
            });
        }
    };

    return (
        <FormCreationForm
            fields={dataTable.fields}
            data={formData}
            setData={setFormData}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            onSubmit={generateFormFromFields}
            form_id={form_id}
        />
    );
}

export default CreateForm;

function FormCreationForm({
    fields = [],
    data,
    setData,
    selectedFields,
    setSelectedFields,
    onSubmit,
    form_id,
}) {
    log.d(selectedFields, "selectedField in FCF");
    return (
        <Form>
            <h3>{form_id ? `Update Form ${form_id}` : "Create New Form"}</h3>
            <Form.Item>
                <label>Name</label>
                <Input
                    value={data?.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                />
            </Form.Item>
            <Form.Item>
                <label>Title</label>
                <Input
                    value={data?.name}
                    onChange={(e) =>
                        setData({ ...data, title: e.target.value })
                    }
                />
            </Form.Item>
            <Form.Item>
                <label>Fields</label>
                <Checkbox.Group
                    defaultValue={selectedFields}
                    value={selectedFields}
                    onChange={(checkedValues) =>
                        setSelectedFields(checkedValues)
                    }
                    options={fields
                        .filter((f) => !f.readonly)
                        .map((f) => {
                            return {
                                label: f.label,
                                value: f.id,
                            };
                        })}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                    }}
                />
            </Form.Item>
            <Button onClick={onSubmit} type="primary">
                {form_id ? "Update Form" : "Create Form"}
            </Button>
        </Form>
    );
}
