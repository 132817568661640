import React, { useEffect, useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import { useSendPasswordResetMail } from "../actions/auth";
import { useSelectedOrg, useUpdateOrgMutation } from "../actions/org";
import log from "cslog";
import PhotoButton from "../qforms/standard_elements/PhotoButton";

export default function OrgnaizationSettings() {
    const [form] = Form.useForm();
    const { isLoading, isSuccess, data: dataOrg } = useSelectedOrg();
    const mutationUpdate = useUpdateOrgMutation();

    log.d(dataOrg, "Org Data");

    return (
        <QurealSection
            header={<SectionHeader heading="Organization Settings" />}
        >
            {isSuccess && (
                <div style={{ padding: "15px" }}>
                    <Form
                        form={form}
                        layout="vertical"
                        requiredMark="optional"
                        name="normal_login"
                        style={{
                            maxWidth: "300px",
                        }}
                        initialValues={dataOrg}
                        onFinish={(values) => {
                            mutationUpdate.mutate({
                                id: dataOrg.id,
                                data: values,
                            });
                        }}
                    >
                        <Form.Item
                            label="Organization Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter org name",
                                },
                            ]}
                            // hasFeedback
                        >
                            <Input
                                placeholder="Organization Name"

                                //  size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Organization Description"
                            name="description"
                        >
                            <Input.TextArea
                                // showCount
                                // maxLength={100}
                                placeholder="Organization Description"
                            />
                        </Form.Item>
                        <Form.Item label="Organization Logo" name="logo">
                            <PhotoButton
                                field={{
                                    collection: "qdb_organization",
                                    field: "logo",
                                }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%" }}
                                loading={mutationUpdate.isLoading}
                            >
                                Proceed
                                <ArrowRightOutlined />
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </QurealSection>
    );
}
