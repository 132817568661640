import React from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { useItemsFine } from "../actions/app/items";
import EnquiryCard from "./EnquiryCard";
import log from "cslog";
import Loading from "../common/Loading";
import { Empty } from "antd";
import LoadingBox from "../common/LoadingBox";

function EnquiryList({ status = null }) {
    const { isSuccess, isLoading, data } = useItemsFine(
        "qform_contact",
        `sort=-date_created&filter[website][user_created][_eq]=$CURRENT_USER&${
            status && `filter[status][_eq]=${status}`
        }`,
        true
    );

    log.d(data, "Enquiry Data");
    return (
        <QurealSection header={<SectionHeader heading="All Enquiries" />}>
            {isLoading && <LoadingBox />}
            {isSuccess && data.length === 0 && <Empty />}
            {isSuccess && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "15px",
                    }}
                >
                    {data?.map((item) => (
                        <EnquiryCard data={item} />
                    ))}
                </div>
            )}
        </QurealSection>
    );
}

export default EnquiryList;
