import React from "react";
import useMedia from "react-use/lib/useMedia";

function BlankBox({ text }) {
    const isMobile = useMedia("(max-width: 700px)");
    return (
        <div
            style={{
                width: "100%",
                height: "120px",
                padding: isMobile ? "12px" : "12px 16px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px dashed grey",
                }}
            >
                <h3>{text}</h3>
            </div>
        </div>
    );
}

export default BlankBox;
