import React from "react";
// import logo from "../media/imgs/Qureal_Logo_Only.png";
import { LOGO_ONLY } from "../constants";

const AppSplash = ({ msg }) => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                background:
                    "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(235,244,245,1) 38%, rgba(197,214,215,1) 100%)",
            }}
        >
            <img
                src={LOGO_ONLY}
                alt="logo"
                style={{
                    height: "60px",
                    objectFit: "contain",
                }}
            />
            <div
                style={{
                    fontSize: "1.2rem",
                }}
            >
                {msg || "Loading..."}
            </div>
        </div>
    );
};

export default AppSplash;
