import { Button, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { getTime } from "../../_helpers/app/dt";
import { getImageFileURL, getPublicImageURL } from "../../_helpers/image";
import { PREVIEW_CREATION_URL, PREVIEW_TEMPLATE_URL } from "../../constants";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import styles from "./styles/Preview.module.css";

function PreviewTemplate({ item, trigger, slug_field = "id", is_template = false,
    enable_nav = false,
    enable_prev = false,
    enable_next = false,
    onPrevClick = () => { },
    onNextClick = () => { },
    open,
    setOpen
}) {
    const [iopen, setIOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [org, setOrg] = useState(null);
    const [person, setPerson] = useState(null);

    useEffect(() => {
        if (iopen) {
            setLoading(true);
        } else {
            setLoading(false)
        }
    }, [iopen]);

    const url = `${is_template ? PREVIEW_TEMPLATE_URL : PREVIEW_CREATION_URL}${item[slug_field]}`
    const editor_url = `https://editor.qureal.com/${item?.id}/edit`
    let customized = false

    let iframe_url = `${url}?`;
    if (org) {
        iframe_url += `qi_organization=${org}`
        customized = true;
    }
    if (person) {
        iframe_url += `&qi_person=${person}`
        customized = true;
    }


    return (
        <Fragment>
            {trigger && <span onClick={(e) => {
                e.preventDefault();
                if (item?.type === "singlepage") {
                    setIOpen(true)
                } else {
                    window.open(url, '_blank');
                }
            }}>{trigger}</span>}
            <Modal
                // title="Template Preview"
                open={trigger ? iopen : open}
                centered
                onOk={() => {
                    setIOpen(false);
                    if (!trigger) {
                        setOpen(false);
                    }
                }}
                onCancel={() => {
                    setIOpen(false)
                    if (!trigger) {
                        setOpen(false)
                    }
                }}
                width={1000}
                footer={null}
                style={{
                    padding: "0px",
                }}
            >
                <div className={styles.wrapper}>
                    <div className={styles.left_box}>
                        {customized ? <iframe
                            title={item.title}
                            src={`${iframe_url}&preview`}
                            width={500}
                            className={styles.web_box}
                            onLoad={() => setLoading(false)}
                        // loading="lazy"
                        /> : <img
                            className={styles.web_box}
                            src={getImageFileURL(item.preview?.filename_disk)}
                            alt="preview"
                        />}
                    </div>
                    <div>
                        {/* <p>{getImageFileURL(item?.preview?.filename_disk)}</p> */}
                        {/* <p>iFrame URL: {iframe_url}</p> */}
                        {loading && <p>Loading...</p>}
                        <h3 className={styles.title}>{item.title}</h3>
                        <p className={styles.creation_type}>{item.creation_type?.name}</p>
                        {!is_template && <p className={styles.last_updated}>Last updated {getTime(item.date_updated, "rt")} ago</p>}
                        <div className={styles.action_wrapper}>
                            {!is_template && <a href={editor_url} target="_blank" rel="noreferrer">
                                <Button>Edit</Button>
                            </a>}
                            <a href={url} target="_blank" rel="noreferrer">
                                <Button>Full page view</Button>
                            </a>
                            <Button>Share</Button>
                        </div>

                        <div className={styles.select_wrapper}>
                            <RelationFieldStanard
                                field={{
                                    foreign_key_table: "qdb_organization",
                                    template: "{{name}}",
                                    foreign_key_column: "id"
                                }}
                                value={org}
                                onChange={new_org => setOrg(new_org)}
                                style={{
                                    width: 200,
                                    marginTop: "15px"
                                }}
                            />

                            <RelationFieldStanard
                                field={{
                                    foreign_key_table: "qdb_person",
                                    template: "{{full_name}}",
                                    foreign_key_column: "id"
                                }}
                                value={person}
                                onChange={new_person => setPerson(new_person)}
                                style={{
                                    width: 200,
                                    marginTop: "15px"
                                }}
                            />
                        </div>

                        {enable_nav && <div className={styles.page_control_wrapper}>
                            <Button disabled={!enable_prev} onClick={onPrevClick}>Previous</Button>
                            <Button disabled={!enable_next} onClick={onNextClick}>Next</Button>
                        </div>}

                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}

export default PreviewTemplate;
