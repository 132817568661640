import React, { useEffect, useState } from "react";
import { useItemsFine } from "../../actions/app/items";
import Handlebars from "handlebars";
import Dropdown from "./Dropdown";
import MultiDropown from "./MultiDropdown";
import log from "cslog";

function RelationField({ field, value, onChange, many = false }) {
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(
        field.foreign_key_table,
        // `limit=1000&sort=-${field.foreign_key_column}`,
        "",
        field.foreign_key_table !== undefined
    );

    const [options, setOptions] = useState([]);

    log.d(field, `Relation Field: ${many}`);
    log.d(dataItems, "Relation Field Data");
    log.d(options, "Relation Options");

    useEffect(() => {
        if (dataItems) {
            const option_template = Handlebars.compile(field.template);

            const newOptions = [];
            if (field.type === "relationM2M") {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template({
                            [field.junction_field]: item,
                        }),
                        value: item[field.foreign_key_column],
                    });
                });
            } else {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template(item),
                        value: item[field.foreign_key_column],
                    });
                });
            }
            setOptions(newOptions);
        }
    }, [dataItems]);

    if (many) {
        return (
            <MultiDropown
                options={options}
                value={value || []}
                onChange={onChange}
            />
        );
    }
    return <Dropdown options={options} value={value} onChange={onChange} />;
}

export default RelationField;
