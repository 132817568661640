import log from "cslog";
import Handlebars from "handlebars";
import { repeat } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Link } from "react-router-dom";
import { history } from "../../../_helpers/history";
// import { getFieldRender } from "../../../_parsers/fields";
import { getFieldRender } from "../../../_parsers/app/fields";
import Card from "./Card";
import ListCard from "./ListCard";
import ProfileCard from "./ProfileCard";
import styles from "./styles/GridView.module.css";
import { getValidationFunc } from "../../../_helpers/app/conditions";
import ActionCell from "../../commons/ActionCell";

function GridView({ comp, fields, items, permissions }) {
    const [citems, setCitems] = useState([]);

    const card_type = comp.data.card_type ? comp.data.card_type : "card";

    useEffect(() => {
        const card_options = comp.data[card_type];
        log.d(card_options, "Card Options");

        const used_fields = comp.data.fields;
        const renderer = {};
        used_fields.forEach((ff) => {
            let field = fields.find((fi) => fi.field === ff);
            if (
                !field.schema.foreign_key_column &&
                field.meta.display !== "image"
            ) {
                // if (field.meta.display !== "image") {
                renderer[ff] = getFieldRender(field);
            }
        });
        log.d(renderer, "Renderer");

        const templates = {};
        Object.keys(card_options).forEach((key) => {
            templates[key] = Handlebars.compile(card_options[key]);
        });

        const out = [];
        const copied_items = cloneDeep(items);
        copied_items.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (key in renderer) {
                    const renderFunc = renderer[key];
                    item[key] = ReactDOMServer.renderToStaticMarkup(
                        renderFunc(item[key], item)
                    );
                }
            });
            const oout = {};
            Object.entries(templates).forEach(([key, template]) => {
                oout[key] = template(item);
            });
            oout["id"] = item.id;
            out.push(oout);
        });
        setCitems(out);
    }, [items, fields, comp]);

    log.d(citems, "CItems");

    //Grid layout==============
    const grid = comp.layout.grid;
    let gridProps = {
        gridTemplateColumns: "repeat(auto-fill, minmax(25rem, 1fr))",
        gridTemplateRows: "repeat(auto-fill, minmax(10rem, 1fr))",
    };
    if (grid) {
        const item_per_row = grid.item_per_row;
        if (item_per_row !== "auto") {
            gridProps = {
                gridTemplateColumns: `repeat(${item_per_row},1fr)`,
            };
        }
    }

    //=========================
    let actions = comp.actions.filter((item) => item.status === "published");
    log.d(actions, "Actions in cardsview");
    const processedActions = [];
    if (actions.length > 0) {
        for (const action of actions) {
            processedActions.push({
                ...action,
                validationFunc: getValidationFunc(action.conditions),
            });
        }
        log.d(processedActions, "Processed Actionss");
    }
    //=============================================
    const default_action = comp.data.default_action;
    const onDefaultAction = default_action
        ? (record) => {
              if (default_action) {
                  if (default_action.type === "update") {
                      history.push(
                          `data/${
                              default_action.form_id || "form_id_not_mentioned"
                          }/edit/${record.id}`,
                          {
                              from: history.location.pathname,
                          }
                      );
                  }
              }
          }
        : null;
    //=============================================

    return (
        <div
            className={styles.cgrid}
            style={{
                ...gridProps,
            }}
        >
            {citems &&
                citems.map((item, index) => {
                    return (
                        <>
                            {card_type === "card" && (
                                <ProfileCard
                                    item={item}
                                    actions={
                                        <ActionCell
                                            actions={processedActions}
                                            // item={item}
                                            record={item}
                                            collection={comp.data.collection}
                                            permissions={permissions}
                                        />
                                    }
                                    onDefaultAction={() =>
                                        onDefaultAction(item)
                                    }
                                />
                            )}
                            {card_type === "listcard" && (
                                <ListCard item={item} />
                            )}
                        </>
                    );
                })}
        </div>
    );
}

export default GridView;
