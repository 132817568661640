import { Button, Form, Space } from "antd";
import log from "cslog";
import React, { useState } from "react";
import { getFormField } from "../../_parsers/form_fields";
import { useCreateItem } from "../../actions/mutations/items";
import { cleanFormData } from "../../_helpers/form";
import Alert from "../../common/Alert";
import startCase from "lodash/startCase";
import { history } from "../../_helpers/history";
import useMedia from "react-use/lib/useMedia";

const ItemForm = ({
    currentComp,
    fields,
    fullWidth = false,
    onDone = "back",
}) => {
    const isMobile = useMedia("(max-width: 700px)");
    const [form] = Form.useForm();
    const createMutation = useCreateItem(currentComp.data.collection);
    const [doneAction, setDoneAction] = useState(onDone);

    // const layout = "horizontal";
    // const layout = "vertical";
    const layout = currentComp.layout.style
        ? currentComp.layout.style
        : "vertical";
    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: fullWidth ? 16 : 14 },
    };
    const tailLayout = {
        wrapperCol: {
            offset: layout === "vertical" || isMobile ? 0 : 8,
            span: 14,
        },
    };

    const submitForm = (values) => {
        log.d(values, "Form Values");
        const cleanedValues = cleanFormData(values);
        log.d(cleanedValues, "Cleandeed Valued");
        createMutation.mutate(cleanedValues);
        // createItem(values);
    };

    const handleSubmit = (reset = true) => {
        form.validateFields()
            .then((values) => {
                log.d(values, "FINLA VALUES");
                submitForm(values);
            })
            .catch((errorInfo) => {})
            .then(() => {
                if (reset) {
                    form.resetFields();
                }
            });
    };

    log.d(createMutation.error?.response?.data, "Mutation Errors");
    log.d(createMutation.data, "Mutation Data");

    if (doneAction === "back" && createMutation.isSuccess) {
        log.p("Going back now");
        history.goBack();
    }

    log.d(fields, `Fields ${currentComp.data.collection}`);

    return (
        <Form
            form={form}
            layout={layout}
            {...formItemLayout}
            // onFinish={submitForm}
            style={{
                paddingTop: "30px",
            }}
            size={currentComp.layout.size ? currentComp.layout.size : "default"}
            requiredMark="optional"
        >
            {fields.map((item) => {
                return getFormField(item);
            })}
            <Form.Item {...tailLayout}>
                <Space>
                    <Button
                        type="primary"
                        // htmlType="submit"
                        onClick={() => {
                            if (onDone === "back") {
                                setDoneAction("back");
                            }
                            handleSubmit();
                        }}
                        loading={createMutation.isLoading}
                    >
                        Save
                    </Button>
                    <Button
                        htmlType="submit"
                        onClick={(e) => {
                            setDoneAction("stay");
                            handleSubmit(false);
                        }}
                        loading={createMutation.isLoading}
                    >
                        Save and Copy
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Reset
                    </Button>
                </Space>
                {createMutation.isSuccess && (
                    <Alert
                        type="success"
                        message={`Record created successfully`}
                    />
                )}
                {createMutation.isError && (
                    <Alert
                        type="error"
                        message={`Record couldn't be created`}
                    />
                )}
            </Form.Item>
        </Form>
    );
};

export default ItemForm;
