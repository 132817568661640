import { useMutation, useQuery } from "react-query";
import { secureAxios } from "../_helpers/auth";
import log from "cslog";

export const useEventCalender = (enabled = true) => {
    const { isSuccess, isLoading, data } = useQuery(
        "qdb_event",
        () => secureAxios.get(`/items/qdb_event?filter[status][_eq]=published`).then((res) => res.data),
        { enabled: enabled }
    );

    const out = {};
    if (isSuccess) {
        data.forEach((item) => {
            const dt = item.date ? new Date(item.date) : null;
            if (dt) {
                const month = dt.getMonth();
                const out_month = out[month] || [];
                out[month] = [...out_month, { ...item, dt: dt }];
            }
        });
    }

    return { isSuccess, isLoading, data: out };
};
