import React, { Fragment as div, useEffect, useState } from "react";
import styles from "./styles/Dropdown.module.css";
import classNames from "classnames";

function Dropdown({
    options,
    value,
    onChange,
    optionRender = null,
    headerRender = null,
}) {
    const [open, setOpen] = useState(false);
    const [labelMapping, setLabelMapping] = useState({});

    useEffect(() => {
        const newLabelMapping = {};
        options.forEach((op) => {
            newLabelMapping[op.value] = op.label;
        });
        setLabelMapping(newLabelMapping);
    }, [options]);

    useEffect(() => {
        const close = () => setOpen(false);
        if (open) {
            window.addEventListener("click", close);
        } else {
            window.removeEventListener("click", close);
        }
    }, [open]);

    return (
        <div
            className={classNames(styles.wrapper)}
            onClick={(eve) => {
                eve.stopPropagation();
                setOpen(!open);
            }}
        >
            <div className={classNames("qinput", styles.header)}>
                {value ? labelMapping[value] : "Please select"}
                <i className="fas fa-angle-down" />
            </div>
            {open && (
                <div className={styles.optionsWrapper}>
                    {options.map((op) => (
                        <div
                            key={op.value}
                            className={styles.option}
                            onClick={() => onChange(op.value)}
                        >
                            {optionRender ? optionRender(op) : op.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Dropdown;
