import React from "react";
import QurealRichTextEditor from "./QurealRichTextEditor";
import log from "cslog";

function QurealRTEField({ value, onChange }) {
    return (
        <div style={{ border: "1px solid #ddd", borderRadius: "3px" }}>
            <QurealRichTextEditor
                // value={value}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default QurealRTEField;
