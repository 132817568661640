import { i } from "cslog";
import { startCase } from "lodash";
import React, { useState } from "react";
import {
    useCreateItemMutation,
    useDeleteItemMutation,
    useUpdateItemMutation,
} from "../../../actions/app/items";
import Dropdown from "../../../qforms/elements/Dropdown";
import RelationField from "../../../qforms/elements/RelationField";
import log from "cslog";
import { Button, Form, Input, Space, Tabs } from "antd";
import SubscriptionDetails from "./SubscriptionDetails";
import CollectedData from "./CollectedData";

function CreationSettings({ data, plans }) {
    return (
        <div style={{ paddingTop: "10px" }}>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        key: "overview",
                        label: "Overview",
                        children: (
                            <div>
                                <SubscriptionDetails plans={plans} />
                            </div>
                        ),
                    },
                    {
                        key: "analytics",
                        label: "Analytics",
                        children: "Content of Tab Pane 2",
                    },
                    {
                        key: "collaborators",
                        label: "Collaborators",
                        children: <CollaboratorsSetting data={data} />,
                    },
                    {
                        key: "settings",
                        label: "Settings",
                        children: (
                            <div>
                                <RenameCreation data={data} />
                                {/* <ChnageSlug data={data} /> */}
                                {/* <TransferOwnership /> */}
                            </div>
                        ),
                    },

                    {
                        key: "data",
                        label: "Collected Data",
                        children: (
                            <div>
                                <CollectedData creation={data} />
                            </div>
                        ),
                    },
                ]}
                onChange={() => { }}
            />
        </div>
    );
}

export default CreationSettings;

function TransferOwnership() {

    return <Form layout="vertical">
        <Form.Item label="New Name" name="name">
            <Space.Compact style={{ width: "60%" }}>
                <RelationField
                    field={{
                        foreign_key_table: "directus_users",
                        foreign_key_column: "id",
                        template: "{{first_name}} {{last_name}}",
                    }}
                    many={false}
                    value={null}
                    onChange={(newVal) => { }}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                // loading={updateMutation.isLoading}
                // onClick={rename}
                >
                    Submit
                </Button>
            </Space.Compact>
        </Form.Item>
    </Form>

}

function RenameCreation({ data }) {
    const [name, setName] = useState(data?.title);
    const updateMutation = useUpdateItemMutation();

    const rename = (e) => {
        e.preventDefault();
        if (name !== "") {
            updateMutation.mutate({
                collection: "qp_creation",
                id: data?.id,
                data: {
                    title: name,
                },
            });
        }
    };

    log.d(data, "Data in RenameCreation");
    return (
        <Form layout="vertical">
            <Form.Item label="New Name" name="name">
                <Space.Compact style={{ width: "60%" }}>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={updateMutation.isLoading}
                        onClick={rename}
                    >
                        Submit
                    </Button>
                </Space.Compact>
            </Form.Item>
        </Form>
    );
}

function ChnageSlug({ data }) {
    const [slug, setSlug] = useState(data?.slug);
    const updateMutation = useUpdateItemMutation();

    const rename = (e) => {
        e.preventDefault();
        if (slug !== "") {
            updateMutation.mutate({
                collection: "qp_creation",
                id: data?.id,
                data: {
                    slug: slug,
                },
            });
        }
    };

    return <Form layout="vertical">
        <Form.Item label="New Name" name="name">
            <Space.Compact style={{ width: "60%" }}>
                <Input
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={updateMutation.isLoading}
                    onClick={rename}
                >
                    Submit
                </Button>
            </Space.Compact>
        </Form.Item>
    </Form>
}

function CollaboratorsSetting({ data }) {
    const [new_colab, setNewColab] = useState({
        directus_users_id: null,
        qp_creation_id: data.id,
        access: "full",
    });
    const createMutation = useCreateItemMutation();
    const deleteMutation = useDeleteItemMutation();

    log.d(new_colab, "New colab now");

    const addColab = () => {
        if (new_colab.directus_users_id) {
            //now add
            createMutation.mutate({
                collection: "qp_creation_directus_users",
                data: new_colab,
                extraInvalidate: ["items", "qp_creation"],
            });
        }
    };

    const deleteColab = (row_id) => {
        deleteMutation.mutate({
            collection: "qp_creation_directus_users",
            id: row_id,
            extraInvalidate: ["items", "qp_creation"],
        });
    };
    return (
        <div>
            <form
                className="qui-form"
                style={{
                    gap: "10px",
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    addColab();
                }}
            >
                <div className="qfield" style={{ width: "300px" }}>
                    <label>Add New Collaborator</label>
                    <RelationField
                        field={{
                            foreign_key_table: "directus_users",
                            foreign_key_column: "id",
                            template: "{{first_name}} {{last_name}}",
                        }}
                        many={false}
                        value={new_colab.directus_users_id}
                        onChange={(newVal) => {
                            setNewColab({
                                ...new_colab,
                                directus_users_id: newVal,
                            });
                        }}
                    />
                </div>
                <div
                    className="qfield"
                    style={{ width: "auto", minWidth: "150px" }}
                >
                    <label>Access</label>
                    <Dropdown
                        options={["full", "data_only", "design_only"].map(
                            (ac) => ({
                                value: ac,
                                label: startCase(ac),
                            })
                        )}
                        value={new_colab.access}
                        onChange={(newVal) =>
                            setNewColab({
                                ...new_colab,
                                access: newVal,
                            })
                        }
                        optionRender={(op) => (
                            <div style={{ color: "blue" }}>{op.label}</div>
                        )}
                    />
                </div>
                <div className="qfield" style={{ width: "auto" }}>
                    <label>.</label>
                    <button type="submit">Add</button>
                </div>
            </form>
            <form
                className="qui-form"
                style={{ width: "400px", marginTop: "10px" }}
            >
                <div className="qlist">
                    {data.collaborators?.length > 0 ? (
                        data.collaborators.map((colab) => (
                            <div className="qitem">
                                <span>
                                    {colab.directus_users_id.first_name}{" "}
                                    {colab.directus_users_id.last_name}
                                </span>
                                <span>{startCase(colab.access)}</span>
                                <div className="btn_group">
                                    {/* <button className="btn">Edit</button> */}
                                    <button
                                        className="btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteColab(colab.id);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No collaborator added</p>
                    )}
                </div>
            </form>
        </div>
    );
}
