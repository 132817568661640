import React, { useState } from "react";
import { useFields } from "../../actions/app/fields";
import { useCollections } from "../../actions/collections";
import log from "cslog";
import { RenderField } from "../../editor/fields";
import startCase from "lodash/startCase";
import { Button } from "antd";
import {
    useCreateItemMutation,
    useUpdateItemMutation,
    useItemsFine,
} from "../../actions/app/items";
import QUIDemo from "../../qui/QUIDemo";
import { useAllRelations, useRelation } from "../../actions/app/relations";
import QurealSection from "../../common/QurealSection";
import SectionHeader from "../../components/commons/SectionHeader";

function extractRelations(f, relations) {
    let out = {};
    if (f.type === "alias") {
        if (f.schema) {
            out["foreign_key_table"] = f.schema?.foreign_key_table;
            out["foreign_key_column"] = f.schema?.foreign_key_column;
        } else {
            log.d(f, "Extracting relation with schema null");
            const rel = relations.find(
                (item) =>
                    item.meta?.one_collection === f.collection &&
                    item.meta?.one_field === f.field
            );
            log.d(rel, `Found Rel: ${f.field}`);
            if (rel) {
                if (f.meta?.interface === "list-m2m") {
                    //Many 2 Many
                    const one_collection = rel.meta?.one_collection;
                    out["foreign_key_table"] =
                        rel.meta?.many_collection?.substring(
                            one_collection.length + 1
                        ); // remove qureal_app prefix from this string
                    out["foreign_key_column"] = rel.meta?.many_field?.substring(
                        one_collection.length + 1
                    ); // remove qureal_app prefix from this string
                    out["junction_field"] = rel.meta?.junction_field;
                } else if (f.meta?.interface === "list-o2m") {
                    out["foreign_key_table"] = rel.meta?.many_collection;
                    out["foreign_key_column"] = rel.meta?.many_field;
                    out["many_table_pk"] = "id";
                } else {
                    out["foreign_key_table"] = rel.meta?.many_collection;
                    out["foreign_key_column"] = rel.meta?.many_field;
                }
            }
        }
    } else {
        if (f.meta?.interface === "select-dropdown-m2o") {
            if (f.schema) {
                out["foreign_key_table"] = f.schema?.foreign_key_table;
                out["foreign_key_column"] = f.schema?.foreign_key_column;
            }
        }
    }
    return out;
}

function cleanObj(obj) {
    const out = {};
    Object.entries(obj).forEach((row) => {
        if (row[1] !== null && row[1] !== undefined) {
            out[row[0]] = row[1];
        }
    });
    return out;
}

function encodeField(f, relations) {
    log.d(f, "Encoding field");
    const ftype = f.type;
    const finterface = f.meta?.interface;
    const fdtype = f.schema?.type;
    const fdisplay = f.meta?.display;

    let out = {
        name: f.field,
        label: startCase(f.field),
        field_type: "standard", //standard, custom
        value_type: ftype, // value type (what api returns)
        required: f.meta.required,
        readonly: f.meta.readonly,
        unique: f.schema?.is_unique,
        nullable: f.schema?.is_nullable,
        note: f.meta?.note,
        default_value: {
            type: "fixed",
            value: f.schema?.default_value,
        },
    };

    out = {
        ...out,
        group: null, //Form/Display section
        step: null, //multi step form
    };

    const relation_info = extractRelations(f, relations);

    // Interface
    const qi = {
        hidden: f.meta.hidden,
        ...(f.meta?.options || {}),
        options: f.meta.options?.choices,
        max_length: f.schema?.max_length,
        ...relation_info,
    };

    if ("choices" in qi) {
        delete qi["choices"];
    }

    // Schema
    const qs = {
        datatype: f.schema?.data_type,
        auto_increment: f.schema?.has_auto_increment,
        is_generated: f.schema?.is_generated,
        max_length: f.schema?.max_length,
        numeric_precision: f.schema?.numeric_precision,
        numeric_scale: f.schema?.numeric_scale,
        generation_expression: f.schema?.generation_expression,
        is_primary_key: f.schema?.is_primary_key,
        comment: f.schema?.comment,
        ...relation_info,
    };

    // Display
    let qd = {
        display_type: f.meta?.display || "raw",
        hidden: false,
        ...(f.meta?.display_options || {}),
        options: f.meta?.display_options?.choices,
    };

    if ("choices" in qd) {
        delete qd["choices"];
    }

    switch (finterface) {
        case "input":
            switch (ftype) {
                case "string":
                case "text":
                    qi["type"] = "text";
                    break;
                case "integer":
                case "decimal":
                case "float":
                case "bigInteger":
                    qi["type"] = "number";
                    break;
                default:
                    qi["type"] = "text";
            }
            break;
        case "input-multiline":
            qi["type"] = "textarea";
            break;
        case "input-rich-text-html":
            qi["type"] = "richtext";
            break;
        case "input-rich-text-md":
            qi["type"] = "markdown";
            break;
        case "tags":
            qi["type"] = "tags";
            break;
        case "select-dropdown":
            qi["type"] = "dropdown";
            break;
        case "select-multiple-dropdown":
            // qi["type"] = "dropdownMulti";
            qi["type"] = "dropdown-multiple";
            break;
        case "select-dropdown-m2o":
            qi["type"] = "relationM2O";
            break;
        case "list-o2m":
            qi["type"] = "relationO2M";
            break;
        case "list-m2m":
            qi["type"] = "relationM2M";
            break;
        case "boolean":
            qi["type"] = "boolean";
            const options = f.meta?.options;
            qi["iconOn"] = options?.iconOn;
            qi["iconOff"] = options?.iconOff;
            qi["colorOn"] = options?.colorOn;
            qi["colorOff"] = options?.colorOff;
            break;
        case "datetime":
            qi["type"] = "datetime";
            break;
        case "select-radio":
            qi["type"] = "radio";
            break;
        case "select-icon":
            qi["type"] = "icon";
            break;
        case "slider":
            qi["type"] = "slider";
            break;
        case "input-code":
            qi["type"] = "code";
            break;
        case "select-color":
            qi["type"] = "color";
            break;
        case "select-multiple-checkbox":
            qi["type"] = "checkboxMulti";
            break;
        case "select-multiple-checkbox-tree":
            qi["type"] = "checkboxTree";
            break;
        case "list":
            qi["type"] = "repeater";
            qi["item_display_template"] = "fill value in template";
            break;
        case "file":
            if (fdisplay === "image") {
                qi["type"] = "image";
            } else {
                qi["type"] = "file";
            }
            break;
        case "files":
            qi["type"] = "files";
            break;
        case "file-image":
            qi["type"] = "image";
            break;
        case "group-raw":
            qi["type"] = "virtual-group";
            break;
        case "group-detail":
            qi["type"] = "detail-group";
            break;
        case "presentation-notice":
            qi["type"] = "notice";
            break;
        default:
            qi["type"] = finterface;
            break;
    }

    if ("fields" in qi) {
        const children = [];
        qi.fields.forEach((cf) => {
            children.push(encodeField(cf, relations));
        });
        qi["fields"] = children;
        qi["item_display_template"] = qi["template"];
        delete qi["template"];
        qi["add_btn_label"] = qi["addLabel"];
        delete qi["addLabel"];
    }

    out = {
        ...out,
        schema: cleanObj(qs), //all database related info
        interface: cleanObj(qi), // all info to render field in form
        display: cleanObj(qd), //all info to display in view
    };
    return out;
}

function CreateTable() {
    const {
        isLoading: loadingCollections,
        isSuccess: successCollections,
        data: dataCollections,
    } = useCollections();
    const [selectedCollection, setSelectedCollection] = useState(null);
    const {
        isLoading: loadingFields,
        isSuccess: successFields,
        data: dataFields,
    } = useFields(selectedCollection);
    const {
        isLoading: loadingRelations,
        isSuccess: successRelations,
        data: dataRelations,
    } = useAllRelations(true);
    const mutationCreate = useCreateItemMutation();
    const mutationUpdate = useUpdateItemMutation();
    const {
        isLoading: loadingTables,
        isSuccess: successTables,
        data: dataTables,
    } = useItemsFine("qdb_table", "", true);

    log.p("======================================");
    log.d(selectedCollection, "Selected Collection");
    log.d(dataCollections, "Collections");
    log.d(dataFields, "Fields");
    log.d(dataRelations, "Relations");
    log.d(dataTables, "Tables");

    const generateTable = () => {
        log.h3(`Generating table for ${selectedCollection}`);
        const already_exists =
            dataTables.findIndex((tab) => tab.id === selectedCollection) !== -1;

        const cd = dataCollections.find(
            (col) => col.collection === selectedCollection
        );
        log.d(cd, "Selected Collection");

        const out = {
            id: cd.collection,
            type: "standard",
            meta: {},
            display_template: "Hello",
        };

        const outFields = [];
        dataFields.forEach((f) => {
            // outFields.push(mapField(f, dataRelations));
            outFields.push(encodeField(f, dataRelations));
        });

        out["fields"] = outFields;

        log.d(out, "OUT");

        if (already_exists) {
            mutationUpdate.mutate({
                collection: "qdb_table",
                id: selectedCollection,
                data: out,
            });
        } else {
            mutationCreate.mutate({
                collection: "qdb_table",
                data: out,
            });
        }
    };

    log.d(mutationCreate, "Create Mutation");

    return (
        <QurealSection
            header={<SectionHeader heading="Create Standard Table" />}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "15px",
                    // alignItems: "flex-start",
                }}
            >
                <RenderField
                    field={{
                        id: "collection",
                        type: "select",
                        label: "Select Collection",
                        params: {
                            options: successCollections
                                ? dataCollections.map((c) => {
                                      return [
                                          c.collection,
                                          startCase(c.collection),
                                      ];
                                  })
                                : [],
                        },
                    }}
                    values={{
                        collection: selectedCollection,
                    }}
                    setValues={(newVals) =>
                        setSelectedCollection(newVals.collection)
                    }
                />
                <Button
                    type="primary"
                    onClick={generateTable}
                    loading={
                        loadingFields.isLoading ||
                        loadingRelations ||
                        mutationCreate.isLoading ||
                        mutationUpdate.isLoading
                    }
                    disabled={loadingFields || loadingRelations}
                    style={{
                        alignSelf: "flex-start",
                    }}
                >
                    {successTables &&
                    dataTables.findIndex(
                        (tab) => tab.id === selectedCollection
                    ) !== -1
                        ? "Regenerate Table"
                        : "Generate Table"}
                </Button>
            </div>
            {/* <QUIDemo /> */}
        </QurealSection>
    );
}

export default CreateTable;
