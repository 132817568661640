import log from "cslog";
import startCase from "lodash/startCase";

// import { fillValuesInTemplate } from "../../_helpers/fields";
import { fillValuesInTemplate } from "../../_helpers/app/fields";
import { Rate } from "antd";
import { getTime } from "../../_helpers/app/dt";
import { BASE_URL } from "../../constants";
import { restoreAccessToken } from "../../_helpers/auth";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}

export const getFieldRender = (field) => {
    let renderer = undefined;
    if (!field) {
        return renderer;
    }
    const dtype = field.schema?.data_type;
    const display = field.meta?.display;

    switch (display) {
        case "related-values":
            const template = field.meta.display_options?.template;
            // renderer = (item) => fillValuesInTemplate(template, item);

            renderer = (item) => (
                <div
                    style={{
                        borderLeft: "5px solid #eee",
                        paddingLeft: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: fillValuesInTemplate(template, item),
                    }}
                />
            );

            break;
        case "user":
            const template2 = field.meta.options?.template;
            renderer = (item) =>
                fillValuesInTemplate(template2, item).replace(
                    "{{avatar.$thumbnail}}",
                    ""
                );
            break;
        case "image":
            renderer = (item) => {
                const imgId = typeof item === "object" ? item?.id : item;
                return imgId ? (
                    <img
                        src={getImageURL(imgId)}
                        alt="img"
                        style={{
                            width: "60px",
                            borderRadius: "5px",
                        }}
                    />
                ) : (
                    "-"
                );
            };
            break;
        case "datetime":
            // renderer = (item) => dayjs.tz.guess();
            renderer = (item) => (item ? getTime(item) : "-");
            break;
        case "boolean":
            const disop = field.meta.display_options;
            renderer = (item, record) => {
                if (item) {
                    return (
                        <span
                            className="q-table-label"
                            style={{
                                backgroundColor: disop?.colorOn
                                    ? disop?.colorOn
                                    : "greenyellow",
                            }}
                        >
                            {disop?.labelOn ? disop.labelOn : "YES"}
                        </span>
                    );
                }
                return (
                    <span
                        className="q-table-label"
                        style={{
                            backgroundColor: disop?.colorOff
                                ? disop.colorOff
                                : "red",
                        }}
                    >
                        {disop?.labelOff ? disop.labelOff : "NO"}
                    </span>
                );
            };
            break;
        case "labels":
            const disop2 = field.meta.display_options;
            const choices = disop2?.choices
                ? disop2?.choices
                : field.meta.options?.choices;
            const isDot = disop2?.showAsDot;

            const field_interface = field.meta ? field.meta.interface : null;

            renderer = (item) => {
                if (field_interface === "tags") {
                    return (
                        <span
                            style={{
                                border: "1px solid grey",
                                padding: "3px",
                                borderRadius: "3px",
                            }}
                        >
                            {item}
                        </span>
                    );
                }
                const choice = choices?.find((ch) => ch.value === item);
                const style = {
                    backgroundColor: choice?.background
                        ? choice?.background
                        : disop2?.defaultBackground
                        ? disop2?.defaultBackground
                        : "#eee",
                    // color: disop2?.defaultForeground
                    //     ? disop2?.defaultForeground
                    //     : "black",
                    color: choice?.foreground
                        ? choice?.foreground
                        : disop2?.defaultForeground
                        ? disop2?.defaultForeground
                        : "black",
                    // fontWeight: "bold",
                };

                if (isDot) {
                    return (
                        <div className="q-table-label-dot" style={style}></div>
                    );
                }
                return (
                    <span className="q-table-label" style={style}>
                        {choice?.text ? choice?.text : "Some Value"}
                    </span>
                );
            };
            break;
        case "rating":
            const op3 = field.meta?.options;
            const maxVal = op3?.maxValue ? op3?.maxValue : 5;

            renderer = (item) => (
                <Rate defaultValue={item} count={maxVal} disabled />
            );
            break;
        case "formatted-value":
            // renderer = (item) => (
            //     <span dangerouslySetInnerHTML={{ __html: item }} />
            // );
            const dops = field.meta?.display_options;
            const style = {};
            if ("bold" in dops) {
                style["fontWeight"] = "bold";
            }
            if ("color" in dops) {
                style["color"] = dops["color"];
            }
            if ("background" in dops) {
                style["background"] = dops["background"];
            }
            log.d(style, "Formatted-value Style");

            renderer = (item) => <span style={style}>{item}</span>;
            break;
        case "raw":
            // renderer = (item) => <b>{item}</b>;
            renderer = (item) => item;
            break;
        default:
            // renderer = (item) => <i>{item}</i>;
            renderer = (item) => item;
    }
    return renderer;
};

export const getField = (field) => {
    if (!field) {
        return {};
    }
    const dtype = field.schema?.data_type;
    let renderer = getFieldRender(field);

    let sorter = undefined;
    let ellipsis = false;
    switch (dtype) {
        case "float":
        case "integer":
            sorter = (a, b) => a[field.field] - b[field.field];
            break;
        case "varchar":
            sorter = (a, b) => a[field.field] > b[field.field];
            // ellipsis = true;
            break;
        case "text":
            // ellipsis = true;
            break;
        default:
            break;
    }

    return {
        title: startCase(field.field),
        dataIndex: field.field,
        key: field.field,
        render: renderer,
        sorter: sorter,
        ellipsis: ellipsis,
        // render
    };
};

export const getFields = (fields) => {
    return fields.map((item) => getField(item));
};
