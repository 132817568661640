import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSendPasswordResetMail } from "../actions/auth";
import CenterCard from "../_layouts/CenterCard";
import CenterCard2Part from "../_layouts/CenterCard2Part";
import useMedia from "react-use/lib/useMedia";

const ForgotPassword = () => {
    const isMobile = useMedia("(max-width: 700px)");
    const mutation = useSendPasswordResetMail();
    const [email, setEmail] = useState("");

    return (
        <CenterCard2Part title="Forgot Password">
            {mutation.isSuccess && email ? (
                <div>
                    <p style={{ textAlign: "center" }}>
                        Email has been sent to {email} with password reset link.
                    </p>
                    <Link to="/login">
                        <div
                            style={{
                                textAlign: "center",
                                paddingTop: "15px",
                                paddingBottom: "-20px",
                            }}
                        >
                            Back to Login
                        </div>
                    </Link>
                </div>
            ) : (
                <Form
                    name="normal_login"
                    style={{
                        maxWidth: isMobile ? "400px" : "300px",
                        width: isMobile ? "100%" : "auto",
                    }}
                    initialValues={{ remember: true }}
                    onFinish={(values) => {
                        setEmail(values.email);
                        mutation.mutate({
                            email: values.email,
                            reset_url: "http://localhost:3000/verify_password",
                        });
                    }}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Email!",
                            },
                            {
                                type: "email",
                                message: "Invalid email",
                            },
                        ]}
                        hasFeedback
                    >
                        <Input
                            prefix={
                                <UserOutlined className="site-form-item-icon" />
                            }
                            placeholder="Email"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                            size="large"
                            loading={mutation.isLoading}
                        >
                            Reset Password
                        </Button>
                        <Link to="/login">
                            <div
                                style={{
                                    textAlign: "center",
                                    paddingTop: "15px",
                                    paddingBottom: "-20px",
                                }}
                            >
                                Back to Login
                            </div>
                        </Link>
                    </Form.Item>
                </Form>
            )}
        </CenterCard2Part>
    );
};

export default ForgotPassword;
