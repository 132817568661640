import React from "react";
import { secureAxios } from "../../_helpers/auth";
import { useQuery } from "react-query";

export const downloadData = (collection, queryString, format) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    } else if (collection === "roles") {
        query = "roles";
    }

    secureAxios
        .get(`/${query}${queryString}?export=${format}`, {
            responseType: "blob",
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${collection}.${format}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
};

// export const useDownloadData = (collection, queryString, format) => {
//     let query = `items/${collection}`;
//     if (collection === "directus_users") {
//         query = "users";
//     } else if (collection === "roles") {
//         query = "roles";
//     }

//     return useQuery(
//         ["item", collection, queryString],
//         () =>
//             secureAxios
//                 .get(`/${query}${queryString}?export=${format}`)
//                 .then((res) => res.data),
//         {
//             enabled: true,
//         }
//     );
// };
