import React from "react";
import QField from "../QField";
import log from "cslog";

// function JSONField({ field, formData, setFormData }) {
function JSONField({ field, value, setValue, formData }) {
    log.d(field, "JSON Field");
    log.d(value, "Value in JSON Field");

    return (
        <div className="qfield" key={field.id}>
            <label>{field.label}</label>
            <div
                style={{
                    borderLeft: "3px solid #ccc",
                    paddingLeft: "15px",
                }}
            >
                {field.fields.map((f) => {
                    return (
                        <div key={f.name}>
                            <QField
                                key={f.name}
                                field={f}
                                formData={formData}
                                // value={value[f.name]}
                                value={
                                    f.type === "json"
                                        ? typeof value === "object"
                                            ? f.name in value
                                                ? value[f.name]
                                                : {}
                                            : {}
                                        : typeof value === "object"
                                        ? f.name in value
                                            ? value[f.name]
                                            : ""
                                        : ""
                                }
                                setValue={(newVal) =>
                                    setValue({
                                        ...(typeof value === "object"
                                            ? value
                                            : {}),
                                        [f.name]: newVal,
                                    })
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default JSONField;
