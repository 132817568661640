import { BASE_URL } from "../constants";
import { restoreAccessToken } from "./auth";
import AvatarPhoto from "../media/imgs/avatar2.jpeg";

export function getImageURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
    } else {
        return AvatarPhoto;
    }
}

export function getPublicImageURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}`;
    } else {
        return AvatarPhoto;
    }
}

export function getImageFileURL(filename) {
    return `${BASE_URL}passets/${filename}`
}

export function getFileURL(id) {
    return `${BASE_URL}assets/${id}?access_token=${restoreAccessToken()}`;
}
