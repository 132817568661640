import React from "react";
import { useRefreshAuth } from "../actions/auth";
import log from "cslog";
import { setAuthToken } from "../_helpers/auth";
import { Redirect } from "react-router-dom";
import AppSplash from "../common/AppSplash";

function VerifyAuth() {
    const auth = useRefreshAuth();

    log.d(auth, "Response in VA");

    if (!auth) {
        return <AppSplash msg="Verifying user" />;
    }

    if (auth?.status) {
        setAuthToken(auth.token);
        return <Redirect to="/" />;
    } else {
        return (
            <Redirect to="/login?nocheck=true&provider=default&error=User has logged in with email. please try login with credentials" />
        );
    }
}

export default VerifyAuth;
