import React, { useEffect, useState } from "react";
import styles from "./styles/Dropdown.module.css";
import classNames from "classnames";
import log from "cslog";

function MultiDropown({
    options,
    value = [],
    onChange,
    optionRender = null,
    headerRender = null,
}) {
    const [open, setOpen] = useState(false);
    const [labelMapping, setLabelMapping] = useState({});

    useEffect(() => {
        const newLabelMapping = {};
        options.map((op) => {
            newLabelMapping[op.value] = op.label;
        });
        setLabelMapping(newLabelMapping);
    }, [options]);

    useEffect(() => {
        const close = () => setOpen(false);
        if (open) {
            window.addEventListener("click", close);
        } else {
            window.removeEventListener("click", close);
        }
    }, [open]);

    return (
        <div
            className={classNames(styles.wrapper)}
            onClick={(eve) => {
                eve.stopPropagation();
                setOpen(!open);
            }}
        >
            {value?.length > 0 ? (
                <div className={classNames("qinput", styles.headerMulti)}>
                    <div className={styles.chipsWrapper}>
                        {value.map((val) => (
                            <div className={styles.chip} key={val}>
                                {labelMapping[val]}
                                <i
                                    className="fas fa-times"
                                    onClick={(eve) => {
                                        eve.stopPropagation();
                                        const newSelected = value.filter(
                                            (sval) => sval !== val
                                        );
                                        onChange(newSelected);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <i className="fas fa-angle-down" />
                </div>
            ) : (
                <div className={classNames("qinput", styles.header)}>
                    Please select
                    <i className="fas fa-angle-down" />
                </div>
            )}

            {open && (
                <div className={styles.optionsWrapper}>
                    {options.map((op) => {
                        if (value?.includes(op.value)) {
                            return null;
                        }
                        return (
                            <div
                                key={op.value}
                                className={classNames({
                                    [styles.option]: true,
                                    [styles.selected]: value?.includes(
                                        op.value
                                    ),
                                })}
                                onClick={(eve) => {
                                    eve.stopPropagation();
                                    log.d(op, "Option clicked");
                                    if (value?.includes(op.value)) {
                                        const newSelected = value.filter(
                                            (val) => val !== op.value
                                        );
                                        onChange(newSelected);
                                    } else {
                                        const newSelected = [...value];
                                        newSelected.push(op.value);
                                        onChange(newSelected);
                                    }
                                }}
                            >
                                {optionRender ? optionRender(op) : op.label}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default MultiDropown;
