import { Button, Col, Form, Input, Modal, Row, Upload } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import PhotoButton from "../../../qforms/standard_elements/PhotoButton";
import TextArea from "antd/es/input/TextArea";
import log from "cslog";
import {
    useCreateItem,
    useUpdateItem,
} from "../../../_actions/mutations/items";

function AddBrandPersonal({ show, setShow, initialValues = {} }) {
    const [form] = Form.useForm();
    const mutationCreate = useCreateItem("qdb_person", () => setShow(false));
    const mutationUpdate = useUpdateItem("qdb_person", initialValues?.id, () =>
        setShow(false)
    );

    const closeModal = () => {
        // form.resetFields();
        setShow(false);
    };

    const is_update = !!initialValues?.id;

    const addPerson = (values) => {
        log.d(values, "Person values");
        if (!!initialValues?.id) {
            log.p("Updating person");
            mutationUpdate.mutate({
                ...values,
                full_name: `${values.first_name} ${values.last_name}`,
            });
        } else {
            log.p("Creating person");
            mutationCreate.mutate({
                ...values,
                full_name: `${values.first_name} ${values.last_name}`,
            });
        }
    };

    log.d(initialValues, "Initial Values");

    return (
        <Fragment>
            <Modal
                title={`${is_update ? "Update" : "Add new"} pesonal brand`}
                open={show}
                onOk={() => {}}
                confirmLoading={false}
                onCancel={closeModal}
                footer={[
                    <Button key="cancel" onClick={closeModal}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={
                            mutationCreate.isLoading || mutationUpdate.isLoading
                        }
                    >
                        {is_update ? "Update" : "Create"}
                    </Button>,
                ]}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={addPerson}
                    initialValues={initialValues}
                >
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="First Name" name="first_name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Last Name" name="last_name">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Photo" name="photo">
                        <PhotoButton
                            field={{
                                collection: "qdb_person",
                                field: "photo",
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Bio" name="bio">
                        <TextArea rows={3} />
                    </Form.Item>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Email" name="email">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Mobile" name="mobile">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Website" name="website">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default AddBrandPersonal;
