export const CREATION_TYPES = [
    {
        id: "website",
        name: "Website",
        img: "/creation_faces/qdf_min_trans.png",
    },
    {
        id: "singlepage",
        name: "Pamphlet",
        img: "/creation_faces/card_min.jpg",
    },
    {
        id: "slider",
        name: "Slider",
        img: "/creation_faces/slider_min_trans.png",
    },
    {
        id: "magazine",
        name: "Magazine",
        img: "/creation_faces/magazine_min.jpg",
    },
    {
        id: "qdf",
        name: "QDF",
        img: "/creation_faces/qdf_min_trans.png",
    },
    {
        id: "card",
        name: "Card",
        img: "/creation_faces/card_min.jpg",
    },
    {
        id: "packaging_box",
        name: "Box Packaging",
        img: "/creation_faces/card_min.jpg",
    },
];

export const CREATION_TYPES_old = [
    {
        id: "slider",
        name: "Slider",
    },
    {
        id: "cube",
        name: "Cube",
    },
    {
        id: "card",
        name: "Card",
    },
    {
        id: "presentation_16_9",
        name: "Presentation (16:9)",
    },
    {
        id: "mobile_presentation",
        name: "Mobile Presentation",
    },
    {
        id: "newsletter",
        name: "Newsletter",
    },
    {
        id: "poster",
        name: "Poster",
    },
    {
        id: "mobile_poster",
        name: "Mobile Poster",
    },
    {
        id: "business_card",
        name: "Business Card",
    },
    {
        id: "slider",
        name: "Slider",
    },
];

export const WELCOME_MSG = [
    "Create something amazing today",
    "Welcome to Qureal Creation Studio",
    "Paint your thoughts on digital canvas",
    "Let your imagination run wild",
];
