import { Popover, Radio, Dropdown, Menu, Button, Space } from "antd";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { currentModuleAtom } from "../../states/app/module";
import {
    TableOutlined,
    PlusOutlined,
    UserOutlined,
    ArrowDownOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import { Fragment } from "react";
import log from "cslog";
import toUpper from "lodash/toUpper";
import { downloadData } from "../../actions/app/export";
import { secureAxios } from "../../_helpers/auth";

const Export = ({ currentComp, trigger }) => {
    const [downloadFields, setDownloadFields] = useState("selected");
    // const {}= useDownloadData()

    let exportEnabled = false;
    Object.values(currentComp.layout.toolbar.export).forEach((value) => {
        if (value) {
            exportEnabled = true;
        }
    });

    if (!exportEnabled) {
        return <></>;
    }

    const download = (format) => {
        log.d(format, "Downloading");
        downloadData(currentComp.data.collection, "", format);
    };

    return (
        <Popover
            title="Downlaod Data"
            trigger="click"
            placement="bottomRight"
            content={
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Radio.Group
                        value={downloadFields}
                        onChange={(e) => setDownloadFields(e.target.value)}
                    >
                        <Space direction="vertical">
                            <Radio value="selected">Selected Fields</Radio>
                            <Radio value="all">All Fields</Radio>
                        </Space>
                    </Radio.Group>
                    <br></br>

                    {exportEnabled && (
                        <Space direction="vertical">
                            {Object.keys(currentComp.layout.toolbar.export).map(
                                (item) => {
                                    if (
                                        currentComp.layout.toolbar.export[item]
                                    ) {
                                        return (
                                            <Button
                                                key={item}
                                                type="primary"
                                                shape="round"
                                                icon={<DownloadOutlined />}
                                                block
                                                onClick={() => download(item)}
                                            >
                                                {toUpper(item)}
                                            </Button>
                                        );
                                    }
                                }
                            )}
                        </Space>
                    )}
                </div>
            }
        >
            {trigger}
        </Popover>
    );
};

export default Export;
