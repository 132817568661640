import { Form, Input, Modal } from "antd";
import log from "cslog";
import { useAtom } from "jotai";
import { useForm } from "rc-field-form";
import React, { useState, Fragment, useEffect } from "react";
import { useCreateItemMutation } from "../../../../actions/app/items";
import { currentComponentAtom } from "../../../../states/app/component";
import UIButton from "../../UIButton";
import Alert from "../../../../common/Alert";

export default function ButtonCreateOther({ action, comp_id, fields, items }) {
    const [currentComp, setCurrentComp] = useAtom(
        currentComponentAtom(comp_id)
    );
    const createMutation = useCreateItemMutation();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const meta = action.meta;
    const mapping = meta.mapping;
    const popup = meta.popup;

    const create = (formValues) => {
        const out = {};
        for (let [key, value] of Object.entries(mapping)) {
            switch (value.source) {
                case "fixed":
                    out[key] = value.value;
                    break;
                case "record":
                    const single_item = items[0];
                    const single_value = single_item[value.field];
                    if (
                        typeof single_value === "object" &&
                        !Array.isArray(single_value) &&
                        single_value !== null
                    ) {
                        out[key] = single_value.id;
                    } else {
                        out[key] = single_value;
                    }
                    break;
                case "popup":
                    out[key] = formValues[value.form_name];
                    break;
                default:
                    break;
            }
        }

        log.d(out, "POPUP OUT");

        createMutation.mutate({
            collection: meta.collection,
            data: out,
        });
    };

    log.d(createMutation, "createMutation");

    useEffect(() => {
        if (createMutation.isSuccess && open) {
            setOpen(false);
            createMutation.reset();
        }
    }, [createMutation]);

    return (
        <Fragment>
            <UIButton
                layout={action.layout}
                onClick={() => {
                    popup ? setOpen(true) : create();
                }}
                loading={createMutation.isLoading}
            />
            {popup && (
                <Modal
                    title={popup.title}
                    visible={open}
                    onOk={() => {
                        form.validateFields().then((values) => {
                            create(values);
                        });
                    }}
                    okText={popup.submit}
                    confirmLoading={createMutation.isLoading}
                    onCancel={() => {
                        setOpen(false);
                    }}
                >
                    <Form form={form}>
                        {popup.form.map((ff) => {
                            return (
                                <Form.Item
                                    name={ff.name}
                                    label={ff.label}
                                    rules={[{ required: ff.required }]}
                                >
                                    <Input />
                                </Form.Item>
                            );
                        })}
                    </Form>
                    {createMutation.isError && (
                        <Alert
                            type="error"
                            message={`Record couldn't be created`}
                            bold={false}
                        />
                    )}
                </Modal>
            )}
        </Fragment>
    );
}
