import log from "cslog";
import React, { useState } from "react";
import { useItemsFine } from "../../actions/app/items";
import { ActionButton } from "../../components/commons/ActionCell";
import { history } from "../../_helpers/history";
import CreateForm from "./CreateForm";
import { Button } from "antd";

function FormList({ table_id }) {
    const { isLoading, isSuccess, data } = useItemsFine(
        "qdb_form",
        `filter[table][_eq]=${table_id}&fields=id,name,title,type`,
        table_id !== undefined
    );
    const [selected_form, setSelectedForm] = useState(undefined);

    log.d(data, "FormList Data");
    return (
        <div>
            {isSuccess && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                    }}
                >
                    {data.map((form) => {
                        return (
                            <div
                                style={{
                                    padding: "8px 16px",
                                    border: "1px solid #ccc",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                {form.id} : {form.name}
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            const path =
                                                history.location?.pathname;
                                            const app_name = path.split("/")[1];
                                            history.push(
                                                `/${app_name}/data/${form.id}/create`,
                                                {
                                                    from: history.location
                                                        .pathname,
                                                }
                                            );
                                        }}
                                    >
                                        Open
                                    </Button>
                                    <Button
                                        onClick={() => setSelectedForm(form.id)}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <CreateForm table_id={table_id} form_id={selected_form} />
        </div>
    );
}

export default FormList;
