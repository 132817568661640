import React, { useState } from "react";
// import { useDeleteFile, useUploadFile } from "../../actions/files";
import log from "cslog";
// import { getImageURL } from "../../_helper/image";
import { useDeleteFile, useUploadFile, getImageURL } from "../junction";
import "../../qui/form_image.css";

const ImageField = ({ field, value, onChange, many = false }) => {
    const fileMutation = useUploadFile((data, variables, context) => {
        log.d(data, "Success Data");
        if (many) {
            let new_val = Array.isArray(value) ? value : [];
            new_val.push({
                directus_files_id: data.id,
            });
            onChange(new_val);
        } else {
            onChange(data.id);
        }
    });

    log.d(value, "Value in ImageField");

    const fileMutationdelete = useDeleteFile((data, variables, context) => {
        log.d(data, "Success Data");
    });

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        console.log(file);
        if (file) {
            if (typeof file === "object" && file !== null) {
                const formData = new FormData();
                //formData.append("title", "Some title")
                formData.append("file", file);

                // if (iconid !== "") {
                if (!many && value) {
                    console.log(value);
                    fileMutationdelete.mutate(value);
                }
                fileMutation.mutate(formData);
            }
        }
    };

    let button_text =
        fileMutation.status === "loading"
            ? "Uploading Image..."
            : many
            ? "Upload Image"
            : value
            ? "Change Image"
            : "Upload Image";

    return (
        <>
            {!many && !value && (
                <div className="img_add_block" onClick={handleClick}>
                    {fileMutation.isLoading ? (
                        <i className="fas fa-coffee" loading />
                    ) : (
                        <i className="fas fa-plus"></i>
                    )}
                    <span>
                        {fileMutation.isLoading
                            ? "Uploading photo..."
                            : "Select Photo"}
                    </span>
                </div>
            )}
            {/* <button
                onClick={handleClick}
                status={fileMutation.status}
                disabled={fileMutation.isLoading}
            >
                {button_text}
            </button> */}
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={onFile}
                style={{ display: "none" }}
            />
            {/* {!many && value && (
                <img
                    src={getImageURL(value)}
                    className="preview-img"
                    alt="preview"
                />
            )} */}
            {!many && value && (
                <div className="preview_wrapper">
                    <img
                        src={getImageURL(value)}
                        className="preview_img"
                        alt="preview"
                    />
                    <div className="preview_overlay">
                        <button size="tiny" onClick={handleClick}>
                            Edit
                        </button>
                        <button
                            style={{ backgroundColor: "red" }}
                            size="tiny"
                            onClick={() => onChange(null)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}

            {many && <h4>Many Images</h4>}
            {/* {many && Array.isArray(value) && ( */}
            {many && (
                <div className="img-group">
                    {Array.isArray(value) &&
                        value.map((item, index) => (
                            <div className="preview_wrapper" key={index}>
                                <img
                                    src={getImageURL(item?.directus_files_id)}
                                    className="preview_img"
                                    alt="preview"
                                />
                                <div className="preview_overlay">
                                    <button
                                        style={{ backgroundColor: "red" }}
                                        size="tiny"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const new_val = [...value];
                                            new_val.splice(index, 1);
                                            onChange(new_val);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    <div className="img_add_block" onClick={handleClick}>
                        {fileMutation.isLoading ? (
                            <i className="fas fa-coffee" loading />
                        ) : (
                            <i className="fas fa-plus"></i>
                        )}
                        <span>
                            {fileMutation.isLoading
                                ? "Uploading photo..."
                                : "Add Photo"}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};
export default ImageField;
