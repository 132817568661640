import React, { useState } from "react";
import {
    useChangePassword,
    useCurrentUser,
    useMe,
    useUpdateMe,
} from "../actions/auth";
import "./style/input.css";
import log from "cslog";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { Alert, Button, Col, Form, Input, Row, Space } from "antd";
import Loading from "../common/Loading";

function UpdateProfile(onChange) {
    const [form] = Form.useForm();
    const { data: dataCU, isSuccess: successCU } = useCurrentUser();
    const updateMutation = useUpdateMe();

    log.d(dataCU, "Data CurretnUser");

    if (!successCU) {
        return <Loading />;
    }

    return (
        <QurealSection header={<SectionHeader heading="Update Profile" />}>
            <Form
                form={form}
                layout="vertical"
                requiredMark="optional"
                name="normal_login"
                style={{
                    maxWidth: "500px",
                    padding: "15px",
                }}
                initialValues={dataCU}
                onFinish={(values) => {
                    log.d(values, "Values");
                    updateMutation.mutate(values);
                }}
            >
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter first name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="last_name"
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter last name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: "Please enter email",
                        },
                    ]}
                    type="email"
                >
                    <Input disabled={true} />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        // style={{ width: "100%" }}
                        loading={updateMutation.isLoading}
                    >
                        Update
                    </Button>
                </Form.Item>
                {updateMutation.isSuccess && (
                    <Alert
                        message="Profile updated successfully"
                        type="success"
                    />
                )}
            </Form>
        </QurealSection>
    );
}

export default UpdateProfile;
