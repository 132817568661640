import styles from "./styles/SectionView.module.css";
import React from "react";
import startCase from "lodash/startCase";
import log from "cslog";
import classNames from "classnames";

const SectionView = ({
    item,
    rendered_item = null,
    title = null,
    label_align = "left",
    header_color = "hotpink",
}) => {
    return (
        <div className={styles.section_wrapper}>
            {title && (
                <h3
                    className={styles.section_title}
                    style={{
                        color: header_color,
                    }}
                >
                    {title}
                </h3>
            )}
            <table className={styles.table}>
                {Object.keys(item).map((key) => {
                    const value = item[key];
                    return (
                        <tr key={key} className={styles.tr}>
                            <td
                                className={classNames(styles.td, styles.label)}
                                style={{
                                    textAlign: label_align,
                                    // paddingRight:
                                    //     label_align === "right"
                                    //         ? "40px"
                                    //         : "10px",
                                }}
                            >
                                {startCase(key)}
                            </td>
                            <td className={styles.td}>
                                {Array.isArray(value) ? (
                                    value.map((vi) => (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: vi(rendered_item),
                                            }}
                                            style={{
                                                paddingBottom: "10px",
                                            }}
                                        />
                                    ))
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item[key](rendered_item),
                                        }}
                                    />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </table>
        </div>
    );
};

export default SectionView;
