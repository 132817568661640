import { Col, Row } from "antd";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import { getItemRenderer, renderItem } from "../../../_parsers/app/item";
import styles from "./styles/TwoColumnView.module.css";
import log from "cslog";
import SectionView from "../views/SectionView";
import { getImageURL } from "../../../_helpers/image";
import ReactDOMServer from "react-dom/server";

Handlebars.registerHelper("full", function (astring) {
    return ReactDOMServer.renderToStaticMarkup(<h2>{astring}</h2>);
});

// Creates Handlebars templates
// const parse_section = (section, rendered_item, item) => {
//     const sfields = {};
//     section.fields.forEach((fi) => {
//         const value = fi.value;
//         if (typeof value === "object") {
//             const field = value["field"];
//             if (Array.isArray(item[field])) {
//                 sfields[fi.label] = rendered_item[field].map((it) =>
//                     Handlebars.compile(it)
//                 );
//             }
//         } else {
//             sfields[fi.label] = Handlebars.compile(fi.value);
//         }
//     });
//     return {
//         ...section,
//         fields: sfields,
//     };
// };

// Creates Handlebars templates
const parse_section = (section, rendered_item, item) => {
    const sfields = {};
    Object.keys(section.records).forEach((key) => {
        const value = section.records[key];
        if (typeof value === "object") {
            const field = value["field"];
            if (Array.isArray(item[field])) {
                sfields[key] = rendered_item[field].map((it) =>
                    Handlebars.compile(it)
                );
            }
        } else {
            sfields[key] = Handlebars.compile(value);
        }
    });
    return {
        ...section,
        fields: sfields,
    };
};

const parse_sections = (sections, rendered_item, item) => {
    const out = [];
    sections.forEach((section) => {
        out.push(parse_section(section, rendered_item, item));
    });
    return out;
};

function OneColumnView({ comp, fields, item }) {
    const [data, setData] = useState(null);
    const layout = comp.layout;

    log.d(item, "TwoColumn item");
    const item_renderer = getItemRenderer(comp.data.fields, fields);
    log.d(item_renderer, "TwoColumn item_renderer");
    const rendered_item = renderItem(item_renderer, item, true);
    log.d(rendered_item, "TwoColumn rendered_item");

    useEffect(() => {
        const out = {
            logo: Handlebars.compile(layout.header_img),
            small_heading: Handlebars.compile(layout.small_heading),
            title: Handlebars.compile(layout.title),
            subtitle: Handlebars.compile(layout.subtitle),
            left_sections: parse_sections(
                layout.left_sections,
                rendered_item,
                item
            ),
        };

        setData(out);
    }, [comp]);

    if (!data) {
        return <h2>Loading...</h2>;
    }

    log.d(data, "TwoColumn; data");

    return (
        <div className={styles.wrapper}>
            <div className={styles.header_block}>
                <img
                    className={styles.header_img}
                    // src={getImageURL(item.logo.id)}
                    src={getImageURL(data.logo(rendered_item))}
                    alt="logo"
                />
                <div>
                    {/* <div className={styles.small_head}>
                        {data.small_heading(rendered_item)}
                    </div> */}
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: data.title(rendered_item),
                        }}
                    />
                    <h3
                        className={styles.subtitle}
                        dangerouslySetInnerHTML={{
                            __html: data.subtitle(rendered_item),
                        }}
                    />
                </div>
            </div>
            <div className={styles.twocol}>
                <div>
                    {data.left_sections.map((section) => {
                        return (
                            <SectionView
                                title={section.title}
                                item={section.fields}
                                rendered_item={rendered_item}
                                label_align="left"
                                header_color={section.title_color}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default OneColumnView;
