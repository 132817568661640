import { Col, Row } from "antd";
import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import { getItemRenderer, renderItem } from "../../../_parsers/app/item";
import styles from "./styles/Profile.module.css";
import log from "cslog";
import SectionView from "../views/SectionView";

function Profile({ comp, fields, item }) {
    const [data, setData] = useState(null);
    const layout = comp.layout;

    const item_renderer = getItemRenderer(comp.data.fields, fields);
    log.d(item_renderer, "Profile; item_renderer");
    const rendered_item = renderItem(item_renderer, item);
    log.d(rendered_item, "Profile; rendered items");

    useEffect(() => {
        const out = {
            title: Handlebars.compile(layout.title),
            subtitle: Handlebars.compile(layout.subtitle),
        };

        const sections = [];
        layout.sections.forEach((section) => {
            const sfields = {};
            section.fields.forEach((fi) => {
                const value = fi.value;
                if (typeof value === "object") {
                    const field = value["field"];
                    if (Array.isArray(item[field])) {
                        sfields[fi.label] = rendered_item[field].map((it) =>
                            Handlebars.compile(it)
                        );
                    }
                } else {
                    sfields[fi.label] = Handlebars.compile(fi.value);
                }
                // sfields[fi.label] = Handlebars.compile(fi.value);
            });
            sections.push({
                ...section,
                fields: sfields,
            });
        });
        out.sections = sections;
        setData(out);
    }, [comp]);

    if (!data) {
        return <h2>Loading...</h2>;
    }

    log.d(data, "Profile; data");

    return (
        <div className={styles.wrapper}>
            {/* <div className={styles.left_wrapper}>
                 <img
                    className={styles.profile_pic}
                    // src="https://i.pinimg.com/originals/ee/0a/94/ee0a94bfe59c1a18593fee908be4df99.png"
                    src={getImageURL(item.avatar)}
                /> 
                 <h3>Some Information</h3> 
            </div> */}
            <div className={styles.right_wrapper}>
                <h1
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                        __html: data.title(rendered_item),
                    }}
                />
                <h3
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{
                        __html: data.subtitle(rendered_item),
                    }}
                />

                <div>
                    {data.sections.map((section) => {
                        return (
                            <SectionView
                                title={section.title}
                                item={section.fields}
                                rendered_item={rendered_item}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Profile;
