import styles from "./styles/PricingPlans.module.css";
import React from "react";
import { Button } from "antd";
import PricingPlanCard from "./PricingPlanCard";

const PricingPlans = () => {
    return (
        <div className={styles.screen}>
            <div className={styles.wrapper}>
                <div className={styles.header_wrapper}>
                    <h1 className={styles.heading}>Choose your plan</h1>
                    <div>
                        <Button type="primary">Individual</Button>
                        <Button>Family</Button>
                        <Button>Organization</Button>
                    </div>
                    <div>
                        <Button type="primary">Monthly</Button>
                        <Button> Yearly</Button>
                    </div>
                </div>
                <div className={styles.cards_wrapper}>
                    <PricingPlanCard />
                    <PricingPlanCard />
                    <PricingPlanCard />
                </div>
            </div>
        </div>
    );
};

export default PricingPlans;
