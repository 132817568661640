import classNames from "classnames";
import React, { useMemo } from "react";
import { getValidationFunc } from "./junction";
import ArrayField from "./elements/ArrayField";
import Dropdown from "./elements/Dropdown";
import JSONField from "./elements/JSONField";
import LookupField from "./elements/LookupField";
import MultiDropown from "./elements/MultiDropdown";
import RelationField from "./elements/RelationField";
// import "../styles/forms.css";
import produce from "immer";
import log from "cslog";
import ImageField from "./elements/ImageField";
import DateTime from "./elements/DateTime";
import {
    Checkbox,
    ColorPicker,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Select,
    Upload,
} from "antd";
import dayjs from "dayjs";
// import QurealRTEField from "../qrte/QurealRTEField";
import QurealRTEField from "../qureal_rte/QurealRTEField";

const { Option } = Select;
const { TextArea } = Input;

function QFieldStandard({
    field,
    value,
    setValue,
    formData,
    setFormData,
    org,
}) {
    let f = { ...field };

    // log.d(value, field.name);

    const condFuncs = useMemo(() => {
        if ("conditions" in field) {
            const condFuncs = [];
            field.conditions.forEach((cond) => {
                const condFunc = getValidationFunc(cond.rules);
                condFuncs.push({
                    func: condFunc,
                    action: cond.action,
                });
            });
            return condFuncs;
        }
        return [];
    }, [field]);

    condFuncs.forEach((cf) => {
        if (cf.func(formData)) {
            f = {
                ...f,
                ...cf.action,
            };
        }
    });

    if (f.hidden) {
        return null;
    }

    const style = {
        // width: `${((f.width || 12) / 12) * 100}%`,
        width: `calc(${((f.width || 12) / 12) * 100}% - 10px)`,
    };

    let element = undefined;
    switch (f.type) {
        case "text":
            element = <Input disabled={f.readonly} />;
            break;
        case "number":
            element = (
                <InputNumber
                    placeholder="Enter Value"
                    disabled={f.readonly}
                    style={{ width: "200px" }}
                />
            );
            break;
        case "textarea":
        case "richtext":
        case "markdown":
            // element = <TextArea rows={4} />;
            element = <QurealRTEField />;
            break;
        case "tags":
            return <div>Tags</div>;
        case "dropdown":
            log.d(value, `Dropdown ${f.name} value`);
            element = (
                <Select>
                    {f.options.map((item) => {
                        return (
                            <Option value={item.value} key={item.value}>
                                {item.text}
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "dropdown-multiple":
            element = (
                <Select mode="multiple" allowClear>
                    {f.options.map((item) => {
                        return (
                            <Option value={item.value} key={item.value}>
                                {item.text} Nice
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "relationM2O":
            element = (
                <RelationField
                    field={f}
                    many={false}
                    // value={value}
                    // onChange={setValue}
                    org={org}
                />
            );
            break;
        case "relation-o2m":
        case "relationO2M":
            element = (
                <RelationField
                    field={f}
                    many={true}
                    // value={value}
                    // onChange={setValue}
                    org={org}
                />
            );
            break;
        case "relationM2M":
            element = (
                <RelationField
                    field={f}
                    many={true}
                    // value={value}
                    // onChange={(newVal) => {
                    //     log.d(newVal, "New val of m2m");
                    //     setValue(newVal);
                    // }}
                    org={org}
                />
            );
            break;
        case "image":
            element = (
                // <ImageField field={f} value={value} onChange={setValue} />
                <ImageField field={f} />
            );
            break;
        case "files":
            element = <ImageField field={f} many={true} />;
            break;
        // case "file":
        //     return (
        //         <div className="qfield" key={f.id} style={style}>
        //             <label>{f.label}</label>
        //             <ImageField field={f} value={value} onChange={setValue} />
        //         </div>
        //     );

        case "checkbox":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={f.id}
                            checked={value}
                            onClick={() => setValue(!value)}
                            // onChange={(e) => setValue(e.target.checked)}
                        />
                        {f.label}
                    </label>
                </div>
            );
        case "checkbox-multiple":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    {f.options.map((op) => {
                        return (
                            <label className="checkbox" key={op.value}>
                                <input
                                    type="checkbox"
                                    name={op.value}
                                    checked={value.includes(op.value)}
                                    onClick={() => {
                                        if (value.includes(op.value)) {
                                            setValue(
                                                produce(value, (df) => {
                                                    const ci = df.indexOf(
                                                        op.value
                                                    );
                                                    df.splice(ci, 1);
                                                })
                                            );
                                        } else {
                                            setValue(
                                                produce(value, (df) => {
                                                    df.push(op.value);
                                                })
                                            );
                                        }
                                    }}
                                    onChange={() => {}}
                                />
                                {op.text}
                            </label>
                        );
                    })}
                </div>
            );
        case "checkbox-tree":
            return <div>Checkbox Tree</div>;
        case "datetime":
            // return (
            //     <div className="qfield" key={f.id} style={style}>
            //         <label>{f.label}</label>
            //         <DateTime field={f} value={value} onChange={setValue} />
            //     </div>
            // );
            // element = <DatePicker />;
            element = <Input type="date" />;
            break;
        case "radio":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <div className="qh">
                        {f.options.map((option) => {
                            return (
                                <div className="qradio" key={option.value}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name={f.id}
                                        value={option.value}
                                        checked={value === option.value}
                                        onClick={() => setValue(option.value)}
                                        onChange={() => {}}
                                    />
                                    <label htmlFor={option.value}>
                                        {option.text}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        case "code":
            element = <TextArea />;
            break;
        case "icon-picker":
            return <div>Icon Picker</div>;
        case "slider":
            return <div>Slider</div>;
        // case "repeater":
        //     return (
        //         <div className="qfield" key={f.id} style={style}>
        //             <ArrayField field={f} value={value} onChange={setValue} />
        //         </div>
        //     );
        case "lookup":
            return (
                <div className="qfield" key={f.id}>
                    <label>{f.label}</label>
                    <LookupField
                        field={f}
                        many={false}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "lookup-many":
            return (
                <div className="qfield" key={f.id}>
                    <label>{f.label}</label>
                    <LookupField
                        field={f}
                        many={true}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "json":
            return (
                <JSONField
                    field={f}
                    formData={formData}
                    // setFormData={setFormData}
                    value={value}
                    setValue={setValue}
                />
            );
        case "submit":
            return (
                <div className="qfield" key={f.id}>
                    <></>
                    <button type="submit">{f.label || "Submit"}</button>
                </div>
            );
        default:
            return null;
    }

    return (
        <Form.Item
            key={f.name}
            name={f.name}
            label={f.label}
            labelAlign="left"
            // rules={rules}
            style={{
                maxWidth: "700px",
            }}
        >
            {element}
        </Form.Item>
    );
}

export default QFieldStandard;

function getMaxWidth() {}
