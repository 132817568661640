import React from "react";
import { useFieldsDirect } from "../../actions/app/fields";
import ItemEditForm from "../formview/ItemEditForm";

function PortableEditView({ collection, id }) {
    const {
        data: dataFields,
        isLoading: loadingFields,
        isSuccess: successFields,
    } = useFieldsDirect(collection);

    return (
        <>
            {successFields && (
                <ItemEditForm
                    id={id}
                    currentComp={{
                        data: {
                            collection: collection,
                        },
                        layout: {
                            style: "horizontal",
                            size: "default",
                        },
                    }}
                    fields={dataFields}
                    onDone="reset"
                />
            )}
        </>
    );
}

export default PortableEditView;
