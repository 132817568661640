import styles from "./styles/CreativeNew.module.css";
import React from "react";
import sample_img from "../../media/imgs/avatar.jpg";
import { Tooltip } from "antd";
import { ALLOWED_TYPES, CREATOR_BASE_URL } from "../../constants";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";

import { CREATION_TYPES, WELCOME_MSG } from "../../creation_constants";
import { getCreateURL } from "../../_helpers/editor";
import SubscriptionCard from "../../payments/SubscriptionCard";
import DonationCard from "../../payments/DonationCard";
import { Layout } from "antd";
import QurealSection from "../../common/QurealSection";
import { useItemsFine } from "../../actions/app/items";

const { Header, Content } = Layout;

const CREATOR_URL = CREATOR_BASE_URL;

const SingleItem = ({ item, path }) => {
    return (
        <div className={styles.gridItem} key={item.id}>
            <div className={styles.gridItemImg}>
                {/* <img
                    className={styles.giImg}
                    //  src={sample_img}
                    src={process.env.PUBLIC_URL + item.img}
                    alt="img"
                    style={{
                        objectFit: "cover",
                    }}
                /> */}
                <div
                    className={classNames(styles.giBackImg, "pan_h")}
                    style={{
                        // backgroundImage: `url(${process.env.PUBLIC_URL + item.img
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/creation_faces/card_min.jpg"
                            })`,
                    }}
                ></div>
                <div className={styles.giOverley}>
                    <Tooltip title="Create New">
                        <a
                            // href={`${CREATOR_URL}new?type=${item.id}`}
                            href={getCreateURL(item.id)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className={styles.giBtn}>
                                <i className="fas fa-plus"></i>
                            </button>
                        </a>
                    </Tooltip>
                    <Tooltip title="Browse Template">
                        <Link to={`${path}templates?type=${item.id}`}>
                            <button className={styles.giBtn}>
                                <i className="fas fa-search"></i>
                            </button>
                        </Link>
                    </Tooltip>
                </div>
            </div>
            <h2 className={styles.gridItemTitle}>{item.name}</h2>
        </div>
    );
};

const BlankCreate = () => {
    const match = useRouteMatch();
    const { isLoading, isSuccess, data } = useItemsFine("qdb_creation_type", "filter[enabled][_eq]=true", true);

    return (
        <QurealSection>
            <div className={styles.wrapper}>
                <h1 className={styles.heading}>
                    {
                        WELCOME_MSG[
                        Math.floor(Math.random() * WELCOME_MSG.length)
                        ]
                    }
                </h1>

                {/* <SubscriptionCard />
                <DonationCard /> */}
                <div className={styles.gridWrapper}>
                    {isSuccess && data.map((item) => {
                        return <SingleItem item={item} path={match.path} />;
                    })}
                </div>
            </div>
        </QurealSection>
    );
};



export default BlankCreate;
