import React from "react";
import { useMutation } from "react-query";
import { secureAxios } from "../_helpers/auth";
import { queryClient } from "../_helpers/query";
import { useCurrentUser } from "./auth";

export const useCreateOrg = (onSuccess = () => {}) => {
    return useMutation(
        (newItem) => {
            return secureAxios
                .post(`/items/qdb_organization`, newItem)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                onSuccess();
                queryClient.invalidateQueries(["user"]);
            },
        }
    );
};

export const useUpdateOrgMutation = (onSuccess = () => {}) => {
    return useMutation(
        (data) =>
            secureAxios
                .patch(`/items/qdb_organization/${data.id}`, data.data)
                .then((res) => res.data),
        {
            onSuccess: (data, variables) => {
                onSuccess();
                queryClient.invalidateQueries(["user"]);
            },
        }
    );
};

export const useSelectedOrg = () => {
    const { isLoading, isSuccess, data: dataMe } = useCurrentUser();
    return {
        isLoading,
        isSuccess,
        data: dataMe?.selected_org,
    };
};

export const useSelecteOrgMutation = (onSuccess = () => {}) => {
    return useMutation(
        (org_id) => {
            return secureAxios
                .patch("/users/me", { selected_org: org_id })
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                onSuccess();
                queryClient.invalidateQueries(["user"]);
            },
        }
    );
};
