import React from "react";
import QForm from "../qforms/QForm";

function QUIDemo() {
    // return <QForm />;
    return <h1>Demo</h1>;
}

export default QUIDemo;

// https://mantine.dev/core/radio/
