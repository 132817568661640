import styles from "./styles/PricingPlanCard.module.css";
import React from "react";
import { Button } from "antd";

function PricingPlanCard() {
    const data = {
        name: "Enterprice",
        price: 251,
        duration: "month",
        benifits: [
            "1 user per account",
            "unlimited events",
            "registration form",
            "Email announcement",
            "Integrate webinars",
            "Sales using mobile app",
        ],
    };
    return (
        <div className={styles.card_wrapper}>
            <h2 className={styles.name}>{data.name}</h2>
            <h1 className={styles.price}>${data.price}</h1>
            <h3 className={styles.duration}>/{data.duration}</h3>
            <Button type="primary">Get Started</Button>
            <ul className={styles.points_wrapper}>
                {data.benifits.map((item) => (
                    <li className={styles.point}>{item}</li>
                ))}
            </ul>
        </div>
    );
}

export default PricingPlanCard;
