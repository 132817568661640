import React from "react";
import styles from "./styles/ProfileCard.module.css";
import { BASE_URL } from "../../../constants";
import { restoreAccessToken } from "../../../_helpers/auth";
import profile_placeholder from "../../../media/imgs/user_placeholder.png";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}

// function Card({ title, subtitle, imgId }) {
function ProfileCard({ item, actions, onDefaultAction = null }) {
    const { title, subtitle, imageSource, content } = item;
    return (
        <div className={styles.card}>
            {/* {imageSource && ( */}
            <img
                src={
                    imageSource ? getImageURL(imageSource) : profile_placeholder
                }
                alt="profile pic"
            />

            <div className={styles.contentWrapper}>
                {onDefaultAction ? (
                    <h3
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: title ? title : "Full Name",
                        }}
                        onClick={onDefaultAction}
                        style={{
                            color: "blue",
                            cursor: "pointer",
                        }}
                    ></h3>
                ) : (
                    <h3
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: title ? title : "Full Name",
                        }}
                    ></h3>
                )}
                <div
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                ></div>
                <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{ __html: content }}
                ></div>
                {actions && (
                    <div className={styles.actions_wrapper}>{actions}</div>
                )}
            </div>
        </div>
    );
}

export default ProfileCard;
