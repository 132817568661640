import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    setAuthToken,
    publicAxios,
    secureAxios,
    saveTokens,
    restoreRefreshToken,
    resetTokens,
} from "../_helpers/auth";
import log, { p } from "cslog";
import { authAtom } from "../states/auth";
import { useAtom } from "jotai";
import { useMutation, useQuery } from "react-query";
import { history } from "../_helpers/history";
import { queryClient } from "../_helpers/query";
import { BASE_URL } from "../constants";
// import Cookies from "js-cookie";

export const useRefreshAuth = () => {
    const [auth, setAuth] = useState(false);
    // log.d(JSON.stringify(Cookies.get()), "All Cookies");

    useEffect(() => {
        fetch(`${BASE_URL}auth/refresh`, {
            method: "POST",
            credentials: "include", // this is required in order to send the refresh token cookie
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                }
                return null;
            })
            .then((data) => {
                if (data) {
                    setAuth({
                        status: true,
                        token: data.data.access_token,
                    });
                } else {
                    setAuth({
                        status: false,
                    });
                }
            });
    }, []);

    return auth;
};

export const useRegister = (onSuccess) => {
    return useMutation(
        (newItem) =>
            publicAxios
                .post("/qe_auth/register", newItem)
                .then((res) => res.data),
        {
            onSuccess: (data, variables, context) => {
                onSuccess(data);
            },
        }
    );
};

export const useCheckUser = (email, enabled = true) => {
    return useQuery(
        ["check_user", email],
        () => publicAxios.post("/qe_auth/check_user", { email: email }),
        {
            enabled: enabled,
        }
    );
};

export const useLogin = () => {
    const [, setAuth] = useAtom(authAtom);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState([]);

    function login(data) {
        setLoading(true);
        publicAxios
            .post("/auth/login", data, null)
            .then((res) => {
                log.p("Logged in successfully");
                const access_token = res?.data?.access_token;
                const refresh_token = res?.data?.refresh_token;
                saveTokens(res?.data);
                setAuthToken(access_token);
                setAuth({
                    isAuth: true,
                    access_token: access_token,
                    refresh_token: refresh_token,
                });
                setSuccess(true);
                setIsError(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        setAuthToken();
                    }
                    const data = err.response.data;
                    setErrors(data?.errors);
                    log.d(data?.errors, "Errors");
                } else if (err.request) {
                    console.log("Request is made but response not received.");
                    log.p("Request is Made but No response received");
                } else {
                    console.log("Something went wrong");
                    log.p("Something went wrong");
                }
                log.d(err?.response, "Error");
                setSuccess(false);
                setIsError(true);
            })
            .finally(() => setLoading(false));
    }

    return {
        loading,
        success,
        isError,
        errors,
        login,
    };
};

export const useSendPasswordResetMail = () => {
    return useMutation((data) =>
        publicAxios.post("/auth/password/request", data).then((res) => res.data)
    );
};

export const useResetPassword = () => {
    return useMutation((data) =>
        publicAxios.post("/auth/password/reset", data).then((res) => res.data)
    );
};

export const useLogout = () => {
    const [, setAuth] = useAtom(authAtom);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    function logout() {
        setLoading(true);
        secureAxios
            .post("/auth/logout", {
                refresh_token: restoreRefreshToken(),
            })
            .then((res) => {
                log.d("Successfully logged out");
                setSuccess(true);
            })
            .catch((err) => {
                log.d(err.response, "Error while logging out");
            })
            .finally(() => {
                resetTokens();
                setAuthToken();
                setAuth({
                    isAuth: false,
                    access_token: null,
                    refresh_token: null,
                });
                setLoading(false);
                queryClient.invalidateQueries();
                history.push("/login?nocheck=true");
                // queryClient.invalidateQueries();
            });
    }

    return {
        logout,
        loading,
        success,
    };
};

export const useMe = (enabled = true) => {
    return useQuery(
        "user",
        () =>
            secureAxios.get(
                "/users/me?fields=*,organizations.qdb_organization_id.*,selected_org.*"
            ),
        {
            retry: 0,
            //token valid time
            // staleTime: 1000 * 1,
            enabled: enabled,
        }
    );
};

export const useCurrentUser = () => {
    const { isLoading, isSuccess, data } = useMe();

    return {
        isLoading,
        isSuccess,
        data: data?.data,
    };
};

// export const useSelectedOrg = () => {
//     return useQuery(
//         "organizations",
//         () =>
//             secureAxios
//                 .get("/items/qdb_organization?filter[users][_eq]=$CURRENT_USER")
//                 .then((res) => res.data),
//         {}
//     );
// };

export const useIsAdmin = () => {
    const { isLoading, isSuccess, data } = useMe();

    if (isSuccess) {
        return data?.data?.role === "b35e905a-97f5-4fd4-87cc-631ecd33354b";
    } else {
        return false;
    }
};

export const useProfile = () => {
    const { isLoading, isSuccess, data } = useMe();

    let profile_id = null;
    if (isSuccess && data) {
        log.d(data, "profile data me");
        profile_id = data.data.profile[0];
    }

    log.d(profile_id, "profile data id");

    return useQuery(
        ["items", "profile", `/${profile_id}?fields=*.*`],
        () =>
            secureAxios
                .get(`/items/profile/${profile_id}?fields=*.*`)
                .then((res) => res.data),
        {
            enabled: isSuccess && data !== null,
        }
    );
};

export const useSettings = () => {
    return useQuery(
        "project_settings",
        () => publicAxios.get("/settings").then((res) => res.data),
        {
            retry: 2,
        }
    );
};

export const useMyPermissions = () => {
    return useQuery(
        "my_permissions",
        () => secureAxios.get("/permissions?limit=-1").then((res) => res.data),
        {
            retry: 0,
        }
    );
};

export const useCollectionPermission = (collection) => {
    const query = useMyPermissions();

    const permissions = query.data?.filter(
        (item) => item.collection === collection
    );

    const out = {};
    permissions.forEach((item) => {
        out[item.action] = item;
    });

    return {
        ...query,
        data: out,
    };
};

export const useChangePassword = () => {
    return useMutation((data) =>
        secureAxios.patch("/users/me", data).then((res) => res.data)
    );
};

export const useUpdateMe = () => {
    return useMutation(
        (data) => secureAxios.patch("/users/me", data).then((res) => res.data),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["user"]);
            },
        }
    );
};
