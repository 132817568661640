import { Layout } from "antd";
import React, { useMemo } from "react";
import useMedia from "react-use/lib/useMedia";
import QurealSection from "../../common/QurealSection";
import QurealForm from "../../forms/QurealForm";
import QurealFormStandard from "../../forms_standard/QurealFormStandard";
import { useMe } from "../../actions/auth";
import SectionHeader from "../commons/SectionHeader";

const { Content } = Layout;

function FormViewStandard({ comp, onSuccess = () => {} }) {
    const { data: dataMe, isSuccess: successMe } = useMe();

    const record_id = useMemo(() => {
        const data = comp.data;
        if (successMe && data.type === "edit") {
            const record = data.record;
            if (typeof record === "object" && record !== null) {
                const rid_type = record.type;
                switch (rid_type) {
                    case "fixed":
                        return record.value;
                    case "me":
                        const fvalue = dataMe.data[record.field];
                        if (Array.isArray(fvalue)) {
                            return fvalue[0];
                        } else {
                            return dataMe.data[record.field];
                        }
                    default:
                        return record.value;
                }
            }
            return null;
        }
        return null;
    }, [comp, dataMe, successMe]);

    return (
        <QurealSection header={<SectionHeader heading={comp.title} />}>
            <QurealFormStandard
                id={comp?.data?.form_id}
                org={"cooler_inc"}
                // id={12}
                record_id={record_id || undefined}
                wrapper_style={{
                    background: "white",
                    padding: "15px",
                    // fontSize: input.fontSize,
                    // fontWeight: input.bold ? "bold" : "normal",
                    // textTransform: input.uppercase
                    //     ? "uppercase"
                    //     : "none",
                    // ...decodeStyle(ps.fixStyle, gs),
                    // ...decodeStyle(ps.style, gs),
                    // ...(tablet(device) && decodeStyle(tabletStyle, gs)),
                    // ...(mobile(device) && decodeStyle(mobileStyle, gs)),
                }}
                configs={{
                    submitButtonText: comp?.layout?.save?.text,
                    submittingButtonText: "Saving...",
                    successMessage: "Your record is successfully created",
                }}
                onSuccess={
                    // () => alert("Done dana done")
                    () => {
                        onSuccess();
                    }
                    // performAction({
                    //     ...input.actionOnSuccess,
                    //     dydb: dydb,
                    // })
                }
            />
        </QurealSection>
    );
}

export default FormViewStandard;
