import React from "react";
import { Button } from "antd";
import Icon from "@ant-design/icons";

export default function UIButton({ layout, ...props }) {
    let btnProps = {};
    switch (layout.theme) {
        case "primary":
            btnProps = { ...btnProps, type: "primary" };
            break;
        case "danger":
            btnProps = { ...btnProps, danger: true };
            break;
        case "success":
            btnProps = { ...btnProps, style: { background: "#1e9447" } };
            break;
        case "warning":
            btnProps = {
                ...btnProps,
                style: { background: "yellow", color: "black" },
            };
            break;
        default:
            break;
    }
    switch (layout.style) {
        case "solid":
            btnProps = { ...btnProps, type: "primary" };
            break;
        case "ghost":
            btnProps = { ...btnProps, ghost: true };
            break;
        case "dashed":
            btnProps = { ...btnProps, type: "dashed" };
            break;
        default:
            break;
    }

    switch (layout.type) {
        case "button":
            btnProps = {
                ...btnProps,
                children: layout.text,
            };
            break;
        case "onlyicon":
            btnProps = {
                ...btnProps,
                icon: (
                    <Icon component={() => <i className={layout.icon}></i>} />
                ),
            };
            break;
        case "iconbutton":
            btnProps = {
                ...btnProps,
                icon: (
                    <Icon component={() => <i className={layout.icon}></i>} />
                ),
                children: layout.text,
            };
            break;
        default:
            break;
    }

    return <Button {...btnProps} {...props} />;
}
