import React, { Fragment, useEffect } from "react";
import { useScreen } from "../actions/app/screens";
import log from "cslog";
import Component from "../components/Component";
import LoadingFull from "../common/LoadingFull";
import { useParams } from "react-router-dom";
import EmptyFull from "../common/EmptyFull";
import { useAtom } from "jotai";
import { navAtom } from "../states/qcon";

function QurealScreen({ screen_id }) {
    const { id } = useParams();
    const {
        data: dataScreen,
        isSuccess: successScreen,
        isLoading: loadingScreen,
    } = useScreen(screen_id);
    const [nav, setNav] = useAtom(navAtom);

    log.d(dataScreen, "Data Screen");
    log.d(id, "Param ID");

    useEffect(() => {
        if (screen_id !== nav.screen) {
            setNav({
                ...nav,
                screen: screen_id,
            });
        }
    }, [screen_id]);

    // return <></>;
    if (loadingScreen) {
        return <LoadingFull />;
    } else if (successScreen) {
        return (
            <Fragment>
                {dataScreen.components.map((comp) => {
                    if (comp.qureal_component_id) {
                        return (
                            <Component
                                key={comp.id}
                                comp={comp.qureal_component_id}
                                slug={id}
                            />
                        );
                    } else {
                        return <></>;
                    }
                })}
                {dataScreen.components.length <= 0 && (
                    <EmptyFull text="No data available" />
                )}
                <div
                    style={{
                        width: "100%",
                        height: "70px",
                        minHeight: "70px",
                    }}
                ></div>
            </Fragment>
        );
    } else {
        return <></>;
    }
}

export default QurealScreen;
