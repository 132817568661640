import React from "react";
import OrgnaizationSettings from "../qbuilt_settings/OrganizationSettings";
import ChangePassword from "../auth/ChangePassword";
import FileBrowser from "../qmade/drive/FileBrowser";
import CreativeNew from "../qmade/creator/CreativeNew";
import CreationSingle from "../qmade/creator/creationdetails/CreationSingle";
import PricingPlans from "../qmade/pricingplans/PricingPlans";
import PricingPlansCompare from "../qmade/pricingplanscompare/PricingPlansCompare";
import CreateTable from "../database/table/CreateTable";
import QurealForm from "../qforms/QurealForm";
import EditTable from "../database/table/EditTable";
import CreationsHome from "../creations/CreationsHome";
import mapping_ecom from "../qbuilt_ecommerce";
import log from "cslog";
import UpdateProfile from "../auth/UpdateProfile";
import CollectionCreateComponent from "../collections/CollectionCreateComponent";
import EnquiryList from "../qbuilt_enquiry/EnquiryList";
import ContentCalender from "../content_calender/ContentCalender";
import ManageBrands from "../qbuilt_settings/ManageBrands";

const MAPPING = {
    ...mapping_ecom,
};

function QBuilt({ screen, params, me }) {
    switch (screen) {
        case "change_password":
            return <ChangePassword />;
        case "update_profile":
            return <UpdateProfile />;
        case "settings_org":
            return <OrgnaizationSettings />;
        case "manage_brand":
            return <ManageBrands />;
        case "content_calender":
            return <ContentCalender />;
        case "file_browser":
            return <FileBrowser />;
        //Creatives
        case "creative_new":
            return <CreativeNew />;
        case "creative_all_creations":
            // return <AllCreations />;
            return <CreationsHome me={me} />;
        case "creative_edit":
            return <CreationSingle params={params} />;
        case "pricing_plan":
            return <PricingPlans />;
        case "pricing_plan_compare":
            return <PricingPlansCompare />;
        //Database
        case "db_create_table":
            return <CreateTable />;
        case "db_create_form":
            return (
                <QurealForm
                    id={8}
                    record_id={1}
                    wrapperStyle={{
                        background: "white",
                    }}
                />
            );
        case "qdb_edit_table":
            return <EditTable id="qdb_lookup" params={params} />;
        case "qdb_create_collection":
            return <CollectionCreateComponent />;
        case "enquiry_all":
            return <EnquiryList />;
        case "enquiry_open":
            return <EnquiryList status="active" />;
        case "enquiry_closed":
            return <EnquiryList status="closed" />;
        case "enquiry_hold":
            return <EnquiryList status="on_hold" />;
        default:
            log.d(MAPPING, "qbuilt mapping");
            if (Object.keys(MAPPING).includes(screen)) {
                return MAPPING[screen];
            }
            return (
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h2
                        style={{
                            color: "grey",
                        }}
                    >
                        QBuilt Component not found: {screen}
                    </h2>
                </div>
            );
    }
}

export default QBuilt;
