import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useResetPassword } from "../actions/auth";
import CenterCard from "../_layouts/CenterCard";
import { Link, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
    const query = useQuery();
    const mutation = useResetPassword();

    if (mutation.isSuccess) {
        return <Redirect to="/login" />;
    }

    return (
        <CenterCard title="Set New Password">
            <Form
                name="normal_login"
                style={{
                    maxWidth: "300px",
                }}
                initialValues={{ remember: true }}
                onFinish={(values) => {
                    if (
                        values.password !== "" &&
                        values.password === values.password2
                    ) {
                        mutation.mutate({
                            token: query.get("token"),
                            password: values.password,
                        });
                    }
                }}
            >
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please enter new password",
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="New Password"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="password2"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your Password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("Passwords do not match!")
                                );
                            },
                        }),
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Confirm Password"
                        size="large"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        size="large"
                        loading={mutation.isLoading}
                    >
                        Reset Password
                    </Button>
                    {/* Or <a href="/">register now!</a> */}
                </Form.Item>
            </Form>
            {mutation.isSuccess && (
                <p
                    style={{
                        textAlign: "center",
                        color: "green",
                    }}
                >
                    Password reset successfully
                </p>
            )}
            <Link to="/login">
                <div
                    style={{
                        textAlign: "center",
                        paddingTop: "15px",
                        paddingBottom: "-20px",
                    }}
                >
                    Back to Login
                </div>
            </Link>
        </CenterCard>
    );
};

export default ResetPassword;
