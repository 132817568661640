import { Layout } from "antd";
import React from "react";
import { MAINBAR_WIDTH, SIDEBAR_WIDTH } from "../constants";
import useMedia from "react-use/lib/useMedia";
import Logo from "./Logo";

const { Sider, Header } = Layout;

const NavHeader = ({ children, theme = "light" }) => {
    const isMobile = useMedia("(max-width: 700px)");
    return (
        <div
            style={{
                position: "fixed",
                left: isMobile ? 0 : MAINBAR_WIDTH,
                backgroundColor: theme === "light" ? "white" : "black",
                zIndex: 5,
                width: "100%",
                // height: "30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                boxShadow: "0px 2px 9px -4px rgba(107,107,107,1)",
            }}
        >
            <div
                style={{
                    margin: "auto 16px",
                    marginLeft: "20px",
                }}
            >
                <Logo theme={theme} />
            </div>
            <div
                style={{
                    flex: 1,
                    height: "100%",
                    // backgroundColor: "yellow",
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default NavHeader;
