import { useQuery } from "react-query";
import { secureAxios, publicAxios } from "../../_helpers/auth";
import log from "cslog";

export const useAllRelations = (enabled = true) => {
    return useQuery(
        "relations",
        () => secureAxios.get(`/relations`).then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
            enabled: enabled,
        }
    );
};

export const useRelation = (collection, field_name, enabled = true) => {
    const query = useAllRelations(enabled);

    log.d(query.data, "All Relations");

    return {
        ...query,
        data: query.data?.find(
            (item) =>
                item.meta?.one_collection === collection &&
                item.meta?.one_field === field_name
        ),
    };
};
