import React, { Suspense, useMemo } from "react";
import { useMe } from "../actions/auth";
import QurealForm from "./QurealForm";
import { Layout } from "antd";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import Loading from "../common/Loading";

const { Content } = Layout;

function QurealFormComponent({ comp, slug, permissions }) {
    const { data: dataMe, isSuccess: successMe } = useMe();

    const record_id = useMemo(() => {
        const data = comp.data;
        if (successMe && data.type === "edit") {
            const record = data.record;
            if (typeof record === "object" && record !== null) {
                const rid_type = record.type;
                switch (rid_type) {
                    case "fixed":
                        return record.value;
                    case "me":
                        const fvalue = dataMe.data[record.field];
                        if (Array.isArray(fvalue)) {
                            return fvalue[0];
                        } else {
                            return dataMe.data[record.field];
                        }
                    default:
                        return record.value;
                }
            }
            return null;
        }
        return null;
    }, [comp, dataMe, successMe]);
    return (
        <QurealSection header={<SectionHeader heading={comp.title} />}>
            <Suspense fallback={<Loading text={comp.title} />}>
                <QurealForm
                    id={comp?.data?.form_id}
                    // record_id={comp?.data?.record_id}
                    record_id={record_id}
                    wrapperStyle={{
                        background: "white",
                        margin: "16px",
                    }}
                />
            </Suspense>
        </QurealSection>
    );
}

export default QurealFormComponent;
