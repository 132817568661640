import React, { useEffect, useState } from "react";
// import { useItemsFine } from "../../actions/items";
import { useItemsFine } from "../junction";
import Handlebars from "handlebars/dist/cjs/handlebars";
import Dropdown from "./Dropdown";
import MultiDropown from "./MultiDropdown";
import log from "cslog";
import { Select } from "antd";

const { Option } = Select;

function RelationField({ field, value, onChange, many = false, org }) {
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(
        field.foreign_key_table,
        // `limit=1000&sort=-${field.foreign_key_column}`,
        field.foreign_key_table === "qdb_lookup"
            ? `filter[lookup][_eq]=${field.lookup_type}`
            : "",
        field.foreign_key_table !== undefined,
        null,
        org
    );

    const [options, setOptions] = useState([]);

    // log.d(field, `Relation Field: ${many}`);
    // log.d(dataItems, "Relation Field Data");
    // log.d(options, "Relation Options");
    // log.d(value, "Relation field value");

    useEffect(() => {
        if (dataItems) {
            const option_template = Handlebars.compile(
                field.template || "template_not_available"
            );

            const newOptions = [];
            if (field.type === "relationM2M") {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template({
                            [field.junction_field]: item,
                        }),
                        value: item[field.foreign_key_column],
                    });
                });
            } else if (
                field.type === "relationO2M" ||
                field.type === "relation-o2m"
            ) {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template(item),
                        // value: item[field.foreign_key_column], //it should be id (m2m, o2m)
                        value: item[field.many_table_pk || "id"],
                    });
                });
            } else {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template(item),
                        value: item[field.foreign_key_column],
                    });
                });
            }
            setOptions(newOptions);
        }
    }, [dataItems]);

    const filterOption = (input, option) => {
        console.log(`Matching ${input}`, option);
        return (option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase());
    };

    if (many) {
        return (
            <Select
                mode="multiple"
                allowClear
                onChange={(val) => onChange(val)}
                defaultValue={value}
                showSearch
                filterOption={filterOption}
                options={options}
            />
        );
    }

    return (
        <Select
            allowClear
            onChange={(val) => onChange(val)}
            defaultValue={value}
            showSearch
            filterOption={filterOption}
            options={options}
        />
    );
}

export default RelationField;
