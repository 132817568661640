import React, { useEffect } from "react";
import { useMe } from "../actions/auth";
import { saveUser } from "../_helpers/auth";
import log from "cslog";
import { history } from "../_helpers/history";
import AppSplash from "../common/AppSplash";

function AutoLogin({ enable, children }) {
    const {
        isLoading: loadingMe,
        isSuccess: successMe,
        data: dataMe,
    } = useMe(enable);

    const sendHome = () => {
        const { from } = history.location.state || {
            from: { pathname: "/" },
        };
        history.push(from);
    };

    useEffect(() => {
        if (successMe) {
            saveUser(dataMe);
        }
        if (enable && successMe && !loadingMe && dataMe?.data !== undefined) {
            log.p("Sending home after auto-login");
            sendHome();
        }
    }, [successMe, loadingMe, dataMe, enable]);

    if (loadingMe) {
        return <AppSplash msg="Auto logging in..." />;
    }

    return <>{children}</>;
}

export default AutoLogin;
