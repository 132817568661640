import React, { useEffect, useState } from "react";
import {
    useCreateItemMutation,
    useSingleItem,
    useUpdateItemMutation,
} from "../../actions/app/items";
import log from "cslog";
import cloneDeep from "lodash/cloneDeep";
import { Menu, Dropdown, Popconfirm, Tooltip } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { CREATOR_BASE_URL } from "../../constants";
import { getEditURL, getEditURLDev, getViewerURL } from "../../_helpers/editor";
import { nanoid } from "nanoid";

const CREATOR_URL = CREATOR_BASE_URL;

function ItemOptionMenu({ children, item }) {
    const [clone, setClone] = useState(false);
    const { isSuccess, isLoading, data } = useSingleItem(
        "qp_creation",
        `/${item.id}`,
        clone
    );
    const createMutation = useCreateItemMutation();
    const updateMutation = useUpdateItemMutation();

    useEffect(() => {
        if (clone) {
            if (isSuccess && data) {
                let newData = cloneDeep(data);
                newData = {
                    ...newData,
                    title: `Copy of ${newData?.title}`,
                    slug: `${newData?.slug}_copy_${nanoid(5)}`,
                };
                delete newData["id"];
                delete newData["date_created"];
                delete newData["date_updated"];
                delete newData["published_creation"];

                let out = {
                    collection: "qp_creation",
                    data: newData,
                };
                log.h3("Cloning Req Sending...");
                log.d(out, "Final Data");
                createMutation.mutate(out);
                setClone(false);
            }
        }
    }, [clone, isSuccess, data]);

    const archiveItem = () => {
        log.p("Archiving....");
        const out = {
            collection: "qp_creation",
            id: item.id,
            data: {
                archived: true,
            },
        };
        updateMutation.mutate(out);
    };

    const OptionMenu = (
        <Menu>
            <a
                // href={getEditURL(item.id)}
                href={getEditURLDev(item.id)}
                target="_blank"
                rel="noreferrer"
            >
                <Menu.Item key="edit" icon={<UserOutlined />}>
                    Edit
                </Menu.Item>
            </a>
            <Menu.Item
                key="duplicate"
                icon={<UserOutlined />}
                onClick={() => setClone(true)}
            >
                Duplicate
            </Menu.Item>
            <a
                // href={`${CREATOR_URL}${item.id}/edit`}
                href={getViewerURL(item.slug, item.type === "website")}
                target="_blank"
                rel="noreferrer"
            >
                <Menu.Item key="view" icon={<UserOutlined />}>
                    View
                </Menu.Item>
            </a>
            {/* <Menu.Item key="rename" icon={<UserOutlined />}>
                Rename
            </Menu.Item> */}
            <Popconfirm
                title="Are you sure?"
                placement="topRight"
                okText="Delete"
                onConfirm={archiveItem}
            >
                <Menu.Item
                    key="delete"
                    icon={<UserOutlined />}
                    danger
                    // onClick={archiveItem}
                >
                    Move to trash
                </Menu.Item>
            </Popconfirm>
        </Menu>
    );

    return (
        <Dropdown
            // overlay={menu}
            overlay={OptionMenu}
            placement="topRight"
            overlayStyle={{
                boxShadow: "0px 0px 5px 0px rgba(186,186,186,1)",
            }}
        >
            {children}
        </Dropdown>
    );
}

export default ItemOptionMenu;
