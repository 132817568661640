import { Checkbox, Popover, Radio } from "antd";
import { useAtom } from "jotai";
import startCase from "lodash/startCase";
import React from "react";
import { currentModuleAtom } from "../../states/app/module";
import { currentComponentAtom } from "../../states/app/component";

const LayoutCustomizer = ({ comp_id, fields, trigger }) => {
    // const [currentModule, setCurrentModule] = useAtom(
    //     currentModuleAtom(module_id)
    // );
    const [currentComp, setCurrentComp] = useAtom(
        currentComponentAtom(comp_id)
    );

    let selectedFields = currentComp.data.fields;
    if (!selectedFields) {
        selectedFields = fields.map((item) => item.field);
    }

    const setSelectedFields = (newFields) => {
        const newModule = {
            ...currentComp,
            data: {
                ...currentComp.data,
                fields: newFields,
            },
        };
        setCurrentComp(newModule);
    };

    let tableSpacing = currentComp.layout.spacing;
    const setTableSpacing = (newSpacing) => {
        const newModule = {
            ...currentComp,
            layout: {
                ...currentComp.layout,
                spacing: newSpacing,
            },
        };
        setCurrentComp(newModule);
    };
    return (
        <Popover
            title="Customize Table Layout"
            trigger="click"
            placement="bottomRight"
            content={
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "60vh",
                        overflowY: "auto",
                    }}
                >
                    <Radio.Group
                        buttonStyle="solid"
                        value={tableSpacing}
                        onChange={(e) => setTableSpacing(e.target.value)}
                    >
                        <Radio.Button value="default">Comfortable</Radio.Button>
                        <Radio.Button value="middle">Cozy</Radio.Button>
                        <Radio.Button value="small">Compact</Radio.Button>
                    </Radio.Group>

                    <Checkbox.Group
                        value={selectedFields ? selectedFields : []}
                        onChange={(list) => {
                            // log.d(list, "New Selection");
                            // const newPreset = {
                            //     ...preset,
                            //     layout_query: {
                            //         ...preset?.layout_query,
                            //         tabular: {
                            //             ...preset?.layout_query?.tabular,
                            //             fields: list,
                            //         },
                            //     },
                            // };
                            // log.d(newPreset, "NewPreset");
                            // setPreset(newPreset);
                        }}
                        style={{
                            // width: "100%",
                            // backgroundColor: "cyan",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "flex-start",
                            paddingTop: "20px",
                        }}
                    >
                        {fields?.map((item, index) => {
                            return (
                                <Checkbox
                                    key={item.field}
                                    value={item.field}
                                    style={{
                                        marginLeft: "8px",
                                    }}
                                    onChange={(e) => {
                                        // log.d(e.target.checked, item.field);
                                        const newSelFields =
                                            selectedFields.slice();
                                        if (e.target.checked) {
                                            const fi = selectedFields.indexOf(
                                                item.field
                                            );
                                            if (fi === -1) {
                                                newSelFields.push(item.field);
                                                setSelectedFields(newSelFields);
                                            }
                                        } else {
                                            const fi = selectedFields.indexOf(
                                                item.field
                                            );
                                            if (fi !== -1) {
                                                newSelFields.splice(fi, 1);
                                                setSelectedFields(newSelFields);
                                            }
                                        }
                                    }}
                                >
                                    {startCase(item.field)}
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                </div>
            }
        >
            {trigger}
        </Popover>
    );
};

export default LayoutCustomizer;
