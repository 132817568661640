import React, { Suspense } from "react";
import ChangePassword from "../auth/ChangePassword";
import CreationsHome from "../creations/CreationsHome";
import QurealForm from "../qforms/QurealForm";
import CreationSingle from "./creator/creationdetails/CreationSingle";
import CreativeNew from "./creator/CreativeNew";
import PricingPlans from "./pricingplans/PricingPlans";
import PricingPlansCompare from "./pricingplanscompare/PricingPlansCompare";

const FileBrowser = React.lazy(() => import("./drive/FileBrowser"));
const CreateTable = React.lazy(() => import("../database/table/CreateTable"));
const EditTable = React.lazy(() => import("../database/table/EditTable"));

const QMade = ({ screen, params }) => {
    if (screen === "creative_new") {
        return <CreativeNew />;
    } else if (screen === "creative_all_creations") {
        // return <AllCreations />;
        return <CreationsHome />;
    } else if (screen === "creative_edit") {
        return <CreationSingle params={params} />;
    } else if (screen === "pricing_plan") {
        return <PricingPlans />;
    } else if (screen === "pricing_plan_compare") {
        return <PricingPlansCompare />;
    } else if (screen === "file_browser") {
        return (
            <Suspense fallback={<h3>Loading...</h3>}>
                <FileBrowser />
            </Suspense>
        );
    } else if (screen === "change_password") {
        return (
            <Suspense fallback={<h3>Loading...</h3>}>
                <ChangePassword />
            </Suspense>
        );
    } else if (screen === "db_create_table") {
        return (
            <Suspense fallback={<h3>Loading...</h3>}>
                <CreateTable />
            </Suspense>
        );
    } else if (screen === "db_create_form") {
        return (
            <Suspense fallback={<h3>Loading...</h3>}>
                <QurealForm
                    id={8}
                    record_id={1}
                    wrapperStyle={{
                        background: "white",
                    }}
                />
            </Suspense>
        );
    } else if (screen === "qdb_edit_table") {
        return (
            <Suspense fallback={<h3>Loading...</h3>}>
                <EditTable id="qdb_lookup" params={params} />
            </Suspense>
        );
    } else if (screen === "change_password") {
        return <ChangePassword />;
    }
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <h2
                style={{
                    color: "grey",
                }}
            >
                Loading...
            </h2>
        </div>
    );
};

export default QMade;
