import { Switch, Route, useRouteMatch } from "react-router-dom";
import React from "react";
import BlankCreate from "./BlankCreate";
import BrowseTemplates from "./BrowseTemplates";

const CreativeNew = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${match.path}/`}
                component={() => <BlankCreate />}
            />

            <Route
                exact
                path={`${match.path}/templates`}
                component={() => <BrowseTemplates />}
            />
        </Switch>
    );
};

export default CreativeNew;
