import { startCase } from "lodash";
import React from "react";
import { useSingleItem } from "../../actions/app/items";
import AddField from "./AddField";
import styles from "./styles/EditFields.module.css";

function EditFields({ table_id }) {
    const {
        isLoading: loadingTable,
        isSuccess: successTable,
        data: dataTable,
    } = useSingleItem(
        "qdb_table",
        `/${table_id}?fields=*,fields.*`,
        table_id !== undefined
    );
    return (
        <div>
            {/* {successTable && JSON.stringify(dataTable)} */}
            {successTable && (
                <div className={styles.itemsWrapper}>
                    {dataTable.fields.map((f) => {
                        return (
                            <div className={styles.item}>
                                <span className={styles.name}>{f.label}</span>
                                <div className={styles.inner}>
                                    <span className={styles.type}>
                                        {startCase(f.value_type)}
                                    </span>
                                    <span className={styles.type}>
                                        {startCase(f.interface?.type)}
                                    </span>
                                </div>
                                <span className={styles.actions}>
                                    <i className="fas fa-edit" />
                                    <i className="fas fa-edit" />
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            <AddField />
        </div>
    );
}

export default EditFields;
