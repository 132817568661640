import types from "./types.json";
import extensions from "./extensions.json";

export function getMimeType(type) {
    return types[type] || "Unknown";
}

export function getMimeExtension(type) {
    return extensions[type] || "UN";
}
