import React, { useState } from "react";
import startCase from "lodash/startCase";
import styles from "./styles/EditTable.module.css";
import classNames from "classnames";
import EditFields from "./EditFields";
import FormList from "./FormList";
import QurealSection from "../../common/QurealSection";
import SectionHeader from "../../components/commons/SectionHeader";

function EditTable({ id, params }) {
    const [selectedTab, setSelectedTab] = useState("Fields");

    const table_id = params?.table_id;

    if (!table_id) {
        return (
            <QurealSection>
                <div style={{ padding: "15px" }}>
                    <h4>No table selected</h4>
                </div>
            </QurealSection>
        );
    }

    return (
        <QurealSection
            header={<SectionHeader heading={`Edit ${startCase(table_id)}`} />}
        >
            <div style={{ padding: "15px" }}>
                <div className={styles.tabWrapper}>
                    {["Fields", "Settings", "Forms", "Views"].map((item) => {
                        return (
                            <div
                                className={classNames({
                                    [styles.tab]: true,
                                    [styles.selected]: selectedTab === item,
                                })}
                                onClick={() => setSelectedTab(item)}
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        paddingTop: "15px",
                    }}
                >
                    {selectedTab === "Fields" && (
                        <EditFields table_id={table_id} />
                    )}
                    {selectedTab === "Forms" && (
                        <FormList table_id={table_id} />
                    )}
                </div>
            </div>
        </QurealSection>
    );
}

export default EditTable;
