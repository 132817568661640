import { useQuery } from "react-query";
import { secureAxios } from "../../_helpers/auth";

export const useApps = () => {
    return useQuery(
        "apps",
        () =>
            secureAxios
                .get(
                    "items/qureal_app?filter[status][_eq]=published&filter[allowed_roles][directus_roles_id][_eq]=$CURRENT_ROLE&fields=*,screens.*"
                    // "items/qui_app?filter[status][_eq]=published&filter[allowed_roles][directus_roles_id][_eq]=$CURRENT_ROLE&fields=*,qscreens.*"
                )
                .then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
        }
    );
};
