import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Filter } from "../../Filters";
import log from "cslog";
import { useAtom } from "jotai";
import { currentComponentAtom } from "../../../../states/app/component";

export default function FilterDropdown({ action, comp_id, fields }) {
    const [currentComp, setCurrentComp] = useAtom(
        currentComponentAtom(comp_id)
    );
    const [filter, setFilter] = useState({
        field: action.meta.field,
        operator: action.meta.operator,
        value: null,
    });

    const applyFilter = (newFilter) => {
        setFilter(newFilter);
        setCurrentComp({
            ...currentComp,
            data: {
                ...currentComp.data,
                // filters: [...currentComp.data.filters, newFilter],
                external_filters: [newFilter],
                external_filter_match: "_and",
            },
        });
    };

    useEffect(() => {
        if (!filter.value) {
            const efilters = currentComp.data.external_filters;
            const ef = efilters?.find((f) => f.field === filter.field);
            if (ef) {
                setFilter(ef);
            }
        }
    }, [currentComp]);

    return (
        <Filter
            alone={true}
            fields={fields}
            filter={filter}
            setFilter={(newFilter) => {
                log.d(newFilter, "Modified Filter");
                applyFilter(newFilter);
            }}
        />
    );
}
