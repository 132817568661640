import { Layout } from "antd";
import log from "cslog";
import React, { useEffect, useState } from "react";
import useMedia from "react-use/lib/useMedia";
import { useFields } from "../../actions/app/fields";
import { useSingleItem } from "../../actions/app/items";
import { useMe } from "../../actions/auth";
import Loading from "../../common/Loading";
import SectionHeader from "../commons/SectionHeader";
import Default from "./default/Default";
import OneColumnView from "./onecolumn/OneColumnView";
import Profile from "./profile/Profile";
import TwoColumnView from "./twocolumn/TwoColumnView";

const { Content } = Layout;

function DisplayView({ comp }) {
    const isMobile = useMedia("(max-width: 700px)");
    const { data: dataMe, isSuccess: succesMe } = useMe();
    const [record_id, setRecord_id] = useState(null);
    const {
        data: dataFields,
        isLoading: loadingFields,
        isSuccess: successFields,
    } = useFields(comp.data.collection);
    const {
        data: dataItem,
        isLoading: loadingItem,
        isSuccess: successItem,
    } = useSingleItem(
        comp.data.collection,
        `/${record_id}?fields=*.*`,
        record_id !== null
    );

    // log.d(currentComp, "Current Component");

    log.d(comp, "In display View");

    useEffect(() => {
        if (comp) {
            const temp_id = comp.data.id;
            if (typeof temp_id === "object" && temp_id !== null) {
                const rid_type = temp_id.type;
                switch (rid_type) {
                    case "fixed":
                        setRecord_id(`${temp_id.value}`);
                        break;
                    case "me":
                        if (dataMe) {
                            setRecord_id(`${dataMe.data[temp_id.field]}`);
                        }
                        break;
                    default:
                        break;
                }
            } else {
                setRecord_id(`${comp.data.id}`);
            }
        }
    }, [comp, dataMe]);

    if (successItem) {
        log.d(dataItem, "Single Item");
    }
    log.d(dataItem, "Single Item");

    if (successFields && successItem && comp) {
        const subtype = comp.subtype;
        let element = null;
        switch (subtype) {
            case "default":
                element = (
                    <Default
                        selected_fields={null}
                        fields={dataFields}
                        item={dataItem}
                    />
                );
                break;
            case "profile":
                element = (
                    <Profile comp={comp} fields={dataFields} item={dataItem} />
                );
                break;
            case "onecolumn":
                element = (
                    <OneColumnView
                        comp={comp}
                        fields={dataFields}
                        item={dataItem}
                    />
                );
                break;
            case "twocolumn":
                element = (
                    <TwoColumnView
                        comp={comp}
                        fields={dataFields}
                        item={dataItem}
                    />
                );
                break;
            case "cardsview":
                element = null;
                break;

            default:
                element = <h1>Component subtype supported yet</h1>;
                break;
        }
        return (
            <Content
                style={{
                    padding: isMobile ? 0 : "0 16px",
                    // display: "flex",
                    // flexDirection: "column",
                }}
            >
                {comp.title ? (
                    <div
                        style={{
                            position: "sticky",
                            top: "-10px",
                            zIndex: 3,
                        }}
                    >
                        {/* <h3>Header</h3> */}
                        <SectionHeader heading={comp.title} />
                    </div>
                ) : (
                    <div
                        style={{
                            height: "20px",
                        }}
                    ></div>
                )}
                <div
                    style={{
                        padding: isMobile ? 0 : 12,
                        maxWidth: "100%",
                        overflowX: "auto",
                        minHeight: 180,
                        // overflow: "auto",
                        background: "white",
                    }}
                >
                    {element}
                </div>
            </Content>
        );
    } else if (loadingFields || loadingItem) {
        return <Loading text={comp.title} />;
    } else {
        return <></>;
    }
}

export default DisplayView;
