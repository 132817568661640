import React from "react";
import { Card, Divider, Layout } from "antd";
import styles from "./styles/CenterCard2Part.module.css";
import { LOGIN_BG } from "../constants";

const CenterCard2Part = ({ title, logo, children, center = true }) => {
    const bg_style = {
        // background: `url('${process.env.PUBLIC_URL}/back.jpg')`,
        background: LOGIN_BG,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    };
    return (
        <Layout className={styles.wrapper} style={bg_style}>
            {/* {logo && <img src={logo} alt="logo" className={styles.logo} />} */}
            <div className={styles.card}>
                {/* <h1>LOGO</h1> */}
                <div className={styles.left_box}>{/* <h3>Hello</h3> */}</div>
                {/* <div className={styles.divider_v}></div> */}
                <div className={styles.right_box}>
                    {logo && (
                        <div className={styles.logo_inside_wrapper}>
                            <img
                                src={logo}
                                alt="logo"
                                className={styles.logo_inside}
                            />
                        </div>
                    )}
                    <h2 className={styles.heading}>{title}</h2>
                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        </Layout>
    );
};

export default CenterCard2Part;
