import {
    Popover,
    Radio,
    Dropdown,
    DatePicker,
    Menu,
    Button,
    Space,
} from "antd";
import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import { currentModuleAtom } from "../../states/app/module";
import {
    TableOutlined,
    PlusOutlined,
    UserOutlined,
    CloseOutlined,
    ArrowDownOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import { Fragment } from "react";
import log from "cslog";
import toUpper from "lodash/toUpper";
import { Select, Input } from "antd";
import { startCase } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { currentComponentAtom } from "../../states/app/component";
import ForeignField from "./ForeignField";
import { useFields } from "../../actions/app/fields";

const { Option } = Select;

const OPERATOR_LOOKUP = {
    _eq: "Equal to",
    _neq: "Not equal to",
    _lt: "Less than",
    _lte: "Less than or equal to",
    _gt: "Greater than",
    _gte: "Greater than or equal to",
    _in: "Matches any of the values",
    _nin: "Doesn't match any of the values",
    _null: "is null",
    _nnull: "is not null",
    _contains: "Contains",
    _ncontains: "Doesn't contain",
    _starts_with: "Starts with",
    _nstarts_with: "Doesn't start with",
    _ends_with: "Ends with",
    _nends_with: "Doesn't end with",
    _between: "Is between",
    _nbetween: "Is not between",
    _empty: "Is empty",
    _nempty: "Is not empty",
};

function getAvailableFilters(dtype) {
    switch (dtype) {
        case "string":
        case "text":
            return [
                "_contains",
                "_ncontains",
                "_eq",
                "_neq",
                "_starts_with",
                "_nstarts_with",
                "_empty",
                "_nempty",
                "_in",
                "_nin",
            ];
        case "integer":
        case "float":
        case "date":
        case "datetime":
            return [
                "_eq",
                "_neq",
                "_lt",
                "_lte",
                "_gt",
                "_gte",
                "_between",
                "_nbetween",
                "_in",
                "_nin",
            ];
        default:
            return ["_empty", "_nempty", "_eq", "_neq", "_null", "_nnull"];
    }
}

const ValueBox = ({ field, filter, setFilter }) => {
    const op = filter.operator;
    const value = filter.value;

    switch (op) {
        case "_empty":
        case "_nempty":
        case "_null":
        case "_nnull":
            if (!value) {
                setFilter({ ...filter, value: true });
            }
            return <div style={{ width: 120, height: 10 }}></div>;

        case "_eq":
        case "_neq":
        case "_lt":
        case "_lte":
        case "_gt":
        case "_gte":
            const itype = field.meta?.interface;
            if (itype === "select-dropdown") {
                const choices = field.meta.options.choices;
                // if (!value) {
                //     setFilter({ ...filter, value: choices[0]["value"] });
                // }
                return (
                    <Select
                        style={{ width: 120 }}
                        defaultValue={filter.value}
                        value={filter.value}
                        onChange={(value) => {
                            setFilter({
                                ...filter,
                                value: value,
                            });
                        }}
                    >
                        {choices.map((item) => {
                            return (
                                <Option value={item.value} key={item.value}>
                                    {item.text}
                                </Option>
                            );
                        })}
                    </Select>
                );
            } else if (itype === "select-dropdown-m2o") {
                return (
                    <ForeignField
                        field={field}
                        many={false}
                        style={{ width: 150 }}
                        value={filter.value}
                        onChange={(value) => {
                            setFilter({
                                ...filter,
                                value: value,
                            });
                        }}
                        defaultSelect={true}
                    />
                );
            } else if (itype === "datetime") {
                const datetype = field.schema?.data_type;
                if (datetype === "date") {
                    return (
                        <DatePicker
                            value={filter.value}
                            onChange={(newValue) =>
                                setFilter({ ...filter, value: newValue })
                            }
                            style={{ width: 120 }}
                        />
                    );
                } else {
                    return (
                        <DatePicker
                            showTime
                            style={{ width: 150 }}
                            value={filter.value}
                            onChange={(newValue) =>
                                setFilter({ ...filter, value: newValue })
                            }
                        />
                    );
                }
            }
            return (
                <Input
                    style={{ width: 120 }}
                    value={filter.value}
                    onChange={(e) => {
                        log.d(e.target.value, "VALUE");
                        setFilter({
                            ...filter,
                            value: e.target.value,
                        });
                    }}
                />
            );
        default:
            return (
                <Input
                    style={{ width: 120 }}
                    value={filter.value}
                    onChange={(e) => {
                        log.d(e.target.value, "VALUE");
                        setFilter({
                            ...filter,
                            value: e.target.value,
                        });
                    }}
                />
            );
    }
};

const Operator = ({ field, filter, setFilter }) => {
    const dtype = field?.schema?.data_type;
    const listOps = getAvailableFilters(dtype);

    useEffect(() => {
        if (!filter.operator) {
            setFilter({
                ...filter,
                operator: listOps[0],
            });
        }
    }, [filter]);

    return (
        <Select
            style={{ width: 120 }}
            defaultValue={filter.operator}
            value={filter.operator}
            onChange={(value) => {
                setFilter({
                    ...filter,
                    operator: value,
                });
            }}
        >
            {listOps.map((op) => {
                return (
                    <Option key={op} value={op}>
                        {OPERATOR_LOOKUP[op]}
                    </Option>
                );
            })}
        </Select>
    );
};

export const Filter = ({
    fields,
    filter,
    setFilter,
    addFilter,
    removeSelf,
    isLast,
    alone = false,
}) => {
    // const [selField, setSelField] = useState(null);

    useEffect(() => {
        log.d(filter, "Filter in useEffect");
        if (!filter.field) {
            setFilter({
                ...filter,
                field: fields[0]["field"],
            });
        }
    }, [filter]);

    const selField = fields.find((fff) => fff.field === filter.field);

    return (
        <Space>
            {!alone && (
                <Select
                    style={{ width: 120 }}
                    defaultValue={filter.field}
                    onChange={(value) => {
                        setFilter({
                            field: value,
                        });
                    }}
                >
                    {fields.map((ff) => {
                        return (
                            <Option key={ff.field} value={ff.field}>
                                {startCase(ff.field)}
                            </Option>
                        );
                    })}
                </Select>
            )}
            {!alone && (
                <Operator
                    field={selField}
                    filter={filter}
                    setFilter={setFilter}
                />
            )}
            <ValueBox field={selField} filter={filter} setFilter={setFilter} />
            {!alone && (
                <Button
                    danger
                    icon={<CloseOutlined />}
                    onClick={removeSelf}
                ></Button>
            )}
            {!alone && isLast ? (
                <Button
                    type="primary"
                    ghost
                    style={{
                        // backgroundColor: "green",
                        color: "green",
                        borderColor: "green",
                    }}
                    icon={<PlusOutlined />}
                    onClick={addFilter}
                ></Button>
            ) : !alone ? (
                <Button disabled />
            ) : (
                <></>
            )}
        </Space>
    );
};

export function RawFilters({
    fields,
    filters,
    setFilters,
    filterMatch,
    setFilterMatch,
    applyFilters,
}) {
    // log.d(filters, "Filters");
    // log.d(currentComp, "Current Comp");
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
            }}
        >
            {/* <h3>Filters</h3> */}
            {filters.length === 0 && (
                <Button
                    type="primary"
                    ghost
                    style={{
                        // backgroundColor: "green",
                        color: "green",
                        borderColor: "green",
                    }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                        setFilters([...filters, {}]);
                    }}
                ></Button>
            )}
            {filters.map((item, index) => {
                return (
                    <Filter
                        key={index}
                        fields={fields}
                        filter={item}
                        setFilter={(newValue) => {
                            const newFilters = cloneDeep(filters);
                            newFilters.splice(index, 1, newValue);
                            setFilters(newFilters);
                        }}
                        addFilter={() => {
                            setFilters([...filters, {}]);
                        }}
                        removeSelf={() => {
                            const newFilters = cloneDeep(filters);
                            newFilters.splice(index, 1);
                            setFilters(newFilters);
                        }}
                        isLast={index === filters.length - 1}
                    />
                );
            })}
            <Space>
                <Select
                    style={{ width: 150 }}
                    defaultValue={filterMatch}
                    value={filterMatch}
                    onChange={(value) => {
                        setFilterMatch(value);
                    }}
                >
                    <Option key="all" value="_and">
                        Match All
                    </Option>
                    <Option key="any" value="_or">
                        Match Any
                    </Option>
                </Select>
                <Button type="primary" onClick={applyFilters}>
                    Filter
                </Button>
            </Space>
        </div>
    );
}

export function TableFilters({
    collection,
    filters,
    setFilters,
    filterMatch,
    setFilterMatch,
}) {
    const {
        data: dataFields,
        isLoading: loadingFields,
        isSuccess: successFields,
    } = useFields(collection);
    const applyFilters = () => {};

    if (!successFields) {
        return <p>Loading...</p>;
    }
    return (
        <RawFilters
            fields={dataFields}
            filters={filters}
            setFilters={setFilters}
            filterMatch={filterMatch}
            setFilterMatch={setFilterMatch}
            applyFilters={applyFilters}
        />
    );
}

const Filters = ({ comp_id, fields, trigger }) => {
    const [currentComp, setCurrentComp] = useAtom(
        currentComponentAtom(comp_id)
    );
    const [filters, setFilters] = useState(currentComp.data.filters);
    const [filterMatch, setFilterMatch] = useState(
        currentComp.data.filter_match ? currentComp.data.filter_match : "_and"
    );

    useEffect(() => {
        if (filters.length === 0) {
            setFilters([...filters, {}]);
        }
    }, []);

    const applyFilters = () => {
        setCurrentComp({
            ...currentComp,
            data: {
                ...currentComp.data,
                filters: filters,
                filter_match: filterMatch,
            },
        });
    };

    // log.d(fields, "Fields in Filters");

    return (
        <Popover
            title="Filter Data"
            trigger="click"
            placement="bottomRight"
            content={
                <RawFilters
                    fields={fields}
                    filters={filters}
                    setFilters={setFilters}
                    filterMatch={filterMatch}
                    setFilterMatch={setFilterMatch}
                    applyFilters={applyFilters}
                />
            }
        >
            {trigger}
        </Popover>
    );
};

export default Filters;
