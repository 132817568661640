import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "./auth/ForgotPassword";
// import Login from "./auth/Login";
import ResetPassword from "./auth/ResetPassword";
import PrivateRoute from "./common/PrivateRoute";
import Home from "./home/Home";
import Login from "./qureal_auth/Login";
// import Login from "./auth/Login";
// import Register from "./auth/Register";
import VerifyAuth from "./auth/VerifyAuth";
import { REGISTER_COMP } from "./constants";
import OrgSetup from "./auth/OrgSetup";
import { Layout } from "antd";
import PrivacyPolicy from "./payments/extras/PrivacyPolicy";
import TermsAndConditions from "./payments/extras/TermsAndConditions";

const { Content } = Layout;

const App = () => {
    return (
        // <div
        //     style={{
        //         display: "flex",
        //         flexDirection: "row",
        //     }}
        // >
        <Content>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/privacy_policy" component={PrivacyPolicy} />
                <Route
                    exact
                    path="/terms_and_conditions"
                    component={TermsAndConditions}
                />
                <Route exact path="/verify_auth" component={VerifyAuth} />
                <Route
                    exact
                    path="/register"
                    component={() => {
                        return (
                            <Suspense fallback={() => <h3>Loading...</h3>}>
                                <REGISTER_COMP />
                            </Suspense>
                        );
                    }}
                />
                <Route
                    exact
                    path="/forgot_password"
                    component={ForgotPassword}
                />
                <Route
                    exact
                    path="/verify_password"
                    component={ResetPassword}
                />
                <PrivateRoute exact path="/setup_org" component={OrgSetup} />
                <PrivateRoute path="/" component={Home} />
                {/* <PrivateRoute path="/home" component={NewHome} /> */}
                {/* <Route path="/" component={Home} /> */}
            </Switch>
        </Content>
        // </div>
    );
};

export default App;
