import styles from "./styles/ActionBox.module.css";
import React from "react";
import { Button, Layout } from "antd";

const { Content } = Layout;

// action = {label, link, onClick}
function ActionBox({
    icon,
    title,
    subtitle,
    content,
    action,
    alignItems = "center",
}) {
    return (
        <Content
            className={styles.wrapper}
            style={{
                alignItems: alignItems,
            }}
        >
            {icon && icon}
            <h3 className={styles.title}>{title}</h3>
            {subtitle && <h5 className={styles.subtitle}>{subtitle}</h5>}
            {content && <p className={styles.content}>{content}</p>}
            {action && action}
        </Content>
    );
}

export default ActionBox;
