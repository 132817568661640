import {
    CREATOR_BASE_URL,
    CREATOR_BASE_URL_DEV,
    VIEWER_BASE_URL,
} from "../constants";
import { restoreAccessToken, restoreRefreshToken } from "./auth";

export function getCreateURL(type) {
    // return `${CREATOR_BASE_URL}new?type=${type}&at=${restoreAccessToken()}&rt=${restoreRefreshToken()}`;
    return `${CREATOR_BASE_URL}new?id=${type}&at=${restoreAccessToken()}&rt=${restoreRefreshToken()}`;
}

export function getEditURL(id) {
    return `${CREATOR_BASE_URL}${id}/edit?at=${restoreAccessToken()}&rt=${restoreRefreshToken()}`;
}

export function getEditURLDev(id) {
    return `${CREATOR_BASE_URL_DEV}${id}/edit?at=${restoreAccessToken()}&rt=${restoreRefreshToken()}`;
}

export function getViewerURL(slug, isWebsite = false) {
    return `${VIEWER_BASE_URL}/${isWebsite ? "w" : "s"}/${slug}`;
}
