import React from "react";
import QurealRichTextEditor from "./QurealRichTextEditor";

function QurealRTEField({ value, onChange }) {
    return (
        <div style={{ border: "1px solid #ddd", borderRadius: "3px" }}>
            <QurealRichTextEditor value={value} onChange={onChange} />
        </div>
    );
}

export default QurealRTEField;
