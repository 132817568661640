import React, { useEffect, useState } from "react";
import ButtonCreateOther from "./actions/table/ButtonCreateOther";
import ButtonExternal from "./actions/table/ButtonExternal";
import FilterDropdown from "./actions/table/FilterDropdown";
import UpdateAllbutton from "./actions/table/UpdateAllButton";
import { getValidationFunc } from "../../_helpers/app/conditions";
import log from "cslog";
import { useExternal } from "../../actions/app/external";
import { Button } from "antd";
import { history } from "../../_helpers/history";
import UIButton from "./UIButton";

export default function TableActionCell({
    action,
    currentComp,
    fields,
    items,
}) {
    const [show, setShow] = useState(false);
    const [exReq, setExReq] = useState({
        enable: false,
        path: null,
        data: null,
    });
    const resEx = useExternal(exReq.enable, exReq.path, exReq.data);

    log.d(resEx, "Response EXT");

    useEffect(() => {
        if (resEx.isSuccess) {
            const data = resEx.data?.data;
            log.d(data, "Res EXt Data");
            if ("show" in data) {
                setShow(data?.show);
            }
        }
    }, [resEx]);

    useEffect(() => {
        let shouldShow = true;
        const conditions = action.conditions;
        log.d(conditions, "Conditions");
        if (Array.isArray(conditions)) {
            conditions?.forEach((cond) => {
                const ctype = cond.type;
                const match = cond.match ? cond.match : "all";
                switch (ctype) {
                    case "local":
                        const vfunc = getValidationFunc(cond.conditions);
                        if (match === "all") {
                            if (items.length <= 0) {
                                shouldShow = false;
                            } else {
                                shouldShow = items.every((record) =>
                                    vfunc(record)
                                );
                            }
                        } else {
                            shouldShow = items.some((record) => vfunc(record));
                        }
                        break;
                    case "external":
                        if (shouldShow) {
                            shouldShow = cond.default ? true : false;
                            setExReq({
                                enable: true,
                                path: cond.path,
                                data: { items: items },
                            });
                        }
                        break;
                    default:
                        break;
                }
            });
        }

        if (action.meta?.type !== "filterdropdown" && items.length <= 0) {
            shouldShow = false;
        }
        setShow(shouldShow);
    }, [action, items]);
    // =========================================================================
    // check conditions to display button

    if (!show) {
        return <></>;
    }
    // =========================================================================

    switch (action.meta?.type) {
        case "data_create":
            return (
                <UIButton
                    layout={action.layout}
                    onClick={() => {
                        history.push(
                            `data/${
                                action?.meta?.form_id || "form_id_not_mentioned"
                            }/create`,
                            {
                                from: history.location.pathname,
                            }
                        );
                    }}
                    loading={false}
                />
            );
        case "filterdropdown":
            return (
                <FilterDropdown
                    action={action}
                    comp_id={currentComp.id}
                    fields={fields}
                />
            );
        case "updateallbutton":
            return (
                <UpdateAllbutton
                    action={action}
                    comp_id={currentComp.id}
                    fields={fields}
                    items={items}
                />
            );
        case "buttonCreateOther":
            return (
                <ButtonCreateOther
                    action={action}
                    comp_id={currentComp.id}
                    fields={fields}
                    items={items}
                />
            );
        case "buttonExternal":
            return (
                <ButtonExternal
                    action={action}
                    comp_id={currentComp.id}
                    fields={fields}
                    items={items}
                />
            );
        default:
            return <>Invalid meta.type</>;
    }
}
