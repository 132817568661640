import cn from "classnames";
import log from "cslog";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useApps } from "../actions/app/app";
import { useLogout, useMe } from "../actions/auth";
import { PRIMARY_COLOR, TOPBAR_HEIGHT } from "../constants";
import { selectedMenuAtom } from "../states/navigation";
import { getImageURL } from "../_helpers/image";
import styles from "./styles/MainBar.module.css";
import useMedia from "react-use/lib/useMedia";
import { Popover } from "antd";
import classNames from "classnames";
import { navAtom } from "../states/qcon";

const MainBar = ({ topbar = false }) => {
    const isMobile = useMedia("(max-width: 700px)");
    const { data: meData } = useMe();
    const [selectedMenu, setSelectedMenu] = useAtom(selectedMenuAtom);
    const {
        isLoading: loadingApps,
        data: dataApps,
        isSuccess: successApps,
    } = useApps();
    const match = useRouteMatch("/:app");

    log.d(meData, "ME");

    useEffect(() => {
        if (!selectedMenu) {
            setSelectedMenu(match?.params?.app);
        }
    }, [match]);

    log.d(selectedMenu, "Selected Menu");

    return (
        <div
            className={classNames({
                [styles.wrapper]: true,
                [styles.topbar]: topbar,
            })}
            style={{
                backgroundColor: PRIMARY_COLOR,
                // backgroundColor: "darkblue",
            }}
        >
            <div className={styles.itemWrapper}>
                {loadingApps && <p>..</p>}
                {successApps &&
                    dataApps
                        .sort((a, b) => b.sort - a.sort)
                        .map((item) => {
                            return (
                                <Link to={`/${item.slug}`} key={item.slug}>
                                    <Popover
                                        content={item.name}
                                        // title={item.name}
                                        trigger="hover"
                                        placement={isMobile ? "top" : "right"}
                                    >
                                        <div
                                            className={cn({
                                                [styles.item]: true,
                                                [styles.selected]:
                                                    selectedMenu === item.slug,
                                            })}
                                            onClick={() =>
                                                setSelectedMenu(item.slug)
                                            }
                                        >
                                            <i className={item.icon}></i>
                                            {/* <span>{item.name}</span> */}
                                        </div>
                                    </Popover>
                                </Link>
                            );
                        })}
                {/* <Icon
                    component={() => <i className={`fas fa-coffee`}></i>}
                    style={{ color: "hotpink", fontSize: "20px" }}
                /> */}
            </div>
        </div>
    );
};

export default MainBar;
