import { Button, Space, Table } from "antd";
import React from "react";
import { getTime } from "../../../_helpers/app/dt";
import PayButtonPhonePe from "../../../payments/phonepe/PayButtonPhonePe";
import { Link } from "react-router-dom";
import { useIsAdmin } from "../../../actions/auth";
import log from "cslog";

function SubscriptionDetails({ plans = [] }) {
    return (
        <div>
            <h3>Subscription Details</h3>
            {/* <p>Plans: {JSON.stringify(plans)}</p> */}
            {plans.map((item) => (
                <PlanCard plan={item} />
            ))}
        </div>
    );
}

export default SubscriptionDetails;

function PlanCard({ plan }) {
    const is_admin = useIsAdmin();

    log.d(is_admin, "IS Admin");
    return (
        <div>
            <Table
                showHeader={false}
                columns={[
                    { title: "Name", dataIndex: "name", key: "name" },
                    { title: "Value", dataIndex: "value", key: "value" },
                ]}
                dataSource={[
                    {
                        key: "name",
                        name: "Current plan",
                        value: plan?.plan?.name,
                    },
                    {
                        key: "name",
                        name: "Expires on",
                        value:
                            plan.plan.duration === -1
                                ? "Never expires"
                                : `${getTime(plan?.end_date, "od")} (${getTime(
                                      plan?.end_date,
                                      "rt"
                                  )})`,
                    },
                ]}
                size="small"
                bordered={true}
                pagination={false}
            />
            {is_admin && (
                <Space style={{ marginTop: "15px" }}>
                    {plan.plan.duration === -1 ? (
                        <PayButtonPhonePe text="Upgrade Plan" amount={13} />
                    ) : (
                        <PayButtonPhonePe text="Renew Plan" amount={13} />
                    )}
                </Space>
            )}
            <div
                style={{
                    paddingTop: "15px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Link to="/privacy_policy" target="_blank">
                    Privacy Policy
                </Link>
                <Link to="/terms_and_conditions" target="_blank">
                    Terms & Conditions
                </Link>
            </div>
        </div>
    );
}
