import React, { useMemo } from "react";
import styles from "./styles/Editor.module.css";
import log from "cslog";

import { Input, Select } from "antd";
import { getFormField } from "../_parsers/form_fields";

const { Option } = Select;

function getCondFunc(conditions) {
    log.p("Calculating cond func");
    if (!conditions) {
        return (values) => false;
    }

    return (values) => {
        let match = true;
        conditions.forEach((cond) => {
            const op = cond.op;
            if (op === "_eq") {
                match = values[cond.field] !== cond.value;
            }
        });
        return match;
    };
}

export function RenderField({ field, values, setValues }) {
    const condFunc = useMemo(
        () => getCondFunc(field.conditions),
        [field.conditions]
    );
    if (condFunc(values)) {
        return null;
    }

    let fieldRender = null;
    switch (field.type) {
        case "string":
            fieldRender = (
                <Input
                    value={values[field.id]}
                    onChange={(eve) =>
                        setValues({
                            ...values,
                            [field.id]: eve.target.value,
                        })
                    }
                />
            );
            break;
        case "select":
            fieldRender = (
                <Select
                    defaultValue={values[field.id]}
                    value={values[field.id]}
                    onChange={(newValue) =>
                        setValues({
                            ...values,
                            [field.id]: newValue,
                        })
                    }
                    showSearch
                >
                    {field.params.options.map((op) => (
                        <Option value={op[0]}>{op[1]}</Option>
                    ))}
                </Select>
            );
            break;
        case "roles":
            fieldRender = getFormField({
                meta: {
                    interface: "list-o2m",
                    options: {
                        template: "Field template",
                    },
                },
                collection: "roles",
                field: "allowed_roles",
                type: "alias",
                schema: {
                    foreign_key_table: "directus_roles",
                },
            });
            break;
        default:
            fieldRender = (
                <Input
                    value={values[field.id]}
                    onChange={(eve) =>
                        setValues({
                            ...values,
                            [field.id]: eve.target.value,
                        })
                    }
                />
            );
    }
    return (
        <div className={styles.field}>
            <label>{field.label}</label>
            {fieldRender}
        </div>
    );
}
