import React, { useEffect } from "react";
import log from "cslog";

// import { GoogleLogin } from "react-google-login";
// import { gapi } from "gapi-script";

// const clientId =
//     "404662816537-peoefo2mt6tb0q07lm2gd7hit5otjja3.apps.googleusercontent.com";

function FacebookLoginButton() {
    return (
        <button
            onClick={() =>
                window.open(
                    "https://api.qureal.com/auth/login/google?redirect=http://localhost:3000/verify_auth",
                    "_self"
                )
            }
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
            }}
        >
            <i className="fab fa-facebook" />
            Facebook
        </button>
    );
}

// function GoogleLoginButton2() {
//     useEffect(() => {
//         const initClient = () => {
//             gapi.client.init({
//                 clientId: clientId,
//                 scope: "",
//             });
//         };
//         gapi.load("client:auth2", initClient);
//     });

//     const onSuccess = (res) => {
//         log.d(res, "Google login success");
//     };
//     const onFailure = (err) => {
//         log.d(err, "Google logni failed");
//     };

//     return (
//         <GoogleLogin
//             clientId={clientId}
//             buttonText="Sign in with Google"
//             onSuccess={onSuccess}
//             onFailure={onFailure}
//             cookiePolicy={"single_host_origin"}
//             isSignedIn={true}
//         />
//     );
// }

export default FacebookLoginButton;
