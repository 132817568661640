import React from "react";
import styles from "./styles/MultiFilePicker.module.css";
import formatFileSize from "../utils/filesize";
import { getMimeExtension, getMimeType } from "../utils/mimetype/mime";
import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Icon from "@ant-design/icons";

export function FileItem({ file, onDelete }) {
    return (
        <div className={styles.fileItem}>
            {/* <div className={styles.itemLeft}> */}
            <div className={styles.fileIcon}>
                {getMimeExtension(file.type).toUpperCase()}
            </div>
            <div
                style={{
                    flex: 1,
                    minWidth: 0,
                }}
            >
                <div className={styles.fileName}> {file.title}</div>
                <div className={styles.fileType}>{getMimeType(file.type)}</div>
            </div>
            {/* </div> */}
            {/* <div className={styles.itemLeft}> */}
            <div className={styles.fileSize}>
                <span>{formatFileSize(file.filesize)}</span>
            </div>
            <Button
                type="text"
                className={styles.btnDownload}
                icon={
                    <Icon
                        component={() => <i className="fas fa-download"></i>}
                    />
                }
            />
            <Button
                type="text"
                className={styles.btnDelete}
                icon={
                    <Icon component={() => <i className="fas fa-trash"></i>} />
                }
                onClick={onDelete}
            />
            {/* </div> */}
        </div>
    );
}

export function FileList({ files, setFiles }) {
    return files.length > 0 ? (
        <div className={styles.filesWrapper}>
            {files.map((file, index) => (
                <FileItem
                    file={file}
                    onDelete={() => {
                        const newFiles = [...files];
                        newFiles.splice(index, 1);
                        setFiles(newFiles);
                    }}
                />
            ))}
        </div>
    ) : (
        <></>
    );
}
