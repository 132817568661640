import React from "react";

function QModal({
    open,
    setOpen = () => {},
    setClose = () => {},
    title,
    actions,
    children,
}) {
    if (!open) {
        return <></>;
    }
    return (
        <div className="qmodal_background" onClick={setClose}>
            <div className="qmodal_main" onClick={(e) => e.stopPropagation()}>
                <div className="qtwo_col">
                    <h3>{title}</h3>
                    <h4 className="qmodal_close" onClick={setClose}>
                        X
                    </h4>
                </div>
                <div>{children}</div>
                {actions?.length > 0 && (
                    <div className="qmodal_action_wrapper">
                        {actions.map((action) => action)}
                    </div>
                )}
            </div>
        </div>
    );
}

export default QModal;
