import styles from "./styles/MyCreations.module.css";
import React from "react";
import { useItemsFine } from "../actions/app/items";
import log from "cslog";
import Loading from "../common/Loading";
import { SingleItem } from "../qmade/creator/SingleItem";
import BlankBox from "../common/BlankBox";
import ActionBox from "../common/ActionBox";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { startCase } from "lodash";
import { Link, useRouteMatch } from "react-router-dom";
import NewCard from "./NewCard";

const CREATION_MAPPING = {
    website: "Website",
    magazine: "Magazine",
    qdf: "QDF",
    singlepage: "Social Media",
    packaging_box: "Packaging",
};

const CREATION_CARD_WIDTH = {
    // all_creation: 150,
    all_creation: 200,
    website: 250,
    slider: 150,
    magazine: 150,
    qdf: 150,
    singlepage: 150,
    packaging_box: 150,
};

function MyCreations({ type }) {
    const match = useRouteMatch();
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(
        "qp_creation",
        // "fields=id,title,slug,type,preview,date_updated,collaborators.*&sort=-date_updated&filter[archived][_eq]=false&filter[user_created][_eq]=$CURRENT_USER",
        type === "all_creation" ?
            `fields=id,title,slug,type,creation_type.name,preview,date_updated,collaborators.*&sort=-date_updated&filter={"_and":[{"_or":[{"user_created":{"_eq":"$CURRENT_USER"}},{"collaborators":{"directus_users_id":{"_eq":"$CURRENT_USER"}}}]},{"archived":{"_eq":false}}]}` :
            `fields=id,title,slug,type,creation_type.name,preview,date_updated,collaborators.*&sort=-date_updated&filter={"_and":[{"_or":[{"user_created":{"_eq":"$CURRENT_USER"}},{"collaborators":{"directus_users_id":{"_eq":"$CURRENT_USER"}}}]},{"type":{"_eq":"${type}"}},{"archived":{"_eq":false}}]}`,
        true
    );

    log.d(dataItems, "MyCreations data");

    if (loadingItems) {
        return <Loading />;
    }

    return (
        <div>
            {successItems && dataItems.length > 0 && (
                <div
                    className={styles.gridWrapper}
                    style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${CREATION_CARD_WIDTH[type]}px, 1fr))`,
                    }}
                >
                    {/* <NewCard type={type} path={match.path} /> */}
                    {dataItems.map((item) => {
                        return <SingleItem key={item.id} item={item} />;
                    })}
                </div>
            )}

            {successItems && dataItems.length === 0 && (
                <ActionBox
                    icon={<i className="fas fa-globe"></i>}
                    title={`Create Your First ${CREATION_MAPPING[type]} Today`}
                    // subtitle="Create Your First Website Today"
                    content="hello world how are you. hope you are fine. hello world how are you. hope you are fine. hello world how are you. hope you are fine. hello world how are you. hope you are fine. hello world how are you. hope you are fine. "
                    action={
                        <Link to={`/creatives/new/templates?type=${type}`}>
                            <Button type="primary">
                                Create {CREATION_MAPPING[type]} Now
                                <ArrowRightOutlined />
                            </Button>
                        </Link>
                    }
                />
            )}
        </div>
    );
}

export default MyCreations;
