import React, { Fragment, useState } from "react";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import log from "cslog";
import { useUploadFile } from "../actions/files";
import { BASE_URL } from "../constants";
import { restoreAccessToken } from "../_helpers/auth";
import { getMimeExtension, getMimeType } from "../utils/mimetype/mime";
import styles from "./styles/MultiFilePicker.module.css";
import formatFileSize from "../utils/filesize";
import { FileList, FileItem } from "./FileItem";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}

function MultiFilePicker({ field, value, onChange, single = true }) {
    const [files, setFiles] = useState([]);
    const fileMutation = useUploadFile((data, variables, context) => {
        log.d(data, "Success Data");
        // setPhoto(data);
        if (single) {
            setFiles([data]);
            onChange(data.id);
        } else {
            const newFiles = [...files, data];
            setFiles(newFiles);
            const idArr = newFiles.map((ff) => {
                return {
                    directus_files_id: {
                        id: ff.id,
                    },
                };
            });
            onChange(idArr);
        }
    });

    const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        if (file) {
            if (typeof file === "object" && file !== null) {
                const formData = new FormData();
                // formData.append("title", "Some title")
                formData.append("file", file);
                fileMutation.mutate(formData);
            }
        }
    };

    log.d(files, "FILESSS");

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
            }}
        >
            <Button
                loading={fileMutation.isLoading}
                type="primary"
                ghost
                icon={<UploadOutlined />}
                onClick={(eve) => {
                    eve.preventDefault();
                    document
                        .getElementById(
                            `photo-${field.collection}-${field.field}`
                        )
                        .click();
                }}
            >
                {fileMutation.isLoading
                    ? "Uploading..."
                    : single && files.length > 0
                    ? "Change File"
                    : "Upload File"}
            </Button>

            <FileList files={files} setFiles={setFiles} />

            <input
                type="file"
                name="photo"
                onChange={onFile}
                id={`photo-${field.collection}-${field.field}`}
                hidden
            />
        </div>
    );
}

export default MultiFilePicker;

// Ant design form custom field
// https://medium.com/swlh/use-custom-and-third-party-react-form-components-with-ant-design-and-typescript-2732e7849aee
