import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSingleItem } from "./junction";
import log from "cslog";
import QFormStandard from "./QFormStandard";

const getDefaultValues = (f) => {
    if (f.type === "json") {
        const out = {};
        f.fields.forEach((ff) => {
            out[ff.name] = getDefaultValues(ff);
        });
        return out;
    } else {
        if ("default_value" in f) {
            return f["default_value"];
        }
        return null;
    }
};

function QurealFormStandard({
    id,
    record_id,
    field_overwrites,
    wrapper_style,
    configs,
    onSuccess,
    onDone,
    org,
}) {
    const {
        isLoading: loadingForm,
        isSuccess: successForm,
        data: dataForm,
    } = useSingleItem("qdb_form", `/${id}`, id !== undefined);
    const query_string = useMemo(() => {
        return makeSingleRecordQuery(dataForm, record_id);
    }, [dataForm, record_id]);
    const {
        isLoading: loadingRecord,
        isSuccess: successRecord,
        data: dataRecord,
    } = useSingleItem(
        dataForm?.table,
        query_string,
        successForm && record_id !== undefined
    );
    const [init_data, setInitData] = useState({});
    const [filled_data, setFilledData] = useState({});
    const [init, setInit] = useState(false);
    const edit = record_id !== undefined;

    // log.d(dataForm, "dataFrom in QurealFormStandard");
    // log.d(query_string, "query_string in QurelFromStarndard");
    // log.d(dataRecord, "dataRecord in QurealFromStandard");

    const field_overs = useMemo(() => {
        if (field_overwrites) {
            return { ...field_overwrites };
        } else {
            return {};
        }
    }, [field_overwrites]);

    const updated_fields = useMemo(() => {
        // log.p("Getting updating fields");
        const fovs = field_overs;
        if (dataForm?.fields) {
            return dataForm.fields.map((f) => {
                if (f.name in fovs) {
                    return { ...f, ...fovs[f.name] };
                }
                return f;
            });
        }
    }, [dataForm?.fields, field_overs]);

    useEffect(() => {
        if (successForm) {
            // log.d(dataRecord, "Incoming data");
            if (edit && successRecord) {
                log.d(dataRecord, "Data Record in edit mode");
                let refined_data = {};
                updated_fields.forEach((f) => {
                    refined_data[f.name] = refineData(f, dataRecord);
                });
                log.d(refined_data, "To be filled data");
                setInitData({ ...refined_data });
                setFilledData(refined_data);
            } else {
                log.p("Calculating initial value");
                const initialData = {};
                updated_fields.forEach((f) => {
                    const val = getDefaultValues(f);
                    if (val) {
                        initialData[f.name] = val;
                    }
                });
                setFilledData(initialData);
            }
        }
    }, [successForm, updated_fields, edit, successRecord]);

    // log.d(field_overwrites, "Field Overwrites");
    // log.d(field_overs.current, "Field Overs (ref)");
    // log.d(updated_fields, "Updated Fields");

    // log.p("Rendering QurealForm");

    // log.d(init_data, "Init data in QurealForm");

    if (loadingForm) {
        return <h4>Loading Form...</h4>;
    }
    if (successForm) {
        return (
            <QFormStandard
                form={dataForm}
                fields={updated_fields}
                record_id={record_id}
                org={org}
                init_data={init_data}
                data={filled_data}
                setData={setFilledData}
                onSuccess={onSuccess}
                onDone={onDone}
                wrapper_style={wrapper_style}
                configs={configs}
            />
        );
    }
    return <></>;
}

export default QurealFormStandard;

function makeSingleRecordQuery(form, record_id) {
    let query = "fields=";
    form?.fields.forEach((f) => {
        if (f.type === "relationM2M" || f.type === "files") {
            query = query + `${f.name}.*,`;
        } else {
            query = query + `${f.name},`;
        }
    });
    query = query.substring(0, query.length - 1);
    const out = `/${record_id}?${query}`;
    log.d(out, "Query");
    return out;
}

const ROLES_MAPPING = {
    "b35e905a-97f5-4fd4-87cc-631ecd33354b": 11, //admin
    "a4654aae-b05f-4a21-be49-af1a6fd0d4e9": 10, //manager
    "a0def5fa-b897-4fa9-b847-fd60e34acf44": 22, //qadmin
    "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f": 21, //users
    "52bb1969-8b18-4e10-8798-c614f476c306": 20, //bot
};

const ROLES_REVERSE_MAPPING = {
    11: "b35e905a-97f5-4fd4-87cc-631ecd33354b", //admin
    10: "a4654aae-b05f-4a21-be49-af1a6fd0d4e9", //manager
    22: "a0def5fa-b897-4fa9-b847-fd60e34acf44", //qadmin
    21: "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f", //users
    20: "52bb1969-8b18-4e10-8798-c614f476c306", //bot
};

function refineData(f, data) {
    if (f.type.startsWith("relation")) {
        let out = data[f.name];
        // if (f.foreign_key_table === "roles") {
        if (f.foreign_key_table === "directus_roles") {
            const val = data[f.name];
            if (Array.isArray(val)) {
                // return val.map((r) => ROLES_REVERSE_MAPPING[r]);
                out = val.map((r) => r?.directus_roles_id);
            } else {
                out = ROLES_REVERSE_MAPPING[val];
            }
        } else {
            out = data[f.name];
        }
        if (f.type === "relationM2M" && Array.isArray(out)) {
            return out.map((item) => item[f.junction_field]);
        }
        return out;
    } else if (f.type === "files") {
        const val = data[f.name];
        return val.map((item) => ({
            directus_files_id: item.directus_files_id,
        }));
    } else if (f.type === "json") {
        const jout = {};
        f.fields.forEach((ff) => {
            jout[ff.name] = refineData(ff, data[f.name]);
        });
        return jout;
    } else {
        return data[f.name];
    }
}

{
    /* <QurealForm
id={input.form}
org={ctx.organization}
// id={12}
// record_id={2}
wrapper_style={{
    background: "white",
    fontSize: input.fontSize,
    fontWeight: input.bold ? "bold" : "normal",
    textTransform: input.uppercase ? "uppercase" : "none",
    ...decodeStyle(ps.fixStyle, gs),
    ...decodeStyle(ps.style, gs),
    ...(tablet(device) && decodeStyle(tabletStyle, gs)),
    ...(mobile(device) && decodeStyle(mobileStyle, gs)),
}}
configs={{
    submitButtonText: input.submitButtonText,
    submittingButtonText: input.submittingButtonText,
    successMessage: input.successMessage,
}}
onSuccess={() =>
    performAction({
        ...input.actionOnSuccess,
        dydb: dydb,
    })
}
/> */
}
