import produce from "immer";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import FieldGroup from "./FieldGroup";
import styles from "./styles/ArrayField.module.css";
import Handlebars from "handlebars";
import log from "cslog";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { startCase } from "lodash";

export function isObjectEmpty(obj) {
    return !Object.values(obj).some((val) => val && val !== "");
}

function RenderItem({ item }) {
    return (
        <table className={styles.table}>
            {Object.keys(item).map((key) => {
                // if (item[key]) {
                return (
                    <tr>
                        <td>{startCase(key)}</td>
                        <td>{item[key]}</td>
                    </tr>
                );
                // }
            })}
        </table>
    );
}

function ModalFormGroup({
    children,
    show,
    onClose,
    fields,
    data,
    setData,
    title = "Add New Item",
}) {
    const [values, setValues] = useState(data);

    return (
        <Rodal
            visible={show}
            onClose={onClose}
            customStyles={{
                height: "auto",
                bottom: "auto",
                top: "50%",
                transform: "translateY(-50%)",
                borderRadius: "12px",
                maxHeight: "95vh",
                overflow: "auto",
            }}
        >
            <div
                style={{
                    margin: "1em",
                    marginTop: ".5em",
                }}
            >
                <h2>{title}</h2>
                <FieldGroup
                    fields={fields}
                    data={values}
                    setData={(newData) => setValues(newData)}
                />
                <button
                    type="button"
                    onClick={() => {
                        setData(values);
                        onClose();
                    }}
                    style={{
                        marginLeft: "7px",
                        marginTop: "5px",
                    }}
                >
                    Sumbit
                </button>
            </div>
        </Rodal>
    );
}

function ArrayField({ field, value: initialValue = [], onChange }) {
    const [value, setValue] = useState([]);
    const [initialData, setInitialData] = useState(null);
    const [editIndex, setEditIndex] = useState(null);

    log.d(initialValue, "ArrayField InitialValuee");
    log.d(value, "ArrayField Valuee");

    // useEffect(() => {
    //     if (initialValue) {
    //         setValue(initialValue);
    //     }
    // }, [initialValue]);

    const displayTemplate = useMemo(() => {
        return Handlebars.compile(field.item_display_template);
    }, [field.item_display_template]);

    useEffect(() => {
        const newID = {};
        field.fields.forEach((af) => {
            if ("default_value" in af) {
                newID[af.id] = af.default_value;
            }
        });
        setInitialData(newID);
    }, [field.fields]);

    const addItem = () => {
        const ci = value.length;
        setValue(
            produce(value, (draft) => {
                draft.push(initialData);
            })
        );
        setEditIndex(ci);
    };

    const deleteItem = (index) => {
        const newVal = produce(value, (draft) => {
            draft.splice(index, 1);
        });
        setValue(newVal);
        onChange(newVal);
    };

    const updateItem = (index, newData) => {
        const newVal = produce(value, (draft) => {
            draft[index] = newData;
        });
        setValue(newVal);
        onChange(newVal);
    };

    return (
        <Fragment>
            <label>{field.label}</label>
            <button onClick={addItem} type="button">
                {field.add_btn_label || "Add Item"}
            </button>
            <div className={styles.listWrapper}>
                {value.map((itemData, index) => {
                    log.d(itemData, "Next ItemData");
                    const isEmpty = isObjectEmpty(itemData);

                    return (
                        <div>
                            {!isEmpty && (
                                <div
                                    className={styles.listItem}
                                    onClick={() => {
                                        if (editIndex === index) {
                                            setEditIndex(null);
                                        } else {
                                            setEditIndex(index);
                                        }
                                    }}
                                >
                                    {/* <div>{displayTemplate(itemData)}</div> */}
                                    {/* <div
                                    dangerouslySetInnerHTML={{
                                        __html: displayTemplate(itemData),
                                    }}
                                /> */}
                                    <RenderItem item={itemData} />
                                    <div className={styles.actionsWrapper}>
                                        <i className="fas fa-edit" />
                                        <i
                                            className="fas fa-times"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteItem(index);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {editIndex === index && (
                                <ModalFormGroup
                                    show={editIndex === index}
                                    onClose={() => setEditIndex(null)}
                                    // fields={field.array_fields}
                                    fields={field.fields}
                                    data={itemData}
                                    setData={(newData) =>
                                        updateItem(index, newData)
                                    }
                                    title={field.add_btn_label}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
}

export default ArrayField;
