import React from "react";
import { Layout, Spin } from "antd";
import QurealSection from "./QurealSection";

const { Content } = Layout;

function Loading({ text }) {
    return (
        // <Content
        //     style={{
        //         padding: "16px",
        //     }}
        // >
        <QurealSection>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "75px",
                    paddingBottom: "75px",
                    // border: "1px dashed blue",
                }}
            >
                <Spin />
            </div>
        </QurealSection>
    );
}

export default Loading;
