import React from "react";
import { useFieldsDirect } from "../../actions/app/fields";
import ItemForm from "../formview/ItemForm";

function PortableCreateView({
    collection,
    fullWidth = false,
    layoutStyle = "horizontal",
}) {
    const {
        data: dataFields,
        isLoading: loadingFields,
        isSuccess: successFields,
    } = useFieldsDirect(collection);

    return (
        <>
            {successFields && (
                <ItemForm
                    currentComp={{
                        data: {
                            collection: collection,
                        },
                        layout: {
                            style: layoutStyle,
                            size: "default",
                        },
                    }}
                    fullWidth={fullWidth}
                    fields={dataFields}
                    onDone="reset"
                />
            )}
        </>
    );
}

export default PortableCreateView;
