import React from "react";
import styles from "./styles/GridView.module.css";
import { BASE_URL } from "../../../constants";
import { restoreAccessToken } from "../../../_helpers/auth";
import profile_placeholder from "../../../media/imgs/avatar.jpg";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}

// function Card({ title, subtitle, imgId }) {
function Card({ item }) {
    const { title, subtitle, imageSource } = item;
    return (
        <div className={styles.card}>
            {/* {imageSource && ( */}
            <div
                className={styles.square_img}
                style={{
                    backgroundImage: `url(${
                        imageSource
                            ? getImageURL(imageSource)
                            : profile_placeholder
                    })`,
                    // backgroundImage: `url(${profile_placeholder})`,
                }}
            />
            {/* )} */}
            <h3
                className={styles.title}
                dangerouslySetInnerHTML={{
                    __html: title ? title : "Full Name",
                }}
            ></h3>
            <div
                className={styles.subtitle}
                dangerouslySetInnerHTML={{ __html: subtitle }}
            ></div>
        </div>
    );
}

export default Card;
