import styles from "./styles/Home.module.css";
import { Layout } from "antd";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { useApps } from "../actions/app/app";
import Editor from "../editor/Editor";
import MainBar from "./MainBar";
import QurealApp from "../schema/QurealApp";
import { selectedMenuAtom } from "../states/navigation";
import QurealAppSingleScreen from "../schema/QurealAppSingleScreen";
import TopBar from "../common/TopBar";
import { TOPBAR_HEIGHT } from "../constants";
import { useCurrentUser } from "../actions/auth";
import useMedia from "react-use/lib/useMedia";
import log from "cslog";
import { useSelecteOrgMutation } from "../actions/org";

const Home = () => {
    const isMobile = useMedia("(max-width: 700px)");
    const { isLoading, isSuccess, data: dataMe } = useCurrentUser();
    const selectOrgMutation = useSelecteOrgMutation();

    const {
        isLoading: loadingApps,
        data: dataApps,
        isSuccess: successApps,
    } = useApps();

    const defaultApp = dataApps
        ? dataApps.sort((a, b) => b.sort - a.sort)[0]
        : null;

    useEffect(() => {
        if (isSuccess && dataMe?.organizations?.length > 0) {
            const all_orgs = dataMe?.organizations;
            const selected_org = dataMe?.selected_org;
            log.d(selected_org, "Updating selected org");
            if (!selected_org && selectOrgMutation.isIdle) {
                log.p("Selecting default org now");
                selectOrgMutation.mutate(
                    all_orgs?.[0]?.qdb_organization_id?.id
                );
            }
        }
    }, [isSuccess, dataMe, selectOrgMutation]);

    if (isSuccess && dataMe?.organizations?.length === 0) {
        return (
            <Redirect
                to={{
                    pathname: "/setup_org",
                }}
            />
        );
    }

    return (
        <div className={styles.page_wrapper}>
            <TopBar me={dataMe} />
            <div className={styles.wrapper}>
                {!isMobile && <MainBar topbar={false} />}

                <div className={styles.app_area}>
                    {true && (
                        <Switch>
                            {defaultApp && (
                                <Redirect
                                    exact
                                    from={`/`}
                                    to={`/${defaultApp.slug}`}
                                />
                            )}

                            {successApps &&
                                dataApps.map((item) => {
                                    return (
                                        <Route
                                            key={item.slug}
                                            path={`/${item.slug}`}
                                            component={() =>
                                                item.layout?.type ===
                                                "single" ? (
                                                    <QurealAppSingleScreen
                                                        app={item}
                                                    />
                                                ) : (
                                                    <QurealApp
                                                        app={item}
                                                        me={dataMe}
                                                    />
                                                )
                                            }
                                        />
                                    );
                                })}
                        </Switch>
                    )}
                </div>
                {isMobile && <MainBar topbar={true} />}
            </div>
        </div>
    );
};

export default Home;
