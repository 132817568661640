import React from 'react'
import styles from "./styles/NewCard.module.css"
import classNames from 'classnames';
import { getCreateURL } from '../_helpers/editor';
import { Tooltip } from 'antd';
import { Link, useRouteMatch } from "react-router-dom";

const CREATION_TYPES = {
    website: {
        id: "website",
        name: "Website",
        img: "/creation_faces/qdf_min_trans.png",
    },
    singlepage: {
        id: "singlepage",
        name: "Pamphlet",
        img: "/creation_faces/card_min.jpg",
    },
    slider: {
        id: "slider",
        name: "Slider",
        img: "/creation_faces/slider_min_trans.png",
    },
    magazine: {
        id: "magazine",
        name: "Magazine",
        img: "/creation_faces/magazine_min.jpg",
    },
    qdf: {
        id: "qdf",
        name: "QDF",
        img: "/creation_faces/qdf_min_trans.png",
    },
    card: {
        id: "card",
        name: "Card",
        img: "/creation_faces/card_min.jpg",
    },
    packaging_box: {
        id: "packaging_box",
        name: "Box Packaging",
        img: "/creation_faces/card_min.jpg",
    },
}

function NewCard({ type, path }) {
    const item = CREATION_TYPES[type]

    return (
        <div className={styles.gridItem} key={item.id}>
            <div className={styles.gridItemImg}>
                {/* <img
                    className={styles.giImg}
                    //  src={sample_img}
                    src={process.env.PUBLIC_URL + item.img}
                    alt="img"
                    style={{
                        objectFit: "cover",
                    }}
                /> */}
                <div
                    className={classNames(styles.giBackImg, "pan_h")}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + item.img
                            })`,
                    }}
                ></div>
                <div className={styles.giOverley}>
                    <Tooltip title="Create New">
                        <a
                            // href={`${CREATOR_URL}new?type=${item.id}`}
                            href={getCreateURL(item.id)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className={styles.giBtn}>
                                <i className="fas fa-plus"></i>
                            </button>
                        </a>
                    </Tooltip>
                    <Tooltip title="Browse Template">
                        {/* <Link to={`${path}/templates?type=${item.id}`}> */}
                        <Link to={`/creatives/new/templates?type=${item.id}`}>
                            <button className={styles.giBtn}>
                                <i className="fas fa-search"></i>
                            </button>
                        </Link>
                    </Tooltip>
                </div>
            </div>
            <h2 className={styles.gridItemTitle}>Create New {item.name}</h2>
        </div>
    );
}

export default NewCard;