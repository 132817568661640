import React from "react";

const Alert = ({ type, message, bold = true }) => {
    let col = "blue";
    let icon = "check";
    if (type === "success") {
        col = "green";
    } else if (type === "error") {
        col = "red";
        icon = "times";
    } else if (type === "warning") {
        col = "yellow";
        icon = "exclamation";
    }

    return (
        <div
            style={{
                padding: "16px 0px",
                color: col,
                fontSize: "1rem",
                fontWeight: bold ? "bold" : "normal",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
            }}
        >
            <i className={`fas fa-${icon}`}></i> {message}
        </div>
    );
};

export default Alert;
