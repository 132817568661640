import React, { useMemo, useState } from "react";
import PaymentManager from "./PaymentManager";
import { useItemsFine } from "../actions/app/items";
import { useCurrentUser } from "../actions/auth";
import { getTime } from "../_helpers/app/dt";

function DonationCard({ amount = 13 }) {
    const { isLoading, isSuccess, data, refetch } = useItemsFine(
        "qd_donations",
        `filter[user][_eq]=$CURRENT_USER`,
        true
    );

    return (
        <div
            style={{
                marginBottom: "20px",
                padding: "15px",
                border: "1px solid #ccc",
            }}
        >
            <h3>Donations</h3>
            {isSuccess && data.length === 0 && (
                <p>You've not donated so far here.</p>
            )}
            {isSuccess && data?.length > 0 && (
                <div>
                    {data.map((item) => {
                        return <div>Next donation amount: {item.amount}</div>;
                    })}
                </div>
            )}

            <PaymentManager
                order_type="donation"
                order_data={{
                    amount: 33,
                    remark: "test donation",
                }}
                amount={33}
                onSuccess={() => {
                    refetch();
                    setTimeout(refetch, 1000);
                    setTimeout(refetch, 2000);
                }}
                onFailed={() => {}}
                trigger={<button className="qbtn">Donate</button>}
            />
        </div>
    );
}

export default DonationCard;

// razorpay key, secret (shivam301296)
// rzp_test_oOQeCHJoMaCTwj
// 6WQQ4KEJNJ9TFD0rfLm6Jkwt
