import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { externalAxios } from "../../_helpers/auth";
import { queryClient } from "../../_helpers/query";
import log from "cslog";

export const useExternal = (enabled, path, data) => {
    return useQuery(
        ["external", path],
        () =>
            externalAxios.post(path, data, {
                "Content-Type": "application/json",
            }),
        {
            enabled: enabled,
            refetchOnMount: true,
        }
    );
};

export const useExternalMutation = () => {
    return useMutation(
        (data) => {
            switch (data.method) {
                case "post":
                    return externalAxios.post(data.path, data.data, {
                        "Content-Type": "application/json",
                    });
                default:
                    return externalAxios.post(data.path, data.data);
            }
        },
        {
            onSuccess: (data, variables) => {
                variables.refresh.forEach((reff) => {
                    log.d(reff, "Invalidating");
                    queryClient.invalidateQueries(reff);
                });
            },
        }
    );
};
