import React from "react";
// import "../qui/main.css";
// import "../qui/forms.css";
// import "../qui/form_thems.css";
// import Select from "react-select";
import classNames from "classnames";
import log from "cslog";
import { useCreateItem, useUpdateItem } from "../actions/mutations/items";
import QField from "./QField";
import { getFormField } from "../_parsers/form_fields";
import QFieldStandard from "./QFieldStandard";
import { Button, Form } from "antd";

export const FIELDS = [
    {
        id: "name",
        label: "App Name",
        type: "text",
        max_length: 20,
        default_value: "",
        required: true,
        readonly: false,
        hidden: false,
    },
    {
        id: "slug",
        label: "Slug",
        type: "text",
        max_length: 20,
        default_value: "",
        required: true,
        readonly: false,
        hidden: false,
    },
    {
        id: "icon",
        label: "Icon",
        type: "text",
        max_length: 20,
        default_value: "",
        required: true,
        readonly: false,
        hidden: false,
    },
    {
        id: "layout_type",
        label: "Layout Type",
        type: "radio",
        default_value: "single",
        options: [
            {
                text: "Single Screen",
                value: "single",
            },
            {
                text: "Multi Screen",
                value: "multiscreen",
            },
        ],
    },
    {
        id: "layout_navigation",
        label: "Layout Navigation",
        type: "radio",
        default_value: "sidebar",
        options: [
            {
                text: "Sidebar",
                value: "sidebar",
            },
            {
                text: "Topbar",
                value: "topbar",
            },
        ],
        conditions: [
            {
                name: "Hide in single",
                rules: {
                    layout_type: {
                        _eq: "single",
                    },
                },
                action: {
                    hidden: true,
                },
            },
        ],
    },
    {
        id: "layout_theme",
        label: "Layout Theme",
        type: "radio",
        default_value: "dark",
        options: [
            {
                text: "Dark",
                value: "dark",
            },
            {
                text: "Light",
                value: "light",
            },
        ],
        conditions: [
            {
                name: "Hide in single",
                rules: {
                    layout_type: {
                        _eq: "single",
                    },
                },
                action: {
                    hidden: true,
                },
            },
        ],
    },
    {
        id: "allowed_roles",
        label: "Allowed Roles",
        type: "relation-o2m",
        relation: {
            type: "o2m",
            other_table: "roles",
            template: "{{name}}",
        },
        default_value: [],
    },
];

export const FIELDS_ORIGINAL = [
    {
        id: "first_name",
        label: "First Name",
        type: "text",
        max_length: 20,
        default_value: "",
        required: true,
        readonly: false,
        hidden: false,
    },
    {
        id: "addresses",
        label: "Addresses",
        type: "array",
        default_value: [],
        add_btn_label: "Add New Address",
        array_fields: [
            {
                id: "street_1",
                label: "Street 1",
                type: "text",
                max_length: 30,
                required: true,
            },
            {
                id: "city",
                label: "City",
                type: "text",
                required: true,
            },
            {
                id: "pincode",
                label: "Pin Code",
                type: "number",
            },
            {
                id: "country",
                label: "Native Country",
                type: "lookup-many",
                lookup: {
                    type: "Country",
                    record_type: "standard",
                    sort: "name",
                    template: "{{name}}",
                },
                required: true,
                default_value: [],
            },
        ],
        item_display_template: "Next Item: {{street_1}}, {{city}}, {{pincode}}",
    },
    {
        id: "gender",
        label: "Gender",
        type: "radio",
        default_value: "male",
        options: [
            {
                text: "Male",
                value: "male",
            },
            {
                text: "Female",
                value: "female",
            },
            {
                text: "Other",
                value: "other",
            },
        ],
    },
    {
        id: "accept_terms_conditions",
        label: "I accepts terms and conditions.",
        type: "checkbox-single",
        default_value: false,
    },
    {
        id: "user",
        label: "Speaker",
        type: "relation-m2o",
        relation: {
            type: "o2m",
            other_table: "qform_contact",
            template: "{{full_name}}",
        },
        conditions: [
            {
                name: "Hide from females",
                rules: {
                    gender: {
                        _eq: "female",
                    },
                },
                action: {
                    hidden: true,
                },
            },
        ],
    },
    {
        id: "users",
        label: "User Many",
        type: "relation-o2m",
        relation: {
            type: "o2m",
            other_table: "qform_contact",
            template: "{{full_name}}",
        },
        default_value: [],
    },
    {
        id: "allowed_roles",
        label: "Allowed Roles",
        type: "relation-o2m",
        relation: {
            type: "o2m",
            other_table: "roles",
            template: "{{name}}",
        },
        default_value: [],
    },
    {
        id: "technologies",
        label: "Many Technology",
        type: "select-multiple",
        options: [
            {
                text: "Python",
                value: "python",
            },
            {
                text: "Javascript",
                value: "javascript",
            },
            {
                text: "Java",
                value: "java",
            },
            {
                text: "Rust",
                value: "rust",
            },
            {
                text: "HTML",
                value: "html",
            },
        ],
        default_value: [],
    },
    {
        id: "technology",
        label: "Technology",
        type: "select",
        options: [
            {
                text: "Python",
                value: "python",
            },
            {
                text: "Javascript",
                value: "javascript",
            },
            {
                text: "Java",
                value: "java",
            },
        ],
    },
    {
        id: "age",
        label: "Age",
        type: "number",
        max: 150,
        min: 0,
        required: false,
        readonly: false,
        hidden: false,
        conditions: [
            {
                name: "Make optional for females",
                rules: {
                    _or: [
                        {
                            gender: {
                                _eq: "male",
                            },
                        },
                        {
                            gender: {
                                _eq: "other",
                            },
                        },
                    ],
                },
                action: {
                    required: true,
                },
            },
        ],
    },
];

const encodeRecord = (template, data) => {
    log.d(template, "Tempalte now");
    log.d(data, "Data now");
    if (typeof template === "object") {
        const out = {};
        Object.entries(template).forEach((row) => {
            out[row[0]] = encodeRecord(row[1], data);
        });
        log.d(out, "Out now");
        return out;
    } else {
        log.d(data[template], "Out noww");
        return data[template];
    }
};

const ROLES_MAPPING = {
    "b35e905a-97f5-4fd4-87cc-631ecd33354b": 11, //admin
    "a4654aae-b05f-4a21-be49-af1a6fd0d4e9": 10, //manager
    "a0def5fa-b897-4fa9-b847-fd60e34acf44": 22, //qadmin
    "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f": 21, //users
    "52bb1969-8b18-4e10-8798-c614f476c306": 20, //bot
};

function refineData(f, data) {
    let out = {};
    if (f.type.startsWith("relation")) {
        if (f.foreign_key_table === "roles") {
            const val = data[f.id];
            if (Array.isArray(val)) {
                out[f.name] = val.map((r) => ROLES_MAPPING[r]);
            } else {
                out[f.name] = ROLES_MAPPING[val];
            }
        } else {
            out[f.name] = data[f.name];
        }
    } else if (f.type === "json") {
        f.fields.forEach((ff) => {
            out = {
                ...out,
                ...refineData(ff, data),
            };
        });
    } else {
        out[f.name] = data[f.name];
    }
    return out;
}

function QFormStandard({ form, fields, record_id, data, setData }) {
    const createMutation = useCreateItem(form.table);
    const updateMutation = useUpdateItem(form.table, record_id);

    log.d(fields, "Form Fields");
    log.d(data, "Form data");

    const submitForm = (eve) => {
        eve.preventDefault();
        log.p("Submitting form");
        log.d(data, "Form Data");

        let refined_data = {};
        fields.forEach((f) => {
            refined_data = {
                ...refined_data,
                ...refineData(f, data),
            };
        });

        let out = {};
        if (form.action?.out) {
            out = encodeRecord(form.action?.out, refined_data);
        } else {
            out = refined_data;
        }
        log.d(out, "OUT");

        // createMutation.mutate(out);
        if (record_id) {
            updateMutation.mutate(out);
        } else {
            createMutation.mutate(out);
        }
    };

    const onFinish = (values) => {
        console.log("Success:", values);
    };

    return (
        <Form
            layout="vertical"
            style={{
                maxWidth: "900px",
            }}
            onFinish={onFinish}
        >
            {/* <form
                className={classNames("qui-form", "qf-deafult")}
                onSubmit={submitForm}
            > */}
            {fields.map((f) => {
                return (
                    <QFieldStandard
                        // <QField
                        key={f.name}
                        field={f}
                        value={data[f.name]}
                        setValue={(newVal) =>
                            setData({
                                ...data,
                                [f.name]: newVal,
                            })
                        }
                        formData={data}
                        setFormData={setData}
                    />
                );
            })}
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
            {/* <div className="qfield">
                <label></label>
                <button
                    type="submit"
                    style={{
                        justifySelf: "start",
                    }}
                    status={
                        createMutation.isLoading || updateMutation.isLoading
                            ? "loading"
                            : "idle"
                    }
                >
                    {createMutation.isLoading || updateMutation.isLoading
                        ? "Submitting form.."
                        : "Submit"}
                </button>
            </div> */}
            <div className="msg" type="error">
                This is sample error
            </div>
            <div className="msg" type="info">
                This is sample info
            </div>
            <div className="msg" type="success">
                This is sample success
            </div>
            {/* </form> */}
        </Form>
    );
}

export default QFormStandard;
