import React from "react";
import { Card, Layout } from "antd";
import styles from "./styles/CenterCard.module.css";
import { LOGIN_BG } from "../constants";

const CenterCard = ({ title, logo, children, width = 350, center = true }) => {
    const bg_style = {
        // background: `url('${process.env.PUBLIC_URL}/back.jpg')`,
        background: LOGIN_BG,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    };
    return (
        <Layout
            className={styles.wrapper}
            style={
                center
                    ? { ...bg_style }
                    : {
                          justifyContent: "flex-start",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          ...bg_style,
                      }
            }
        >
            {/* {logo && <img src={logo} alt="logo" className={styles.logo} />} */}
            <Card
                className={styles.card}
                bordered={false}
                style={{ width, borderRadius: "12px" }}
            >
                {/* <h1>LOGO</h1> */}

                {logo && (
                    <div className={styles.logo_inside_wrapper}>
                        <img
                            src={logo}
                            alt="logo"
                            className={styles.logo_inside}
                        />
                    </div>
                )}
                <h2 className={styles.heading}>{title}</h2>
                {children}
            </Card>
        </Layout>
    );
};

export default CenterCard;
