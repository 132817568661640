import React from "react";

function MultiColumnView() {
    return (
        <div>
            <h4>Multi Column View</h4>
        </div>
    );
}

export default MultiColumnView;
