import React, { useState } from "react";
import "./styles/DateTime.module.css";

export default function DateTime({ field, value, onChange }) {
    const onSetDate = (event) => {
        console.log(event.target.value);
        onChange(new Date(event.target.value).toISOString());
    };

    return (
        <input
            className="datetimestyle"
            type="date"
            // min="1997-01-01"
            // max="2030-12-31"
            onChange={onSetDate}
            value={value === undefined ? {} : value.slice(0, 10)}
        />
    );
}
