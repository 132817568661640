import { getFieldRender } from "./fields";
import ReactDOMServer from "react-dom/server";
import log from "cslog";

export const getItemRenderer = (fields, all_fields) => {
    const field_renderers = {};
    fields.forEach((field_name) => {
        let field = all_fields.find((fi) => fi.field === field_name);
        // if (
        //     !field.schema?.foreign_key_column &&
        //     field.meta.display !== "image"
        // ) {
        //     field_renderers[field_name] = getFieldRender(field);
        // }
        field_renderers[field_name] = getFieldRender(field);
    });
    return field_renderers;
};

export const renderItem = (item_renderer, item, deep = false) => {
    const rendered_item = {};
    Object.keys(item).forEach((key) => {
        if (key in item_renderer) {
            const renderFunc = item_renderer[key];
            if (Array.isArray(item[key])) {
                log.d(item[key], "Profile: Arr found");
                rendered_item[key] = item[key].map((it) =>
                    ReactDOMServer.renderToStaticMarkup(renderFunc(it))
                );
            } else {
                rendered_item[key] = ReactDOMServer.renderToStaticMarkup(
                    renderFunc(item[key], item)
                );
            }
        } else {
            // rendered_item[key] = item[key];
        }

        //for deep
        if (deep && typeof item[key] === "object") {
            rendered_item[`$_${key}`] = item[key];
        }
    });
    return rendered_item;
};
