import { PlusOutlined } from "@ant-design/icons";
import {
    Checkbox,
    ColorPicker,
    Form,
    Input,
    InputNumber,
    Select,
    Upload,
} from "antd";
import log from "cslog";
import produce from "immer";
import React, { useMemo } from "react";
// import "../qui/forms.css";
import { getValidationFunc } from "../utils/conditions";
import ArrayField from "./elements/ArrayField";
import DateTime from "./elements/DateTime";
import JSONField from "./elements/JSONField";
import LookupField from "./elements/LookupField";
import RelationFieldStanard from "./standard_elements/RelationFieldStandard";
import PhotoButton from "./standard_elements/PhotoButton";

const { Option } = Select;
const { TextArea } = Input;

function QFieldStandard({ field, value, setValue, formData, setFormData }) {
    let f = { ...field };

    log.d(value, field.name);

    const condFuncs = useMemo(() => {
        if ("conditions" in field) {
            const condFuncs = [];
            field.conditions.forEach((cond) => {
                const condFunc = getValidationFunc(cond.rules);
                condFuncs.push({
                    func: condFunc,
                    action: cond.action,
                });
            });
            return condFuncs;
        }
        return [];
    }, [field]);

    condFuncs.forEach((cf) => {
        if (cf.func(formData)) {
            f = {
                ...f,
                ...cf.action,
            };
        }
    });

    if (f.hidden) {
        return null;
    }

    const style = {
        // width: `${((f.width || 12) / 12) * 100}%`,
        width: `calc(${((f.width || 12) / 12) * 100}% - 10px)`,
    };

    let element = undefined;

    switch (f.type) {
        case "text":
            element = <Input disabled={f.readonly} />;
            break;
        case "number":
            element = (
                <InputNumber
                    placeholder="Enter Value"
                    disabled={f.readonly}
                    style={{ width: "200px" }}
                />
            );
            break;
        case "textarea":
        case "richtext":
        case "markdown":
            element = <TextArea rows={4} />;
            break;
        case "tags":
            return <div>Tags</div>;
        case "dropdown":
            log.d(value, `Dropdown ${f.name} value`);
            element = (
                <Select>
                    {f.options.map((item) => {
                        return (
                            <Option value={item.value} key={item.value}>
                                {item.text}
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "dropdown-multiple":
            element = (
                <Select mode="multiple" allowClear>
                    {f.options.map((item) => {
                        return (
                            <Option value={item.value} key={item.value}>
                                {item.text} Nice
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "relationM2O":
            element = <RelationFieldStanard field={f} many={false} />;
            break;
        // case "relation-o2m":
        case "relationO2M":
            element = <RelationFieldStanard field={f} many={true} />;
            break;
        case "relationM2M":
            element = <RelationFieldStanard field={f} many={true} />;
            break;
        case "image":
            element = <PhotoButton field={field} />;
            // element = (
            //     <Upload action="/upload.do" listType="picture-card">
            //         <div>
            //             <PlusOutlined />
            //             <div
            //                 style={{
            //                     marginTop: 8,
            //                 }}
            //             >
            //                 Upload
            //             </div>
            //         </div>
            //     </Upload>
            // );
            break;
        case "color":
            element = <ColorPicker />;
            break;
        case "boolean":
        case "checkbox":
            return (
                <Form.Item key={f.name} name={f.name} valuePropName="checked">
                    <Checkbox>{f.label}</Checkbox>
                </Form.Item>
            );
        case "checkbox-multiple":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    {f.options.map((op) => {
                        return (
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name={op.value}
                                    checked={value.includes(op.value)}
                                    onClick={() => {
                                        if (value.includes(op.value)) {
                                            setValue(
                                                produce(value, (df) => {
                                                    const ci = df.indexOf(
                                                        op.value
                                                    );
                                                    df.splice(ci, 1);
                                                })
                                            );
                                        } else {
                                            setValue(
                                                produce(value, (df) => {
                                                    df.push(op.value);
                                                })
                                            );
                                        }
                                    }}
                                    onChange={() => {}}
                                />
                                {op.text}
                            </label>
                        );
                    })}
                </div>
            );
        case "checkbox-tree":
            return <div>Checkbox Tree</div>;
        case "datetime":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <DateTime field={f} value={value} onChange={setValue} />
                </div>
            );
        case "radio":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <label>{f.label}</label>
                    <div className="qh">
                        {f.options.map((option) => {
                            return (
                                <div className="qradio" key={option.value}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name={f.id}
                                        value={option.value}
                                        checked={value === option.value}
                                        onClick={() => setValue(option.value)}
                                        onChange={() => {}}
                                    />
                                    <label htmlFor={option.value}>
                                        {option.text}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        case "code":
            return <div>Code</div>;
        case "icon-picker":
            return <div>Icon Picker</div>;
        case "slider":
            return <div>Slider</div>;
        case "repeater":
            return (
                <div className="qfield" key={f.id} style={style}>
                    <ArrayField field={f} value={value} onChange={setValue} />
                </div>
            );
        case "lookup":
            return (
                <div className="qfield" key={f.id}>
                    <label>{f.label}</label>
                    <LookupField
                        field={f}
                        many={false}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "lookup-many":
            return (
                <div className="qfield" key={f.id}>
                    <label>{f.label}</label>
                    <LookupField
                        field={f}
                        many={true}
                        value={value}
                        onChange={setValue}
                    />
                </div>
            );
        case "json":
            return (
                <JSONField
                    field={f}
                    formData={formData}
                    // setFormData={setFormData}
                    value={value}
                    setValue={setValue}
                />
            );
        case "submit":
            return (
                <div className="qfield" key={f.id}>
                    <></>
                    <button type="submit">{f.label || "Submit"}</button>
                </div>
            );
        default:
            return null;
    }

    return (
        <Form.Item
            key={f.name}
            name={f.name}
            label={f.label}
            labelAlign="left"
            // rules={rules}
        >
            {element}
        </Form.Item>
    );
}

export default QFieldStandard;
