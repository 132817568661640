import { useQuery } from "react-query";
import { secureAxios } from "../_helpers/auth";

export const useCollections = (enabled = true) => {
    return useQuery(
        "collections",
        () => secureAxios.get("/collections").then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
            enabled: enabled,
        }
    );
};

export const useUserCollections = () => {
    const query = useCollections();

    return {
        ...query,
        data: query.data?.filter(
            (item) =>
                !item.collection.startsWith("directus_") &&
                !item.collection.startsWith("qureal_") &&
                !item.meta?.hidden
        ),
    };
};

export const useCollection = (collection) => {
    const query = useCollections(collection !== null);

    let data = query.data?.find((item) => item.collection === collection);

    return {
        ...query,
        data: data,
    };
};
