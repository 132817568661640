import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Table } from 'antd';
import { useItemsFine } from '../../../actions/app/items';
import { getTime } from '../../../_helpers/app/dt';
import log from "cslog"
import { startCase } from 'lodash';
import produce from 'immer';

const CollectedData = ({ creation }) => {
    const { isLoading, isSuccess, data, error } = useItemsFine(
        "qdb_filled_data",
        `filter[creation][_eq]=${creation?.id}&sort=-date_created`,
        true,
        false
    );
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);


    useEffect(() => {
        if (isSuccess) {
            const sample = data.slice(0, data.length > 4 ? 4 : data.length);
            const temp_col = new Set();

            if (sample.length > 0) {
                sample.forEach((item) => {
                    Object.keys(item.data).forEach((block_id) => {
                        Object.keys(item.data[block_id]?.value).forEach((field_id) => {
                            const block_data = item.data[block_id];
                            temp_col.add(JSON.stringify([block_id, field_id, block_data?.name]));
                        }
                        );
                    });
                });
            }
            let new_cols = {
                date_created: {
                    title: 'Date Created',
                    dataIndex: 'date_created',
                    key: 'date_created',
                    render: (dt) => getTime(dt, "d"),
                }
            }
            temp_col.values().forEach((item) => {
                const [block_id, field_id, block_name] = JSON.parse(item);
                const id = `${block_id}.${field_id}`;
                new_cols = produce(new_cols, (draft) => {
                    if (!draft[block_id]) {
                        draft[block_id] = { title: block_name, children: [] };
                    }
                    draft[block_id].children.push({
                        title: startCase(field_id),
                        dataIndex: id,
                        key: id,
                    });
                });
            });
            setColumns(Object.values(new_cols));
            const new_data = data.map((item) => {
                const new_item = { id: item.id, date_created: item.date_created };
                Object.keys(item.data).forEach((block_id) => {
                    Object.keys(item.data[block_id]?.value).forEach((field_id) => {
                        const id = `${block_id}.${field_id}`;
                        new_item[id] = item.data[block_id]?.value[field_id];
                    });
                });
                return new_item;
            }
            );
            setDataSource(new_data);
        }
    }, [isSuccess, data])


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (isSuccess && data.length === 0) {
        return <div style={{
            textAlign: "center",
            padding: "10px",
            color: "gray",
            fontSize: "1.2em",
            border: "1px solid #e8e8e8",
        }}>No data collected yet.</div>
    }


    return <Table dataSource={dataSource} columns={columns} size="small"
        bordered={true}
        pagination={{ position: ["bottomLeft"] }} />;
};

export default CollectedData;