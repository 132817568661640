import React, { useEffect, useState } from "react";
// import { useItemsFine } from "../../actions/items";
import { useItemsFine } from "../junction";
import Handlebars from "handlebars/dist/cjs/handlebars";
import Dropdown from "./Dropdown";
import MultiDropown from "./MultiDropdown";

function LookupField({ field, value, onChange, many = false }) {
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(
        "qdb_lookup",
        `filter[lookup][name]=${field.lookup.type}&filter[record_type]=${field.lookup.record_type}&sort=${field.lookup.sort}`,
        field.lookup.type !== undefined
    );
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (dataItems) {
            const option_template = Handlebars.compile(field.lookup.template);

            const newOptions = [];
            dataItems.forEach((item) => {
                newOptions.push({
                    label: option_template(item),
                    value: item.id,
                });
            });
            setOptions(newOptions);
        }
    }, [dataItems]);

    if (many) {
        return (
            <MultiDropown options={options} value={value} onChange={onChange} />
        );
    }
    return <Dropdown options={options} value={value} onChange={onChange} />;
}

export default LookupField;
