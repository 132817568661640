import styles from "./styles/CreativeNew.module.css";
import React, { useEffect, useState } from "react";
import sample_img from "../../media/imgs/avatar.jpg";
import { Tooltip } from "antd";
import { BASE_URL, CREATOR_BASE_URL } from "../../constants";
import {
    useCreateItemMutation,
    useDeleteItemMutation,
    useItemsFine,
    useSingleItem,
} from "../../actions/app/items";
import log from "cslog";
// import { getImageURL } from "../../_helpers/image";
import { nanoid } from "nanoid";

import { CREATION_TYPES } from "../../creation_constants";
import { Redirect, Route, useLocation } from "react-router-dom";
import AvatarPhoto from "../../media/imgs/avatar2.jpeg";
import { restoreAccessToken } from "../../_helpers/auth";
import classNames from "classnames";
import { useMe } from "../../actions/auth";
import cloneDeep from "lodash/cloneDeep";
import { getCreateURL, getEditURL } from "../../_helpers/editor";
import QurealSection from "../../common/QurealSection";
import SectionHeader from "../../components/commons/SectionHeader";
import PreviewTemplate from "./PreviewTemplate";

const CREATOR_URL = CREATOR_BASE_URL;

export function getImageURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}?key=system-medium-contain&access_token=${restoreAccessToken()}`;
    } else {
        return AvatarPhoto;
    }
}

const BlankItem = ({ title, link, height = 310 }) => {
    return (
        <div className={styles.gridItem} key={title}>
            <div
                className={styles.gridItemImgV}
                style={{
                    height: height,
                }}
            >
                {/* <img
                    className={styles.giImg}
                    src={getImageURL(item.preview)}
                    alt="img"
                /> */}
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <i
                        className="fas fa-plus"
                        style={{
                            color: "white",
                            fontSize: "4rem",
                        }}
                    ></i>
                </div>
                <a href={link} target="_blank" rel="noreferrer">
                    <div className={styles.giOverley}>
                        <Tooltip title={title}>
                            <i
                                className="fas fa-plus"
                                style={{
                                    color: "white",
                                    fontSize: "4rem",
                                }}
                            ></i>
                        </Tooltip>
                    </div>
                </a>
            </div>
            <Tooltip title={title}>
                <h2 className={styles.gridItemTitle}>{title}</h2>
            </Tooltip>
        </div>
    );
};

const SingleItem = ({
    item,
    toggleFav,
    isFav,
    applyTemplate,
    creating = false,
    height = 310,
}) => {
    return (
        <div className={styles.gridItem} key={item.id}>
            <div
                className={styles.gridItemImgV}
                style={{
                    height: height,
                }}
            >
                <img
                    className={styles.giImg}
                    src={getImageURL(item.preview)}
                    alt="img"
                />
                <div className={styles.giOverley}>
                    <Tooltip title="Use this template">
                        <button
                            className={styles.giBtn}
                            onClick={applyTemplate}
                        >
                            <i
                                className={
                                    creating
                                        ? "fas fa-cog fa-spin"
                                        : "fas fa-arrow-right"
                                }
                            ></i>
                        </button>
                    </Tooltip>

                    <PreviewTemplate
                        item={item}
                        trigger={
                            <Tooltip title="Preview">
                                <button className={styles.giBtn}>
                                    <i className="fas fa-eye"></i>
                                </button>
                            </Tooltip>
                        }
                        is_template={true}
                        slug_field="id"
                    />
                    <Tooltip title="Favourite">
                        <button
                            className={styles.loveBtn}
                            style={{
                                color: isFav ? "red" : "white",
                            }}
                            onClick={toggleFav}
                        >
                            <i className="fas fa-heart"></i>
                        </button>
                    </Tooltip>
                </div>
            </div>
            <Tooltip title={item.title}>
                <h2 className={styles.gridItemTitle}>{item.title}</h2>
            </Tooltip>
        </div>
    );
};

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const BrowseTemplates = () => {
    let query = useQuery();
    const [type, setType] = useState(undefined);
    const {
        data: dataType,
        isSuccess: successType,
        isLoading: loadingType,
    } = useSingleItem("qdb_creation_type", `/${type}`, !!type);
    const [name, setName] = useState("");
    const [dims, setDims] = useState({
        w: 160,
        h: 160,
    });
    const {
        data: dataTemplates,
        isSuccess: successTemplates,
        isLoading: loadingTemplates,
    } = useItemsFine(
        // "qp_template",
        "qp_creation_template",
        // `filter[type][_eq]=${type}`,
        `filter[types][qdb_creation_type_id][_eq]=${type}&fields=*`,
        type !== undefined
    );
    const { data: dataMe, isSuccess: successMe } = useMe();
    const { data: dataFav, isSuccess: successFav } = useItemsFine(
        // "qp_template_directus_users",
        "qp_creation_template_directus_users",
        `filter[directus_users_id][_eq]=${dataMe?.data?.id}`,
        successMe
    );
    const [allFavs, setAllFavs] = useState([]);
    const createMutation = useCreateItemMutation();
    const createCreationMutation = useCreateItemMutation();
    const deleteMutation = useDeleteItemMutation();

    const [applyId, setApplyId] = useState(null);
    const {
        data: dataSingle,
        isSuccess: successSingle,
        isLoading: loadingSingle,
        // } = useSingleItem("qp_template", `/${applyId}`, applyId !== null);
    } = useSingleItem("qp_creation_template", `/${applyId}`, applyId !== null);

    useEffect(() => {
        if (applyId) {
            if (successSingle && dataSingle) {
                log.d(dataSingle, "Cloning from");
                const newData = cloneDeep(dataSingle.data);
                newData["slug"] = `${newData["slug"]}_${nanoid(5)}`;
                log.d(newData, "New Data for Creation");
                createCreationMutation.mutate({
                    collection: "qp_creation",
                    data: newData,
                    from_id: applyId,
                });
                setApplyId(null);
            }
        }
    }, [applyId, successSingle, dataSingle]);

    const applyTemplate = (id) => {
        log.p(`Applying template: ${id}`);
        setApplyId(id);
    };

    const markFavourite = (id) => {
        log.p(`marking favourite ${id}`);
        if (successMe) {
            const out = {
                // collection: "qp_template_directus_users",
                collection: "qp_creation_template_directus_users",
                data: {
                    // qp_template_id: id,
                    qp_creation_template_id: id,
                    directus_users_id: dataMe?.data?.id,
                },
            };
            createMutation.mutate(out);
        }
    };

    const removeFavourite = (row_id) => {
        log.p(`removing favourite ${row_id}`);
        if (row_id) {
            deleteMutation.mutate({
                // collection: "qp_template_directus_users",
                collection: "qp_creation_template_directus_users",
                id: row_id,
            });
        }
    };

    useEffect(() => {
        if (successFav) {
            // const allFavs = dataFav.map((item) => item.qp_template_id);
            const allFavs = dataFav.map((item) => item.qp_creation_template_id);
            setAllFavs(allFavs);
        }
    }, [successFav, dataFav]);

    useEffect(() => {
        const type = query.get("type");
        setType(type);
    }, [query]);

    useEffect(() => {
        if (successType) {
            setName(dataType?.name);
            const ratio = dataType?.ratio || 1;
            if (ratio >= 1) {
                setDims({
                    w: 250,
                    h: 250 / ratio,
                });
            } else {
                setDims({
                    w: 160,
                    h: 160 / ratio,
                });
            }
        }
    }, [successType]);

    if (successTemplates) {
        log.d(dataTemplates, "DATA Templates");
    }

    log.d(dataType, "data dataType");
    log.d(dims, "types dims");

    if (createCreationMutation.isSuccess) {
        log.d(createCreationMutation, "Create Creation Mutation");
        if (createCreationMutation?.data?.id) {
            return (
                <Route
                    path="/"
                    component={() => {
                        // window.location.href = `${CREATOR_URL}${createCreationMutation?.data?.id}/edit`;
                        window.location.href = getEditURL(
                            createCreationMutation?.data?.id
                        );
                        return null;
                    }}
                />
            );
        }
    }

    return (
        <QurealSection
            header={<SectionHeader heading={`Browse ${name} Templates`} />}
        >
            <div className={styles.wrapper}>
                <div
                    className={styles.gridWrapperV}
                    style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${dims.w}px, 1fr))`,
                    }}
                >
                    <BlankItem
                        title={`Create Blank ${name}`}
                        // link={`${CREATOR_URL}new?type=${type}`}
                        link={getCreateURL(type)}
                        height={dims.h}
                    />
                    {type &&
                        successTemplates &&
                        dataTemplates.map((item) => {
                            const isFav = allFavs.includes(item.id);
                            return (
                                <SingleItem
                                    item={item}
                                    isFav={isFav}
                                    creating={
                                        createCreationMutation.isLoading &&
                                        createCreationMutation?.variables
                                            ?.from_id === item.id
                                    }
                                    toggleFav={() => {
                                        if (isFav) {
                                            const row = dataFav.find(
                                                (favItem) =>
                                                    // favItem.qp_template_id ===
                                                    favItem.qp_creation_template_id ===
                                                    item.id
                                            );
                                            removeFavourite(row?.id);
                                        } else {
                                            markFavourite(item.id);
                                        }
                                    }}
                                    applyTemplate={() => applyTemplate(item.id)}
                                    height={dims.h}
                                />
                            );
                        })}
                </div>
            </div>
        </QurealSection>
    );
};

export default BrowseTemplates;
