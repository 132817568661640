import React from "react";
import ReactDOM from "react-dom";

import Application from "./Application";

ReactDOM.render(<Application />, document.querySelector("#root"));

// React Query Auth login
// https://www.reddit.com/r/reactjs/comments/jd5oxu/does_reactquery_make_sense_in_an_auth_provider/

// React Query 7 Tips
// https://betterprogramming.pub/7-tips-for-using-react-query-in-large-projects-22ccc49d61c2

// React Query Error Handling
// https://tkdodo.eu/blog/react-query-error-handling

// Axios Refresh Token
// https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da

// Regex for {{value}}::  \{\{[a-zA-Z]+\}\}
// Regex ofr {{valeu}} new::  \{\{[^}]*\}\}
