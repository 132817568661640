import React from "react";
import SectionHeader from "./SectionHeader";
import { Link } from "react-router-dom";
import { Button, Dropdown, Menu, Badge } from "antd";
import LayoutCustomizer from "./LayoutCustomizer";
import {
    TableOutlined,
    PlusOutlined,
    UserOutlined,
    ArrowDownOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import Export from "./Export";
import Filters from "./Filters";
import Search from "../commons/Search";
import TableActionCell from "./TableActionCell";
import { history } from "../../_helpers/history";

function TableHeader({
    currentComp,
    fields,
    items,
    searchTerm,
    onSearch,
    permissions,
}) {
    return (
        <SectionHeader heading={currentComp.title}>
            {currentComp.data.refresh && (
                <div style={{ paddingRight: "10px" }}>
                    <div
                        style={{
                            width: "10px",
                            height: "10px",
                            color: "red",
                            backgroundColor: "red",
                            borderRadius: "10px",
                        }}
                    ></div>
                </div>
            )}
            {currentComp.actions.map((action) => {
                if (
                    action.target === "table" &&
                    action.status === "published"
                ) {
                    return (
                        <TableActionCell
                            action={action}
                            currentComp={currentComp}
                            fields={fields}
                            items={items}
                        />
                    );
                }
            })}
            {currentComp.layout.toolbar.search && (
                <Search
                    currentComp={currentComp}
                    searchTerm={searchTerm}
                    onSearch={onSearch}
                />
            )}
            {permissions["create"] !== undefined &&
                currentComp.layout.toolbar.new && (
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            const currentRoute = history.location.pathname;
                            history.push(
                                `data/${currentComp.layout.toolbar.new.form_id}/create`,
                                {
                                    from: currentRoute,
                                }
                            );
                        }}
                    >
                        {currentComp.layout.toolbar.new.text}
                    </Button>
                )}
            <LayoutCustomizer
                comp_id={currentComp.id}
                fields={fields}
                trigger={<Button type="primary" icon={<TableOutlined />} />}
            />
            {currentComp.layout.toolbar.filters && (
                <Filters
                    // currentComp={currentComp}
                    comp_id={currentComp.id}
                    fields={fields}
                    trigger={
                        <Badge dot={currentComp.data.filters.length > 0}>
                            <Button type="primary" icon={<FilterOutlined />} />
                        </Badge>
                    }
                />
            )}
            <Export
                currentComp={currentComp}
                trigger={<Button type="primary" icon={<ArrowDownOutlined />} />}
            />
        </SectionHeader>
    );
}

export default TableHeader;
