import { Layout } from "antd";
import startCase from "lodash/startCase";
import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";
import SectionHeader from "../components/commons/SectionHeader";
import PortableCreateView from "../components/portable/PortableCreateView";
import PortableEditView from "../components/portable/PortableEditView";
import PortableShowView from "../components/portable/PortableShowView";

const { Content } = Layout;

function ItemCreateView() {
    const { collection } = useParams();
    return <PortableCreateView collection={collection} />;
}

function ItemShowView() {
    const { collection, id } = useParams();
    return <PortableShowView {...{ collection, id }} />;
}

function ItemEditView() {
    const { collection, id } = useParams();

    return <PortableEditView {...{ collection, id }} />;
}

function ItemScreen() {
    const match = useRouteMatch();
    const isMobile = useMedia("(max-width: 700px)");
    const { collection } = useParams();

    return (
        <Content
            style={{
                padding: isMobile ? 0 : "0 16px",
            }}
        >
            <div
                style={{
                    position: "sticky",
                    top: "-10px",
                    zIndex: 3,
                }}
            >
                <Switch>
                    <Route
                        exact
                        path={`${match.path}/view/:id`}
                        component={() => (
                            <SectionHeader heading={startCase(collection)} />
                        )}
                    />
                    <Route
                        exact
                        path={`${match.path}/create`}
                        component={() => (
                            <SectionHeader
                                heading={`Create ${startCase(collection)}`}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={`${match.path}/edit/:id`}
                        component={() => (
                            <SectionHeader
                                heading={`Edit ${startCase(collection)}`}
                            />
                        )}
                    />
                </Switch>
            </div>
            <div
                style={{
                    padding: isMobile ? 12 : 24,
                    maxWidth: "100%",
                    overflowX: "auto",
                    minHeight: 180,
                    // overflow: "auto",
                    background: "white",
                }}
            >
                <Switch>
                    <Route
                        exact
                        path={`${match.path}/view/:id`}
                        component={ItemShowView}
                    />
                    <Route
                        exact
                        path={`${match.path}/create`}
                        component={ItemCreateView}
                    />
                    <Route
                        exact
                        path={`${match.path}/edit/:id`}
                        component={ItemEditView}
                    />
                </Switch>
            </div>
        </Content>
    );
}
export default ItemScreen;
