import { useQuery } from "react-query";
import { secureAxios } from "../../_helpers/auth";

export const useScreen = (screen_id) => {
    return useQuery(
        ["screen", screen_id],
        () =>
            secureAxios
                // .get(`items/qureal_screen/${screen_id}?fields=*,components.*.*`)
                .get(
                    `items/qureal_screen/${screen_id}?fields=*,components.qureal_component_id.*,components.qureal_component_id.actions.qureal_action_id.*`
                )
                .then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
        }
    );
};
