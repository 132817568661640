import { Button, Table } from "antd";
import log from "cslog";
import React, { Fragment, useEffect, useState } from "react";
import { useItemsFine } from "../../actions/app/items";
import styles from "./styles/PricingPlansCompare.module.css";
import startCase from "lodash/startCase";
import BillingButton from "./BillingButton";
import { useProfile } from "../../actions/auth";
import classNames from "classnames";
import { useChangeMembership } from "../../actions/mutations/membership";
import useMedia from "react-use/lib/useMedia";
import dayjs from "dayjs";

const PlanName = ({ item, is_current_plan, selected, onSelect }) => {
    return (
        <div
            className={styles.table_header_cell}
            style={{
                backgroundColor: item.theme,
                // border: "1px solid #aaa",
            }}
        >
            <h1 className={styles.name}>{item.name}</h1>
            <h3 className={styles.price}>₹{item.price}</h3>
            {is_current_plan ? (
                <Button type="ghost">Current Plan</Button>
            ) : (
                <Button
                    type={selected ? "primary" : "default"}
                    onClick={() => onSelect(item)}
                >
                    {selected ? "Selected" : "Select"}
                </Button>
            )}
        </div>
    );
};

const prepareData = (
    data,
    selected_fields = null,
    current_plan = null,
    selectedPlan = null,
    setSelectedPlan
) => {
    const one = data[0];
    const out = [];
    const columns = [
        {
            title: "",
            dataIndex: "label",
            key: "label",
            // width: 200,
            fixed: "left",
            render: (item) => <div className={styles.label}>{item}</div>,
        },
    ];
    data.map((item) => {
        columns.push({
            title: (
                <PlanName
                    item={item}
                    is_current_plan={current_plan?.id === item.id}
                    selected={selectedPlan?.name === item.name}
                    onSelect={setSelectedPlan}
                />
            ),
            dataIndex: item.name,
            key: item.name,
            align: "center",
        });
    });
    let rows = selected_fields;
    // let rows = null;
    if (!rows) {
        rows = Object.keys(one).map((key) => key);
    }
    const names = {};
    data.map((item) => {
        names[item.name] = item;
    });
    rows.forEach((row) => {
        if (row === "id") {
            return;
        }
        const newRow = {};
        newRow["key"] = row;
        newRow["label"] = startCase(row);
        Object.keys(names).map((name) => {
            const value = names[name][row];
            if (typeof value === "boolean") {
                newRow[name] = value ? "Yes" : "No";
            } else {
                newRow[name] = value;
            }
        });
        out.push(newRow);
    });
    return [columns, out];
};

const BillingPanel = ({ selectedPlan, currentPlan, profile, category }) => {
    const mutation = useChangeMembership(profile?.id);
    const [billingData, setBillingData] = useState({
        plan_cost: selectedPlan?.price,
        reduction: 0,
        total: selectedPlan?.price,
    });
    const [total, setTotal] = useState(selectedPlan ? selectedPlan.price : 0);

    useEffect(() => {
        if (selectedPlan) {
            const current_plan_cost = currentPlan?.price;
            let plan_cost = selectedPlan?.price;

            // remaining cost
            let cost_remaning = 0;
            let time_remaning = null;
            if (profile.membership_valid_till) {
                const plan_expiry = new Date(profile.membership_valid_till);
                const today = new Date();
                const diffDays = parseInt(
                    (plan_expiry - today) / (1000 * 60 * 60 * 24),
                    10
                );
                // const diffMonths = diffDays / 30;
                cost_remaning = (current_plan_cost * diffDays) / 365;

                plan_cost = parseInt((plan_cost * diffDays) / 365);

                time_remaning = `${Math.floor(diffDays / 30)} months ${
                    diffDays % 30
                } days`;

                if (cost_remaning > current_plan_cost) {
                    cost_remaning = current_plan_cost;
                }
            }
            //------------------------------------------------

            // const reduction = currentPlan.price;
            const reduction = parseInt(cost_remaning);
            let total = plan_cost - reduction;
            if (total < 0) {
                total = 0;
            }
            setBillingData({
                plan_cost: plan_cost,
                time_remaning: time_remaning,
                reduction: reduction,
                total: total,
            });
            setTotal(total);
        } else {
            setTotal(0);
        }
    }, [selectedPlan, currentPlan]);

    const current_plan_rows = (
        <Fragment>
            <tr>
                <td className={styles.label}>Current Plan</td>
                <td className={classNames(styles.value, styles.light)}>
                    {currentPlan?.name}
                </td>
            </tr>
            <tr>
                <td className={styles.label}>Expiry Date</td>
                <td className={classNames(styles.value, styles.light)}>
                    {profile?.membership_valid_till
                        ? dayjs(profile?.membership_valid_till).format(
                              "DD MMM YYYY"
                          )
                        : "-"}
                </td>
            </tr>
        </Fragment>
    );

    if (!selectedPlan || total <= 0) {
        return (
            <div className={styles.billing_wrapper}>
                <table>{current_plan_rows}</table>
            </div>
        );
    }

    log.d(currentPlan, "BD Current Plan");
    log.d(selectedPlan, "BD Selected Plan");

    return (
        <div className={styles.billing_wrapper}>
            <table>
                {current_plan_rows}
                <tr>
                    <td className={classNames(styles.label, styles.bold)}>
                        Upgrade to
                    </td>
                    <td className={styles.value}>{selectedPlan?.name}</td>
                </tr>
                <tr>
                    <td className={classNames(styles.label, styles.bold)}>
                        Plan Cost
                    </td>
                    <td className={styles.value}>{billingData.plan_cost}</td>
                </tr>
                {/* {billingData.time_remaning && (
                    <tr>
                        <td className={classNames(styles.label, styles.bold)}>
                            Time Remaning
                        </td>
                        <td className={styles.value}>
                            {billingData.time_remaning}
                        </td>
                    </tr>
                )} */}
                <tr>
                    <td className={classNames(styles.label, styles.bold)}>
                        Reduction
                    </td>
                    <td className={styles.value}>{billingData.reduction}</td>
                </tr>
                <tr>
                    <td className={classNames(styles.label, styles.bold)}>
                        Total Amount
                    </td>
                    <td className={styles.value}>{billingData.total}</td>
                </tr>
            </table>
            <BillingButton
                total={total}
                onSuccess={(payment_id) => {
                    let new_expiry = profile?.membership_valid_till;
                    if (!new_expiry) {
                        // add 1 year in today date
                        const today = new Date();
                        today.setFullYear(today.getFullYear() + 1);
                        new_expiry = today;
                    }
                    mutation.mutate({
                        plan: selectedPlan.id,
                        membership_valid_till: new_expiry,
                        last_payment_id: payment_id,
                        membership_type: category,
                    });
                }}
            />
        </div>
    );
};

const PricingPlansCompare = () => {
    const isMobile = useMedia("(max-width: 700px)");
    const {
        isLoading: loadingProfile,
        isSuccess: successProfile,
        data: dataProfile,
    } = useProfile();
    const [category, setCategory] = useState(null);
    const { isLoading, isSuccess, data } = useItemsFine(
        "membership_plan",
        `filter[category][_eq]=${category}&sort=price`,
        successProfile
    );
    const [tableData, setTableData] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);

    log.d(dataProfile, "data profile");

    const gender_mapping = {
        male: "men",
        female: "women",
        other: "men",
    };

    useEffect(() => {
        if (successProfile) {
            setCurrentPlan(dataProfile.plan);
            let cat = dataProfile.membership_type;
            if (cat === "individual") {
                cat = dataProfile.gender
                    ? gender_mapping[dataProfile.gender]
                    : "men";
                if (cat !== category) {
                    setCategory(cat);
                }
            } else if (cat) {
                if (cat !== category) {
                    setCategory(cat);
                }
            } else {
                if (!category) {
                    setCategory("men");
                }
            }
        }
    }, [successProfile, dataProfile]);

    useEffect(() => {
        if (isSuccess && data) {
            const temp = prepareData(
                data,
                [
                    "id",
                    "one_time_fees",
                    "price",
                    "discount_for_new_registration",
                    "voting_rights",
                    "candidature_for_election",
                ],
                currentPlan,
                selectedPlan,
                setSelectedPlan
            );
            log.d(temp, "Pricing Plan OUt");
            setTableData({
                columns: temp[0],
                data: temp[1],
            });
            if (!currentPlan) {
                setCurrentPlan(data[0]);
            }
        }
    }, [data, isSuccess, selectedPlan, setSelectedPlan]);

    log.d(data, "Membership plans");

    if (tableData) {
        log.d(tableData.columns.length, "Scroll X");
    }

    // if (isLoading) {
    //     return <h1>Loading...</h1>;
    // }

    const col_width = isMobile ? 150 : 200;

    return (
        <div className={styles.screen}>
            <div className={styles.wrapper}>
                <div className={styles.header_wrapper}>
                    <h1 className={styles.heading}>Upgrade membership plan</h1>
                    <div>
                        <Button
                            type={
                                ["men", "women"].includes(category)
                                    ? "primary"
                                    : "default"
                            }
                            onClick={() => setCategory("men")}
                        >
                            Individual
                        </Button>
                        <Button
                            type={category === "family" ? "primary" : "default"}
                            onClick={() => setCategory("family")}
                        >
                            Family
                        </Button>
                        <Button
                            type={
                                category === "organization"
                                    ? "primary"
                                    : "default"
                            }
                            onClick={() => setCategory("organization")}
                        >
                            Organization
                        </Button>
                    </div>
                </div>
                <BillingPanel
                    selectedPlan={selectedPlan}
                    currentPlan={currentPlan}
                    profile={dataProfile}
                    category={category}
                />
                <div className={styles.table_wrapper}>
                    {tableData && (
                        <Table
                            dataSource={tableData.data}
                            columns={tableData.columns}
                            // scroll={{ x: 1600 }}
                            scroll={{
                                x: tableData.columns.length * col_width,
                            }}
                            // title={() => "Header"}
                            // footer={() => "Footer"}
                            // bordered
                            tableLayout="fixed"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PricingPlansCompare;
