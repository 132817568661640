import React, { Fragment } from "react";
import QField from "../QField";

function FieldGroup({ fields, data, setData }) {
    return (
        <Fragment>
            {fields.map((field) => (
                <QField
                    field={field}
                    value={data[field.id]}
                    setValue={(newVal) => {
                        setData({
                            ...data,
                            [field.id]: newVal,
                        });
                    }}
                />
            ))}
        </Fragment>
    );
}

export default FieldGroup;
