import React from "react";
import log from "cslog";
import { getValidationFunc } from "./conditions";

export function getPermiFuncs(permissions) {
    return {
        update: getValidationFunc(permissions.update.permissions),
        delete: getValidationFunc(permissions.delete.permissions),
    };
}

export function getPermittedActions(comp, permissions) {
    log.p("Filtering permitted actions");
    log.d(comp.actions, "Actions");
    log.d(permissions, "Permissions");

    const permi = {
        update: permissions.update
            ? getValidationFunc(permissions.update.permissions)
            : () => false,
        delete: permissions.delete
            ? getValidationFunc(permissions.delete.permissions)
            : () => false,
        read: permissions.read
            ? getValidationFunc(permissions.read.permissions)
            : () => false,
    };

    // log.d(permiFuncs, "Permi Funcs");
    // log.d(permiFuncs({ status: "pending" }), "Permi Funcs OUT");

    const out = [];
    comp.actions.forEach((action) => {
        const acts = action.qureal_action_id?.actions;
        if (!acts) {
            return;
        }
        let permit = true;
        let permiFuncs = [];
        acts.forEach((act) => {
            if (!(act.type in permissions) && !act.type.startsWith("open")) {
                permit = false;
            } else {
                //check deep now
                switch (act.type) {
                    case "read":
                        permiFuncs.push(permi.read);
                        break;
                    case "update":
                        permiFuncs.push(permi.update);
                        break;
                    case "delete":
                        permiFuncs.push(permi.delete);
                        break;
                    default:
                        break;
                }
                if (act.type.startsWith("open")) {
                    permiFuncs.push(() => true);
                }
            }
        });
        if (permit) {
            let permiFunc =
                permiFuncs.length === 0
                    ? (record) => true
                    : (record) => permiFuncs.every((func) => func(record));
            out.push({
                ...action.qureal_action_id,
                permiFunc: permiFunc,
            });
        }
    });

    log.d(out, "ACTIONS OUT");
    return out;
}

export function checkPermissionPermission(record, action, permissions) {
    log.success("Checking permission permissions");
    log.d(record, "Record");
    log.d(action, "Action");
    log.d(permissions, "Permissions");

    let permitted = true;
    action.actions.forEach((act) => {
        const permi = permissions[act.type];
        //now check it
        const inner_permis = permi.permissions;
        log.d(inner_permis, "Inner Permissions");
    });
    log.info("==================");
}
