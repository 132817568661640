import { Drawer } from "antd";
import { useAtom } from "jotai";
import React from "react";
import { sidebarAtom } from "../states/navigation";
import QurealSidebar from "./QurealSidebar";
import styles from "./styles/QurealSidebarMobile.module.css";

function QurealSidebarMobile({ app, onDynamicScreenLoad, dscreens }) {
    const [open_sidebar, setOpenSidebar] = useAtom(sidebarAtom);

    return (
        <Drawer
            open={open_sidebar}
            placement="left"
            onClose={() => setOpenSidebar(false)}
            closable={true}
            width="70%"
            classNames={{
                body: styles.drawer_body,
            }}
        >
            <QurealSidebar
                app={app}
                onLoad={onDynamicScreenLoad}
                dscreens={dscreens}
                onMenuClick={() => setOpenSidebar(false)}
            />
        </Drawer>
    );
}

export default QurealSidebarMobile;
