import React from "react";
import { useMutation } from "react-query";
import { secureAxios } from "../../_helpers/auth";
import { queryClient } from "../../_helpers/query";

export const useInitPaymentPhonePe2 = (onSuccess = () => {}) => {
    return useMutation(
        (data) => {
            return secureAxios.post(`/qe_payment_phonepe/init_payment`, data);
        },
        {
            onSuccess: (data, variables, context) => {
                onSuccess(data);
                queryClient.invalidateQueries(["items", "qd_payments"]);
            },
        }
    );
};

export const useInitPaymentPhonePe = (onSuccess = () => {}) => {
    return useMutation(
        (data) => {
            return secureAxios
                .post(`/qe_payment_phonepe/init_payment`, data)
                .then((res) => res);
        },
        {
            onSuccess: (data, variables, context) => {
                onSuccess(data);
                queryClient.invalidateQueries(["items", "qd_payments"]);
            },
        }
    );
};
