import log from "cslog";

const validateFilter = (filter) => {
    // make sure this doesn't cause vernability
    if (!filter) {
        return (record) => true;
    }
    //=======================================
    for (const [key, values] of Object.entries(filter)) {
        if (key === "_and") {
            return (record) => {
                // for (const value of values) {
                //     if (!validateFilter(value)(record)) {
                //         return false;
                //     }
                // }
                // return true;
                return values.every((value) => validateFilter(value)(record));
            };
        } else if (key === "_or") {
            return (record) => {
                // for (const value of values) {
                //     if (validateFilter(value)(record)) {
                //         return true;
                //     }
                // }
                // return false;
                return values.some((value) => validateFilter(value)(record));
            };
        } else {
            const [operator, val] = Object.entries(values)[0];
            // log.d(key, "FILD");
            // log.d(operator, "OP");
            // log.d(val, "VAL");
            switch (operator) {
                case "_eq":
                    return (record) => record[key] === val;
                case "_neq":
                    return (record) => record[key] !== val;
                case "_gt":
                    return (record) => record[key] > val;
                default:
                    return (record) => false;
            }
        }
    }
    return (record) => true;
};

export const getValidationFunc = (conditions) => {
    log.info("Calculating Validation Func");
    return validateFilter(conditions);
};

export const getConditions = (conditions) => {
    // log.d(conditions, "Conditionsss");
    // for (const [key, value] of Object.entries(conditions)) {
    // }
    const func = validateFilter(conditions);
    log.d(func, "FUNC");
    log.success(func({ status: "pending", name: "rama" }), "FUNC_OUT");
};
