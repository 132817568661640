import React, { useState } from "react";
import { useDeleteFile, useUploadFile } from "../../actions/files";
import log from "cslog";
import { getImageURL } from "../../_helpers/image";

const ImageField = ({ field, value, onChange }) => {
    const fileMutation = useUploadFile((data, variables, context) => {
        log.d(data, "Success Data");

        onChange(data.id);
    });

    const fileMutationdelete = useDeleteFile((data, variables, context) => {
        log.d(data, "Success Data");
    });

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        console.log(file);
        if (file) {
            if (typeof file === "object" && file !== null) {
                const formData = new FormData();
                //formData.append("title", "Some title")
                formData.append("file", file);

                // if (iconid !== "") {
                if (value) {
                    console.log(value);
                    fileMutationdelete.mutate(value);
                }
                fileMutation.mutate(formData);
            }
        }
    };

    return (
        <>
            <button
                onClick={handleClick}
                status={fileMutation.status}
                disabled={fileMutation.isLoading}
            >
                {fileMutation.status === "loading"
                    ? "Uploading Image..."
                    : value
                    ? "Change Image"
                    : "Upload Image"}
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={onFile}
                style={{ display: "none" }}
            />
            {value && (
                <img
                    src={getImageURL(value)}
                    className="preview-img"
                    alt="preview"
                />
            )}
        </>
    );
};
export default ImageField;
