import { Button } from "antd";
import { useItemsFine } from "../../../_actions/app/items";
import { getImageURL } from "../../../_helpers/image";
import styles from "../styles/BrandStyles.module.css";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddBrandOrganization from "./AddBrandOrganization";
import { useCurrentUser } from "../../../actions/auth";

export default function BrandOrganization({ prefill = {} }) {
    const { isLoading: loadingMe, isSuccess: successMe, data: dataMe } = useCurrentUser();
    const [show, setShow] = useState(false);
    const { data, isSuccess, isLoading } = useItemsFine(
        "qdb_organization",
        "filter[owner][_eq]=$CURRENT_USER",
        true
    );
    const [selected, setSelected] = useState(null);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isSuccess) {
        return <p>Something went wrong</p>;
    }
    return (
        <div className={styles.container}>
            <Button
                icon={<PlusOutlined />}
                onClick={() => {
                    setSelected(null);
                    setShow(true);
                }}
            >
                Add New Organization
            </Button>
            <AddBrandOrganization
                show={show}
                setShow={setShow}
                initialValues={selected || {}}
                key={`shivam_${Math.random()}`}
                current_user={dataMe}
            />
            <div className={styles.grid_wrapper}>
                {data.map((item) => (
                    <div
                        className={styles.item_wrapper}
                        key={item.id}
                        onClick={() => {
                            setSelected(item);
                            setShow(true);
                        }}
                    >
                        <img
                            className={styles.img_organization}
                            src={getImageURL(item.logo)}
                            alt={item.name}
                        />
                        <p className={styles.name}>{item.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
