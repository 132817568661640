import React from "react";
import { Switch, useParams, useRouteMatch, Route } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";
import QurealForm from "../qforms/QurealForm";
import FormViewStandard from "../components/formview/FormViewStandard";
import { history } from "../_helpers/history";

function CRUDScreens() {
    const match = useRouteMatch();
    const isMobile = useMedia("(max-width: 700px)");

    return (
        <div>
            <Switch>
                <Route
                    exact
                    path={`${match.path}/:form_id/create`}
                    component={CreateRecord}
                />
                <Route
                    exact
                    path={`${match.path}/:form_id/edit/:record_id`}
                    component={EditRecord}
                />
                {/* <Route
                    exact
                    path={`${match.path}/form/:id/create`}
                    component={CreateRecord}
                /> */}
            </Switch>
        </div>
    );
}

function EditRecord() {
    const { form_id, record_id } = useParams();

    return (
        <FormViewStandard
            comp={{
                title: "CRUD Edit Form",
                data: {
                    type: "edit",
                    form_id: form_id,
                    record: {
                        type: "fixed",
                        value: record_id,
                    },
                },
            }}
            onSuccess={() => {
                history.goBack();
            }}
        />
    );
}

function CreateRecord() {
    const { form_id } = useParams();

    return (
        <FormViewStandard
            comp={{
                title: "CRUD Create Form",
                data: {
                    type: "create",
                    form_id: form_id,
                },
            }}
        />
    );
}

export default CRUDScreens;
