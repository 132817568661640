import { Fragment } from "react";
import {
    Form,
    Input,
    Select,
    Switch,
    InputNumber,
    Radio,
    DatePicker,
    Slider,
    Checkbox,
    Button,
    Tag,
} from "antd";
import startCase from "lodash/startCase";
import ForeignField from "../components/commons/ForeignField";
import { UploadOutlined } from "@ant-design/icons";
// import PhotoButton from "../widgets/PhotoButton";
import PhotoButton from "../qforms/standard_elements/PhotoButton";
import MultiFilePicker from "../widgets/MultiFilePicker";
import moment from "moment";
// import dayjs from "dayjs";
import log from "cslog";

const { Option } = Select;
const { TextArea } = Input;

const OnlyDatePicker = ({ value, onChange, showTime = false }) => {
    log.d(value, "Date Value");
    log.d(typeof value, "Date Value type");
    // const dt = dayjs(value).utc().format();
    // log.d(dt, "date value parsed");
    return (
        <DatePicker
            value={moment(value)}
            // value={dayjs(value)}
            // value={dt}
            showTime={showTime}
            onChange={(date, dateString) => {
                log.d(date?.format(), "New Date");
                onChange(date?.format());
            }}
        />
    );
};

export const getFormField = (field) => {
    if (!field) {
        return null;
    }
    const itype = field.meta.interface;
    const display_type = field.meta?.display;
    const meta = field.meta;
    const options = meta?.options ? meta.options : {};
    if (meta.hidden) {
        return null;
    }

    let input = undefined;
    let irules = {};
    switch (itype) {
        case "slider":
            input = (
                <Slider
                    min={options.minValue}
                    max={options.maxValue}
                    step={options.stepInterval}
                />
            );
            break;
        case "input-rich-text-html":
            input = <TextArea rows={4} />;
            break;
        case "select-dropdown-m2o":
            input = <ForeignField field={field} many={false} />;
            break;
        case "list-o2m":
            // input = null;
            // input = <h3>One to Many</h3>;
            input = <ForeignField field={field} many={true} />;
            break;
        case "list-m2m":
            input = null;
            break;
        case "file-image":
            input = <PhotoButton field={field} />;
            break;
        case "file":
            if (display_type === "image") {
                input = <PhotoButton field={field} />;
            } else {
                input = <MultiFilePicker field={field} single={true} />;
            }
            break;
        case "files":
            input = <MultiFilePicker field={field} single={false} />;
            break;
        case "select-multiple-dropdown":
            const multi_choices = options.choices;
            input = (
                <Select mode="multiple" allowClear>
                    {multi_choices.map((item) => {
                        return (
                            <Option value={item.value} key={item.value}>
                                {item.text} Nice
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "select-dropdown":
            const choices = options.choices;
            input = (
                <Select>
                    {choices.map((item) => {
                        return (
                            <Option value={item.value} key={item.value}>
                                {item.text}
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "select-multiple-checkbox":
            const multi_check_options = [];
            meta.options.choices.forEach((item) => {
                multi_check_options.push({
                    label: item.text,
                    value: item.value,
                });
            });
            input = <Checkbox.Group options={multi_check_options} />;
            break;
        case "select-radio":
            const radio_choices = options.choices;
            input = (
                <Radio.Group>
                    {radio_choices.map((item) => {
                        return (
                            <Radio value={item.value} key={item.value}>
                                {item.text}
                            </Radio>
                        );
                    })}
                </Radio.Group>
            );
            break;
        // case "datetime":
        //     input = <OnlyDatePicker />;
        //     break;
        case "datetime":
            const datetype = field?.schema?.data_type;
            if (datetype === "date") {
                // input = <DatePicker />;
                input = <OnlyDatePicker />;
            } else {
                // input = <DatePicker showTime />;
                input = <OnlyDatePicker showTime />;
            }
            break;
        case "boolean":
            input = <Switch />;
            break;
        case "input":
            const dtype = field.schema.data_type;
            switch (dtype) {
                case "integer":
                case "float":
                case "bigint":
                    input = (
                        <InputNumber
                            placeholder="Enter Value"
                            disabled={meta.readonly}
                            style={{ width: "200px" }}
                        />
                    );
                    irules = { ...irules, type: "number" };
                    break;
                default:
                    const isMasked = options?.masked;
                    if (isMasked) {
                        input = <Input.Password />;
                    } else {
                        input = (
                            <Input
                                placeholder="Enter Value"
                                disabled={meta.readonly}
                            />
                        );
                    }
            }
            break;
        default:
            input = (
                <Input placeholder="Enter Value" disabled={meta.readonly} />
            );
    }

    const rules = [
        {
            required: meta?.required,
            ...irules,
            ...("min" in options && { min: options.min }),
            ...("max" in options && options.max !== -1 && { max: options.max }),
            // ...("min" in options && { type: "number" }),
        },
        // { ...("min" in options && { min: options.min }) },
    ];
    // log.d(options, "Options");
    // log.d(rules, "Rules");
    if (input) {
        return (
            <Form.Item
                key={field.field}
                name={field.field}
                label={startCase(field.field)}
                labelAlign="left"
                hidden={meta.hidden}
                rules={rules}
            >
                {input}
            </Form.Item>
        );
    } else {
        return <></>;
    }
};
