import { startCase } from "lodash";
import MyCreations from "./MyCreations";
import TemplatesBar from "./TemplatesBar";
import styles from "./styles/CreationDisplayArea.module.css";
import React from "react";

function CreationDisplayArea({ type }) {
    return (
        <div className={styles.wrapper}>
            {/* <TemplatesBar type={type} /> */}
            <h3>My {startCase(type)}s</h3>
            <MyCreations type={type} />
        </div>
    );
}

export default CreationDisplayArea;
